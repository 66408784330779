import React, { ReactElement, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

interface AllowedRouteProps {
  isAllowed: boolean
}
export default function AllowedRoute({ isAllowed }: AllowedRouteProps): ReactElement | null {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAllowed) {
      navigate('/projects');
    }
  }, [navigate, isAllowed]);

  if (!isAllowed) { return null; }
  return (
    <Outlet />
  );
}
