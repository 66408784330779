import { ITemplateRepositorySubmit, SupportedVCS } from 'types/types';
import { objectToGraphqlString } from 'utils';
import { Parameter, VcsTarget } from './types';

export function generateUserState(dataOriginUuid: string): string {
  return `mutation { generateUserState (dataOriginUuid: "${dataOriginUuid}") { success errors state { state dataOriginClientId } } }`;
}

export function createProjectMutation(name: string, description:string):string {
  return (`mutation {
    createProject(name: "${name}", description: "${description}") 
      {
        success 
        errors 
        project 
        { 
          name 
          description
          uuid
        }
      }
    }`
  );
}

export function bindUserToProjectMutation(installationId: string, code: string): string {
  return (`mutation {
    bindPlutoUser(installationId: "${installationId}", code: "${code}")
    {
      success
      errors
      userAccount { username }
      projectUser { username }
      organisation { name }
    }
  }`);
}

export function createRepositoryMutation(name: string, vcsTarget: VcsTarget, projectUUID: string,
  templates: ITemplateRepositorySubmit[], ciProvider: string, parameters: Parameter[], options: Parameter[],
): string {
  return (`mutation {
    createRepository(name: "${name}", vcsTarget: ${objectToGraphqlString(vcsTarget)}, projectUuid: "${projectUUID}", templates: ${objectToGraphqlString(templates)}, ciProvider: "${ciProvider}", parameters: ${objectToGraphqlString(parameters)}, options: ${objectToGraphqlString(options)})
    {
      success
      errors
    }
  }`);
}

export function authoriseUserMutation(state: string, code: string, dataOriginUuid: string): string {
  return (`mutation {
    authoriseUser(state: "${state}", code: "${code}", dataOriginUuid: "${dataOriginUuid}")
    { 
      success
      errors 
    }
  }`);
}

export function updateProjectDescriptionMutation(projectUuid:string, description: string): string {
  return (`mutation {
    updateProjectDescription(projectUuid: "${projectUuid}", description: """${description}""")
    {
      success
      errors
      project
      {
        name
        description
        uuid
        repositories
        {
          uuid
          url
          name
          description
          attributes
          { 
            displayText
            value
            name
          }
        }
        members {
          role {
            name
          }
          user {
            uuid
            username
            email
            name
          }
        }
      }
    }
  }`);
}

export function deleteProjectMutation(projectUuid: string): string {
  return (`mutation { deleteProject(projectUuid: "${projectUuid}") { success errors }}`);
}

export function deleteRepositoryMutation(repositoryUuid: string, deleteFromVcs: boolean): string {
  return (`mutation { deleteRepository(repositoryUuid: "${repositoryUuid}", deleteFromVcs: ${deleteFromVcs}) { success errors }}`);
}

export function addTemplateRepositoryMutation(url: string, name: string, description: string, username: string, token: string, organisationUuid?: string):string {
  return (`mutation { 
    addTemplateRepository(url: "${url}", name: "${name}", description: """${description}"""${username ? `, username: "${username}"` : ''}${token ? `, token: "${token}"` : ''}${organisationUuid ? `, organisationUuid: "${organisationUuid}"` : ''}
  )
    { success errors }
  }`);
}
export function deleteTemplateRepositoryMutation(uuid: string): string {
  return (`mutation { deleteTemplateRepository(uuid: "${uuid}") { success errors }}`);
}

export function addProjectMemberMutation(projectUuid: string, memberUuid: string, role: 'User' | 'Admin' = 'User'): string {
  return (`mutation { 
    addProjectMember(projectUuid: "${projectUuid}", memberUuid: "${memberUuid}", role: "${role}") {
      success
      errors
    }
  }`);
}

export function updateProjectMemberMutation(projectUuid: string, memberUuid: string, role: 'User' | 'Admin'): string {
  return (`mutation { 
    updateProjectMember(projectUuid: "${projectUuid}", memberUuid: "${memberUuid}", role: "${role}") {
      success
      errors
    }
  }`);
}
export function deleteProjectMemberMutation(projectUuid: string, memberUuid: string): string {
  return (`mutation { 
    deleteProjectMember(projectUuid: "${projectUuid}", memberUuid: "${memberUuid}") {
      success
      errors
    }
  }`);
}

export function updateOrgMemberMutation(orgUuid: string, memberUuid: string, role: 'Admin' | 'User'): string {
  return (`mutation {
    updateOrganisationMember(organisationUuid: "${orgUuid}", memberUuid: "${memberUuid}", role: "${role}") {
      success
      errors
    }
  }`);
}

export function createTeamMutation(organisationUuid: string, name: string, description: string, groups?: string[]): string {
  return (`mutation { 
    createTeam(organisationUuid: "${organisationUuid}", name: "${name}", description: "${description}"${groups ? `, groups: ${objectToGraphqlString(groups)}` : ''}) {
      success
      errors
    }
  }`);
}

export function deleteTeamMutation(uuid: string): string {
  return (`mutation { 
    deleteTeam(teamUuid: "${uuid}") {
      success
      errors
    }
  }`);
}

export function addTeamMemberMutation(teamUuid: string, memberUuid: string): string {
  return (`mutation {
    addTeamMember(teamUuid: "${teamUuid}", memberUuid: "${memberUuid}", role: "User") {
      success
      errors
    }
  }`);
}

export function updateTeamMemberMutation(teamUuid: string, memberUuid: string, role: 'Admin' | 'User'): string {
  return (`mutation {
    updateTeamMember(teamUuid: "${teamUuid}", memberUuid: "${memberUuid}", role: "${role}") {
      success
      errors
    }
  }`);
}

export function deleteTeamMemberMutation(teamUuid: string, memberUuid: string): string {
  return (`mutation {
    deleteTeamMember(teamUuid: "${teamUuid}", memberUuid: "${memberUuid}") {
      success
      errors
    }
  }`);
}

export function addTeamToProjectMutation(projectUuid: string, teamUuid: string): string {
  return (`mutation {
    addTeamToProject(projectUuid: "${projectUuid}", teamUuid: "${teamUuid}", ) {
      success
      errors
    }
  }`);
}

export function deleteTeamFromProjectMutation(projectUuid: string, teamUuid: string): string {
  return (`mutation {
    deleteTeamFromProject(projectUuid: "${projectUuid}", teamUuid: "${teamUuid}", ) {
      success
      errors
    }
  }`);
}

export function executeTemplatesMutation(projectUUID: string, templates: ITemplateRepositorySubmit[], parameters: Parameter[], repositoryUuid?: string): string {
  return (`mutation {
    executeTemplates(projectUuid: "${projectUUID}", templates: ${objectToGraphqlString(templates)}, parameters: ${objectToGraphqlString(parameters)}${repositoryUuid ? `, repositoryUuid: "${repositoryUuid}"` : ''})
    {
      success
      errors
    }
  }`);
}

export function adminCreateOrganisationMutation(name: string, groupName?: string): string {
  return (`mutation {
    createOrganisation(name: "${name}"${groupName ? `, groupName: "${groupName}"` : ''})
    {
      success
      errors
    }
  }`
  );
}

export function adminAddMemberToOrganisation(organisationUuid: string, userUuid: string): string {
  return (`mutation {
    addOrganisationMember(organisationUuid: "${organisationUuid}", memberUuid: "${userUuid}", role: "User")
    {
      success
      errors
    }
  }`);
}

export function adminRemoveMemberFromOrganisation(organisationUuid: string, userUuid: string): string {
  return (`mutation {
    deleteOrganisationMember(organisationUuid: "${organisationUuid}", memberUuid: "${userUuid}")
    {
      success
      errors
    }
  }`);
}

export function adminImportPublicTemplateRepositoryMutation(url: string, name: string, description: string, token: string): string {
  return (`mutation {
     addPublicTemplateRepository(url: "${url}", name: "${name}", description: """${description}"""${token ? `, vcsToken: "${token}"` : ''})
    { success errors }
  }`);
}

export function createStoredValueMutation(name: string, description: string, valueType: string, value: string, organisationUuid: string, projectUuid?: string) {
  return (`mutation {
     createStoredValue(name: "${name}", description: """${description}""", valueType: "${valueType}" value: """${value}""" organisationUuid: "${organisationUuid}"${projectUuid ? `, projectUuid: "${projectUuid}"` : ''})
    { success errors }
  }`);
}

export function deleteStoredValueMutation(storedValueUuid: string) {
  return (`mutation {
     deleteStoredValue(storedValueUuid: "${storedValueUuid}")
    { success errors }
  }`);
}
export function updateStoredValueMutation(storedValueUuid: string, name: string, description: string, valueType: string, value?: string) {
  return (`mutation {
     updateStoredValue(storedValueUuid: "${storedValueUuid}", name: "${name}", description: """${description}""", valueType: "${valueType}"${value ? `, value: """${value}"""` : ''})
    { success errors }
  }`);
}

export function deleteTeamGroupMutation(teamUuid: string, groupUuid: string): string {
  return (`mutation {
    deleteTeamGroup(teamUuid: "${teamUuid}", groupUuid: "${groupUuid}") {
      success
      errors
    }
  }`);
}

export function addTeamGroupMutation(teamUuid: string, groupName: string): string {
  return (`mutation {
    addTeamGroup(teamUuid: "${teamUuid}", groupName: "${groupName}", role: "User") {
      success
      errors
    }
  }`);
}

export function createDataOriginMutation(organisationUuid: string, name: string, vcsType: string, url: string, apiUrl: string, clientId: string, clientSecret: string): string {
  return (`mutation {
    createDataOrigin(organisationUuid: "${organisationUuid}", name: "${name}", vcsType: "${vcsType}", url: "${url}", apiUrl: "${apiUrl}", clientId: "${clientId}", clientSecret: "${clientSecret}") {
      success
      errors
    }
  }`);
}

export function addDataOriginMutation(organisationUuid: string, dataOriginUuid: string): string {
  return (`mutation {
    addDataOrigin(organisationUuid: "${organisationUuid}", dataOriginUuid: "${dataOriginUuid}") {
      success
      errors
    }
  }`);
}

export function editDataOriginMutation(dataOriginUuid: string, name: string, vcsType: SupportedVCS, url: string, apiUrl: string, clientId?: string, clientSecret?: string): string {
  return (`mutation {
    updateDataOrigin(dataOriginUuid: "${dataOriginUuid}", name: "${name}", vcsType: "${vcsType}", url: "${url}", apiUrl: "${apiUrl}"${clientId ? `, clientId: "${clientId}"` : ''}${clientSecret ? `, clientSecret: "${clientSecret}"` : ''}) {
      success
      errors
    }
  }`);
}
