import { Parameter, Project } from 'graphql/types';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { executeTemplatesAction } from 'store/actions/projectActions';
import ScriptCard from 'stories/composite/ScriptCard/ScriptCard';
import { ITemplateInputTypes, ITemplateRepositorySubmit } from 'types/types';
import { useAppDispatch, useAppSelector } from 'utils';
import { TemplateInfoWithRepoName } from 'views/CreateRepository/types';

interface ProjectScriptsProps {
  project: Project
}
export default function ProjectScripts({ project }: ProjectScriptsProps): ReactElement {
  const [projectTemplates, setProjectTemplates] = useState<TemplateInfoWithRepoName[]>([]);
  const [scriptCategories, setScriptCategories] = useState<{ category: string, templates: TemplateInfoWithRepoName[] }[]>([]);
  const templateRepositories = useAppSelector((state) => state.project.templateRepositories);
  const storedValues = useAppSelector((state) => state.organisation.storedValues);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const _projectTemplates: TemplateInfoWithRepoName[] = [];
    templateRepositories.forEach((repo) => {
      repo.templates?.forEach((template) => {
        if (template.component.name === 'executor_templates' && template.type.name === 'project') {
          _projectTemplates.push({
            ...template,
            repository: {
              name: repo.name,
              uuid: repo.uuid,
            },
          });
        }
      });
    });
    setProjectTemplates(_projectTemplates);
  }, [templateRepositories]);

  useEffect(() => {
    const _scriptCategories: { category: string, templates: TemplateInfoWithRepoName[] }[] = [];
    projectTemplates.forEach((template) => {
      const alreadyMadeCategoryIndex = _scriptCategories.findIndex((category) => category.category === template.category?.description || category.category === template.category?.name);
      if (alreadyMadeCategoryIndex >= 0) {
        _scriptCategories[alreadyMadeCategoryIndex].templates.push(template);
      } else {
        _scriptCategories.push({
          category: template.category?.description || template.category?.name || '',
          templates: [template],
        });
      }
    });
    setScriptCategories(_scriptCategories);
  }, [projectTemplates]);

  const submit = useCallback(
    (template: TemplateInfoWithRepoName, values: { field: string, value: string, type: ITemplateInputTypes, text: string, valueType: 'value' | 'stored'}[]) => {
      if (template.template?.executable) {
        const submitTemplates: ITemplateRepositorySubmit[] = [
          {
            templateRepositoryUuid: template.repository.uuid,
            templatePaths: [template.path],
          },
        ];
        const parameters: Parameter[] = values.map((value) => ({ field: value.field, value: value.value, type: value.valueType }));
        dispatch(executeTemplatesAction(project.uuid, submitTemplates, parameters));
      }
    },
    [dispatch, project.uuid],
  );

  return (
    <div>
      {scriptCategories.map((category) => (
        <div key={category.category}>
          <h3>{category.category}</h3>
          <div className="project-scripts-category">
            {category.templates.map((template) => (
              <ScriptCard
                key={template.uuid}
                id={`${template.name}-selection-box`}
                title={template.name}
                subtitle={template.template?.description || ''}
                source={`From: ${template.repository.name}`}
                inputs={template.template?.inputs || []}
                onDeploy={(values) => submit(template, values)}
                storedValues={storedValues}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
