import React, { ReactElement } from 'react';
import Label from '../Label/Label';
import styles from './TextArea.module.css';
import { TextAreaProps } from './types';

export default function TextArea({
  id, rows = 5, className, disabled, fluid, error, label, info, required, ...rest
}: TextAreaProps): ReactElement {
  // textarea
  let classForTextarea = className || '';
  if (disabled) {
    classForTextarea += ` ${styles['disabled-textarea']}`;
  }
  // wrapper
  let textareaWrapper = styles['textarea-wrapper'];
  if (fluid) {
    textareaWrapper += ` ${styles.fluid}`;
  }
  if (error) {
    textareaWrapper += ` ${styles['error-wrapper']}`;
    classForTextarea += ` ${styles['error-outline']}`;
  }

  return (
    <div className={textareaWrapper}>
      {label ? <Label htmlFor={id} className={styles.label} text={required ? `${label} *` : label} info={info} /> : null}
      <textarea
        className={classForTextarea}
        id={id}
        rows={rows}
        disabled={disabled}
        required={required}
        {...rest}
      />
      {typeof (error) === 'string' ? <span className={`${styles.error}`}>{error}</span> : null}
    </div>
  );
}
