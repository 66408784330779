import React, {
  ChangeEvent, ReactElement, useCallback, useState,
} from 'react';
import Search from 'stories/atoms/Search/Search';
import UserCard from 'stories/composite/UserCard/UserCard';
import { useAppDispatch } from 'utils';
import * as userActions from 'store/actions/userActions';
import * as projectActions from 'store/actions/projectActions';
import { SearchResult } from 'stories/atoms/Search/types';
import Button from 'stories/atoms/Button/Button';
import { Member, Project } from 'graphql/types';

interface ProjectMembersProps {
  project: Project
}
export default function ProjectMembers({ project }: ProjectMembersProps): ReactElement {
  const dispatch = useAppDispatch();
  const [UserSearchResults, setUserSearchResults] = useState<SearchResult[]>([]);

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value || event.target.value.length < 3) { return; }
      dispatch(userActions.FindUsers(event.target.value)).then((users) => {
        const results: SearchResult[] = [];
        users?.forEach((user) => {
          results.push({
            text: `${user.username} - ${user.email}`,
            id: `${user.username}-result`,
            key: user.uuid,
            uuid: user.uuid,
          });
        });
        setUserSearchResults(results);
      });
    },
    [dispatch],
  );

  const onSearchSelect = useCallback(
    (result: SearchResult) => {
      dispatch(projectActions.addMemberToProject(project.uuid, result.uuid));
    },
    [dispatch, project.uuid],
  );

  const removeMember = useCallback(
    (member: Member) => {
      dispatch(projectActions.deleteProjectMember(project.uuid, member.user.uuid));
    },
    [dispatch, project.uuid],
  );

  const updateMemberRole = useCallback(
    (member: Member, role: 'Admin' | 'User') => {
      dispatch(projectActions.updateProjectMember(project.uuid, member.user.uuid, role));
    },
    [dispatch, project.uuid],
  );

  return (
    <div>
      {project.amIAdmin ? (
        <Search
          minInputLength={3}
          debounceTimer={300}
          id="search-members"
          placeholder="Add a member"
          onChange={onSearchChange}
          onResultSelect={onSearchSelect}
          results={UserSearchResults}
        />
      ) : null}
      <div className="project-members">
        {project.members.map((member) => {
          const role = member.role.name === 'Admin' ? 'admin' : 'user';
          const popupContent = project.amIAdmin ? (
            <div className="project-members-popup">
              <Button onClick={() => updateMemberRole(member, member.role.name === 'Admin' ? 'User' : 'Admin')}>{role === 'admin' ? 'Demote to user' : 'Promote to admin'}</Button>
              <Button onClick={() => removeMember(member)}>Remove</Button>
            </div>
          ) : undefined;
          return (
            <UserCard
              id={`${member.user.username}-card`}
              key={member.user.uuid}
              title={member.user.username}
              subtitle={member.user.email}
              icon={role}
              popupContent={popupContent}
            />
          );
        })}
      </div>
    </div>
  );
}
