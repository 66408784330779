import React, { ReactElement } from 'react';
import Button from 'stories/atoms/Button/Button';
import Link from 'stories/atoms/Link/Link';
import Icon from 'stories/atoms/Icon/Icon';
import Identicon from 'stories/atoms/Identicon/Identicon';
import { isUrl } from 'utils';
import styles from './Card.module.css';
import { CardProps } from './types';

export default function Card({
  title, subtitle, action, actionTitle, id, link, linkHref, subtitleMaxChars = 200,
  linkNewTab, actionClassName, attributes, imageHash, topActions, noTop = false,
}: CardProps): ReactElement {
  let cardSubtitle = subtitle;
  if (cardSubtitle && cardSubtitle.length > subtitleMaxChars) {
    cardSubtitle = `${cardSubtitle.slice(0, subtitleMaxChars - 3)}...`;
  }

  function getTopActions():ReactElement {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {topActions?.map((topAction) => (
          <Icon
            key={topAction.icon}
            onClick={topAction.onClick}
            name={topAction.icon}
            color={topAction.color}
            size="small"
            actionable
          />
        ))}
      </>
    );
  }
  return (
    <div className={styles.container} id={id}>
      {noTop ? null : (
        <div className={styles.top}>
          {/* eslint-disable-next-line react/no-danger */}
          {imageHash ? <Identicon className={styles.identicon} imageHash={imageHash} /> : null}
          {topActions && topActions.length ? <div className={styles['top-actions']}>{getTopActions()}</div> : null}
        </div>
      )}

      <div className={noTop ? `${styles.body} ${styles['no-top']}` : styles.body}>
        <h4 title={title} className={imageHash ? `${styles.title} ${styles['title-with-image']}` : styles.title}>
          {title}
          {noTop && topActions && topActions.length ? <div className={styles['top-actions']}>{getTopActions()}</div> : null}
        </h4>
        <p title={cardSubtitle !== subtitle ? subtitle : undefined} className={styles.subtitle}>{cardSubtitle}</p>
        {attributes && attributes.length ? (
          <div className={styles.attributes}>
            <h5>Repository details:</h5>
            <ul>
              {attributes.map((attribute) => (
                <li id={attribute.displayText} key={attribute.displayText}>
                  <span className={styles['attribute-title']}>{attribute.displayText}:</span>{isUrl(attribute.value) ? <Link id={`${attribute.displayText}-link`} href={attribute.value}>Link</Link> : <span>{attribute.value}</span>}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div className={styles.buttons}>
          {action ? <Button id={`${id}-button`} variant="secondary" className={actionClassName} onClick={action}>{actionTitle}</Button> : null}
          {link && linkHref ? <Link newTab={linkNewTab} className={actionClassName} type="button-secondary" href={linkHref}>{link}</Link> : null}
        </div>
      </div>
    </div>
  );
}
