import Axios from 'axios';
import {
  adminAddMemberToOrganisation, adminCreateOrganisationMutation, adminImportPublicTemplateRepositoryMutation, adminRemoveMemberFromOrganisation,
} from 'graphql/mutations';
import {
  adminGetOrganisationByUuidQuery, adminGetOrganisationsQuery, adminGetOrganisationTeamsByOrgUuidQuery,
} from 'graphql/queries';
import { Team } from 'graphql/types';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { Action, ActionType } from 'store/actionTypes';
import store from 'store/configureStore';
import {
  baseUrl, headers, adminPath, getErrorString, path, checkErrors,
} from 'utils';

const toastUpdateDefaults = {
  isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true,
};

export const GetAllOrganisations = () => async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${adminPath}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: adminGetOrganisationsQuery() },
    });
    checkErrors(response);
    dispatch({
      type: ActionType.ADMIN_GET_ORGANISATIONS,
      payload: response.data.data.organisations.organisations,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const GetOrganisationByUuid = (uuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${adminPath}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: adminGetOrganisationByUuidQuery(uuid) },
    });
    checkErrors(response);
    dispatch({
      type: ActionType.ADMIN_GET_CURRENT_ORGANISATION,
      payload: response.data.data.organisation.organisation,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const GetOrganisationTeams = (uuid: string) => async (dispatch: Dispatch<Action>): Promise<Team[] | void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: adminGetOrganisationTeamsByOrgUuidQuery(uuid) },
    });
    checkErrors(response);
    const { teams }: { teams: Team[] } = response.data.data.getTeams;
    dispatch({
      type: ActionType.ADMIN_GET_CURRENT_ORGANISATION_TEAMS,
      payload: teams,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const CreateOrganisation = (name: string, groupName: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Creating organisation');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${adminPath}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: adminCreateOrganisationMutation(name, groupName) },
    });
    checkErrors(response);
    GetAllOrganisations()(dispatch);
    toast.update(id, {
      render: 'Organisation created', type: 'success', ...toastUpdateDefaults,
    });
    return Promise.resolve();
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
};

export const AddMemberToOrganisation = (organisationUuid: string, userUuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Adding member to organisation');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${adminPath}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: adminAddMemberToOrganisation(organisationUuid, userUuid) },
    });
    checkErrors(response);
    GetOrganisationByUuid(organisationUuid)(dispatch);
    toast.update(id, {
      render: 'Member added', type: 'success', ...toastUpdateDefaults,
    });
    return Promise.resolve();
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
};

export const RemoveMemberFromOrganisation = (organisationUuid: string, userUuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Removing member from organisation');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${adminPath}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: adminRemoveMemberFromOrganisation(organisationUuid, userUuid) },
    });
    checkErrors(response);
    GetOrganisationByUuid(organisationUuid)(dispatch);
    toast.update(id, {
      render: 'Member removed', type: 'success', ...toastUpdateDefaults,
    });
    return Promise.resolve();
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
};

export const importPublicTemplateRepository = (url: string, name: string, description: string, token: string) => async (): Promise<void> => {
  const id = toast.loading('Importing template repository');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${adminPath}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: adminImportPublicTemplateRepositoryMutation(url, name, description, token) },
    });
    checkErrors(response);
    toast.update(id, {
      render: 'Template repository imported!', type: 'success', ...toastUpdateDefaults,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  return Promise.resolve();
};
