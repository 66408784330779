import React, { ReactElement, useEffect, useState } from 'react';
import Icon from 'stories/atoms/Icon/Icon';
import styles from './Tabs.module.css';
import { IPanel, TabsProps } from './types';

export default function Tabs({
  panels, className, onSelect, openPanel,
}: TabsProps): ReactElement {
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (typeof openPanel === 'number') {
      setActive(openPanel);
    }
  }, [openPanel]);

  function changeActiveTab(index: number):void {
    setActive(index);
    if (onSelect instanceof Function) {
      onSelect(index);
    }
  }

  function setClassName(panel: IPanel, index: number): string {
    let wholeClassName = styles.tab;
    if (active === index) {
      wholeClassName += ` ${styles.active}`;
    }
    if (panel.disabled) {
      wholeClassName += ` ${styles.disabled}`;
    }
    return wholeClassName;
  }

  const panelsContent = panels.length && panels.map((panel, index) => (
    <div
      key={panel.id}
      id={panel.id}
      className={setClassName(panel, index)}
      onClick={() => {
        if (!panel.disabled) {
          changeActiveTab(index);
        }
      }}
    >
      {panel.icon ? <Icon width={16} height={16} color="white" className={styles.active} name={panel.icon} /> : null}
      <span>{panel.title}</span>
    </div>
  ));
  return (
    <div className={`${styles['tabs-wrapper']} ${className}`}>
      <div className={`${styles.tabs}`}>
        {panelsContent}
      </div>
      <div className={`${styles.content}`}>
        {panels[active].content}
      </div>
    </div>
  );
}
