import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from 'stories/atoms/Icon/Icon';
import styles from './NavItem.module.css';
import { NavItemProps } from './types';

export default function NavItem({ to, icon, text }: NavItemProps):ReactElement {
  return (
    <NavLink
      title={text}
      to={to}
      className={({ isActive }) => (
        isActive ? `${styles['nav-item']} ${styles.active}` : styles['nav-item']
      )}
    >
      <Icon color="white" width={20} height={20} name={icon} />
    </NavLink>
  );
}
