import React, { ReactElement, useEffect, useState } from 'react';
import Icon from 'stories/atoms/Icon/Icon';
import { IconColor } from 'stories/atoms/Icon/types';
import { IStepperPanel, StepperProps } from './types';
import styles from './Stepper.module.css';

export default function Stepper({
  panels, className, onSelect, openPanel,
}: StepperProps): ReactElement {
  const [active, setActive] = useState(0);
  useEffect(() => {
    if (typeof openPanel === 'number') {
      setActive(openPanel);
    }
  }, [openPanel]);

  function changeActiveTab(index: number):void {
    setActive(index);
    if (onSelect instanceof Function) {
      onSelect(index);
    }
  }

  function setClassName(panel: IStepperPanel, index: number): string {
    let wholeClassName = styles.bar;
    if (active === index) {
      wholeClassName += ` ${styles.active}`;
    }
    if (panel.accomplished) {
      wholeClassName += ` ${styles.accomplished}`;
    }
    return wholeClassName;
  }

  function iconColor(panel: IStepperPanel, index: number): IconColor {
    if (active === index) {
      return 'primary';
    }
    if (panel.accomplished) {
      return 'green';
    }
    return 'grey';
  }

  const panelsContent = panels.length && panels.map((panel, index) => (
    <div
      key={panel.id}
      id={panel.id}
      className={setClassName(panel, index)}
      onClick={() => {
        if (panel.accomplished) {
          changeActiveTab(index);
        }
      }}
    >
      <div className={styles['connector-container']}>
        <Icon width={23} height={23} className={styles.icon} name={active === index ? 'inProgress' : 'filledCheckmark'} color={iconColor(panel, index)} />
        {index > 0 ? <div className={panel.accomplished || active === index ? `${styles.connector} ${styles.accomplished}` : styles.connector}></div> : null}
      </div>
      <span className={active === index ? styles['title-active'] : undefined}>{panel.title}</span>
    </div>
  ));

  return (
    <div className={`${styles['steps-wrapper']} ${className}`}>
      <div className={`${styles.tabs}`}>
        {panelsContent}
      </div>
      <div className={`${styles.content}`}>
        {panels[active].content}
      </div>
    </div>
  );
}
