import { Auth } from 'aws-amplify';
import React, {
  ChangeEvent, FormEvent, ReactElement, useCallback, useState,
} from 'react';
import { toast } from 'react-toastify';
import Button from 'stories/atoms/Button/Button';
import Input from 'stories/atoms/Input/Input';
import { AuthPages } from '../types';

interface ResetPasswordProps {
  changePage: (page: AuthPages) => void;
}
export default function ResetPassword({ changePage }: ResetPasswordProps): ReactElement {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetCodeSent, setResetCodeSent] = useState(false);
  const requestResetCode = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      try {
        await Auth.forgotPassword(username);
        setResetCodeSent(true);
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
      }
      setLoading(false);
    },
    [username],
  );

  const onChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'username':
        setUsername(event.target.value);
        break;
      case 'new-password':
        setPassword(event.target.value);
        break;
      case 'code':
        setCode(event.target.value);
        break;
      default:
        break;
    }
  }, []);

  const passwordReset = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      try {
        await Auth.forgotPasswordSubmit(username, code, password);
        toast.success('Password changed!');
        changePage('signin');
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
      }
      setLoading(false);
    },
    [changePage, code, password, username],
  );

  function checkPassword(): string {
    if (!password) { return ''; }
    if (password.length < 8) {
      return 'Password must contain at least 8 characters';
    }
    const specialCharacters = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (!specialCharacters.test(password)) {
      return 'Password must contain at least 1 symbol';
    }
    if (password.toLowerCase() === password) {
      return 'Password must contain at least 1 uppercase letter';
    }
    if (password.toUpperCase() === password) {
      return 'Password must contain at least 1 lowercase letter';
    }
    return '';
  }

  function disabled(): boolean {
    if (!password || !checkPassword || !code) {
      return true;
    }
    return false;
  }

  if (resetCodeSent) {
    return (
      <form className="auth-form" onSubmit={passwordReset}>
        <h3 className="auth-title">Reset your password</h3>
        <div className="auth-form-inputs">
          <Input wrapperClassName="auth-input" id="username" name="username" disabled defaultValue={username} fluid required label="Username" />
          <Input wrapperClassName="auth-input" onChange={onChangeHandler} id="code" name="code" fluid required label="Code" type="number" info="Code from email that has been sent to you" />
          <Input
            wrapperClassName="auth-input"
            onChange={onChangeHandler}
            id="new-password"
            name="new-password"
            fluid
            required
            label="New Password"
            type="password"
            error={checkPassword()}
            info="Password must contain at least 8 characters and contain at least 1 lowercase 1 uppercase and 1 symbol"
          />
        </div>
        <div className="auth-footer">
          <div><span className="change-page" onClick={() => changePage('signin')}>Back to Sign In</span></div>
          <Button disabled={disabled()} id="reset-password" loading={loading} type="submit">Reset</Button>
        </div>
      </form>
    );
  }
  return (
    <form className="auth-form" onSubmit={requestResetCode}>
      <h3 className="auth-title">Reset your password</h3>
      <div className="auth-form-inputs">
        <Input className="auth-input" id="username" name="username" onChange={onChangeHandler} fluid required label="Username" />
      </div>
      <div className="auth-footer">
        <div><span className="change-page" onClick={() => changePage('signin')}>Back to Sign In</span></div>
        <Button id="request-code" loading={loading} type="submit">Reset</Button>
      </div>
    </form>
  );
}
