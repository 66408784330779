import React, { ReactElement } from 'react';
import styles from './Divider.module.css';

interface DividerProps {
  text?: string;
  className?: string;
}
export default function Divider({ text = '', className }: DividerProps):ReactElement {
  return (
    <div className={className ? `${styles.divider} ${className}` : styles.divider}>{text}</div>
  );
}
