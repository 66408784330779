import React, {
  ChangeEvent, ReactElement, useCallback, useEffect, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateRepositoryAction, GetProjectByUUID, GetRepositoryTemplates } from 'store/actions/projectActions';
import Stepper from 'stories/composite/Stepper/Stepper';
import { IStepperPanel } from 'stories/composite/Stepper/types';
import { useAppDispatch, useAppSelector } from 'utils';
import { ITemplateRepositorySubmit, ITemplateInputSaved } from 'types/types';
import { DropdownOption } from 'stories/composite/Dropdown/types';
import { GetUserAuthorisationTargets } from 'store/actions/userActions';
import { GetStoredValuesAction } from 'store/actions/organisationActions';
import { DataOrigin, Parameter, VcsTarget } from 'graphql/types';
import { RepositoryDetails } from './RepositoryDetails/RepositoryDetails';
import './CreateRepository.css';
import Application from './Application/Application';
import { CiProvider } from './CiProvider/CiProvider';
import { DeploymentType } from './DeploymentType/DeploymentType';
import { RepositoryOverview } from './RepositoryOverview/RepositoryOverview';
import { TemplateInfoWithRepoName, TextAndValue } from './types';

function CreateRepository(): ReactElement {
  const [tabIndex, setTabIndex] = useState(0);
  const [visitedTabIndex, setVisitedTabIndex] = useState(0);

  const [name, setName] = useState('');
  const [privateRepo, setPrivateRepo] = useState(false);
  const [authorisedTarget, setAuthorisedTarget] = useState<DropdownOption>({
    text: 'Please select authorised target',
    value: '',
    id: '',
    key: '',
    type: '',
  });

  const [appTemplates, setAppTemplates] = useState<TemplateInfoWithRepoName[]>([]);
  const [ciTemplates, setCiTemplates] = useState<TemplateInfoWithRepoName[]>([]);
  const [deploymentTemplates, setDeploymentTemplates] = useState<TemplateInfoWithRepoName[]>([]);

  const [selectedAppType, setSelectedAppType] = useState<null | TextAndValue>(null);
  const [selectedAppTemplates, setSelectedAppTemplates] = useState<TemplateInfoWithRepoName[]>([]);

  const [ciOptions, setCiOptions] = useState<string[]>([]);
  const [selectedCiOption, setSelectedCiOption] = useState('');
  const [selectedCiTemplates, setSelectedCiTemplates] = useState<TemplateInfoWithRepoName[]>([]);

  const [selectedDeploymentTemplate, setSelectedDeploymentTemplate] = useState<TemplateInfoWithRepoName | null>(null);
  const [selectedDeploymentType, setSelectedDeploymentCategory] = useState<null | TextAndValue>(null);

  const [templateInputs, setTemplateInputs] = useState<ITemplateInputSaved[]>([]);

  const navigate = useNavigate();
  const { projectUuid } = useParams();
  const dispatch = useAppDispatch();
  const authorisationTargets = useAppSelector((state) => state.user.authorisationTargets);
  const templateRepositories = useAppSelector((state) => state.project.templateRepositories);
  const organisation = useAppSelector((state) => state.organisation.organisation);
  const storedValues = useAppSelector((state) => state.organisation.storedValues);
  const loading = useAppSelector((state) => state.project.loading);

  const removeTemplateInputsFromState = useCallback(
    (item: TemplateInfoWithRepoName) => {
      if (item.template?.inputs && item.template.inputs.length) {
        const _newTemplateInputs = [...templateInputs];
        item.template.inputs.forEach((itemInput) => {
          const foundIndex = _newTemplateInputs.findIndex((templateInput) => templateInput.field === itemInput.field);
          if (foundIndex > -1) {
            _newTemplateInputs.splice(foundIndex, 1);
          }
        });
        if (_newTemplateInputs.length !== templateInputs.length) {
          setTemplateInputs(_newTemplateInputs);
        }
      }
    },
    [templateInputs],
  );
  // Only at start
  useEffect(() => {
    dispatch(GetUserAuthorisationTargets());
    dispatch(GetRepositoryTemplates());
    if (organisation) {
      dispatch(GetStoredValuesAction(organisation.uuid));
    }
  }, [dispatch, organisation]);

  // If templateRepositories change;
  useEffect(() => {
    const _appTemplates: TemplateInfoWithRepoName[] = [];
    const _ciTemplates: TemplateInfoWithRepoName[] = [];
    const _deploymentTemplates: TemplateInfoWithRepoName[] = [];
    const _ciOptions: string[] = [];
    templateRepositories.forEach((repo) => {
      repo.templates?.forEach((template) => {
        if (template.component.name === 'applications') {
          _appTemplates.push({
            ...template,
            repository: {
              name: repo.name,
              uuid: repo.uuid,
            },
          });
        } else if (template.component.name === 'ci') {
          _ciTemplates.push({
            ...template,
            repository: {
              name: repo.name,
              uuid: repo.uuid,
            },
          });
        } else if (template.component.name === 'deployment') {
          _deploymentTemplates.push({
            ...template,
            repository: {
              name: repo.name,
              uuid: repo.uuid,
            },
          });
        }
        template.template?.ciOptions?.forEach((option) => {
          if (!_ciOptions.find((item) => item === option)) {
            _ciOptions.push(option);
          }
        });
      });
    });
    setAppTemplates(_appTemplates);
    setCiTemplates(_ciTemplates);
    setDeploymentTemplates(_deploymentTemplates);
    setCiOptions(_ciOptions);
  }, [templateRepositories]);

  const submit = useCallback(() => {
    const _submittedTemplateRepositories: ITemplateRepositorySubmit[] = [];
    selectedAppTemplates.forEach((template) => {
      const foundSubmitTemplateRepository = _submittedTemplateRepositories.find((submitRepo) => template.repository.uuid === submitRepo.templateRepositoryUuid);
      if (foundSubmitTemplateRepository) {
        foundSubmitTemplateRepository.templatePaths.push(template.path);
      } else {
        _submittedTemplateRepositories.push({
          templateRepositoryUuid: template.repository.uuid,
          templatePaths: [template.path],
        });
      }
    });
    selectedCiTemplates.forEach((template) => {
      const foundSubmitTemplateRepository = _submittedTemplateRepositories.find((submitRepo) => template.repository.uuid === submitRepo.templateRepositoryUuid);
      if (foundSubmitTemplateRepository) {
        foundSubmitTemplateRepository.templatePaths.push(template.path);
      } else {
        _submittedTemplateRepositories.push({
          templateRepositoryUuid: template.repository.uuid,
          templatePaths: [template.path],
        });
      }
    });

    if (selectedDeploymentTemplate) {
      const foundSubmitTemplateRepository = _submittedTemplateRepositories.find((submitRepo) => selectedDeploymentTemplate.repository.uuid === submitRepo.templateRepositoryUuid);
      if (foundSubmitTemplateRepository) {
        foundSubmitTemplateRepository.templatePaths.push(selectedDeploymentTemplate.path);
      } else {
        _submittedTemplateRepositories.push({
          templateRepositoryUuid: selectedDeploymentTemplate.repository.uuid,
          templatePaths: [selectedDeploymentTemplate.path],
        });
      }
    }

    const parameters: Parameter[] = templateInputs.map((input) => ({ field: input.field, value: input.value, type: input.valueType }));
    const options: Parameter[] = [{
      field: 'private',
      value: privateRepo.toString(),
      type: 'value',
    }];

    const vcsTarget: VcsTarget = {
      dataOrigin: (authorisedTarget.dataOrigin as DataOrigin).uuid,
      vcsFolder: (authorisedTarget.value as string) || undefined,
    };

    dispatch(CreateRepositoryAction(name, vcsTarget, projectUuid as string, _submittedTemplateRepositories, selectedCiOption, parameters, options)).then(() => {
      dispatch(GetProjectByUUID(projectUuid as string));
      navigate(`/projects/${projectUuid}`);
    }).catch((error) => error);
  },
  [selectedAppTemplates, selectedCiTemplates, selectedDeploymentTemplate, templateInputs, privateRepo, authorisedTarget.dataOrigin, authorisedTarget.value, dispatch, name, projectUuid, selectedCiOption, navigate]);

  const onChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'name':
        setName(event.target.value);
        break;
      case 'private':
        setPrivateRepo(event.target.checked);
        break;
      default:
        break;
    }
  }, []);
  const onAppTypeSelect = useCallback(
    (text: string, value:string) => {
      if (!(selectedAppType?.value === value)) {
        setSelectedAppType({ text, value });
        setVisitedTabIndex(1);
        // remove template inputs from state
        selectedAppTemplates.forEach((template) => removeTemplateInputsFromState(template));
        selectedCiTemplates.forEach((template) => removeTemplateInputsFromState(template));
        if (selectedDeploymentTemplate) {
          removeTemplateInputsFromState(selectedDeploymentTemplate);
        }
        setSelectedAppTemplates([]);
        setSelectedCiTemplates([]);
        setSelectedDeploymentTemplate(null);
      }
    },
    [removeTemplateInputsFromState, selectedAppTemplates, selectedAppType?.value, selectedCiTemplates, selectedDeploymentTemplate],
  );

  const onAppTemplateSelect = useCallback(
    (item: TemplateInfoWithRepoName) => {
      const _selectedAppTemplates = [...selectedAppTemplates];
      let found = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < _selectedAppTemplates.length; i++) {
        if (item.path === _selectedAppTemplates[i].path && item.repository.uuid === _selectedAppTemplates[i].repository.uuid) {
          _selectedAppTemplates.splice(i, 1);
          removeTemplateInputsFromState(item);
          found = true;
        }
      }
      if (!found) {
        _selectedAppTemplates.push(item);
      }
      setSelectedAppTemplates(_selectedAppTemplates);
      setVisitedTabIndex(1);
    }, [removeTemplateInputsFromState, selectedAppTemplates]);

  const onCiOptionSelect = useCallback(
    (item: string) => {
      if (item !== selectedCiOption) {
        setSelectedCiOption(item);
        if (selectedCiTemplates.length) {
          selectedCiTemplates.forEach((template) => {
            removeTemplateInputsFromState(template);
          });
        }
        setSelectedCiTemplates([]);
        if (selectedDeploymentTemplate) {
          removeTemplateInputsFromState(selectedDeploymentTemplate);
        }
        setSelectedDeploymentCategory(null);
        setSelectedDeploymentTemplate(null);
        setVisitedTabIndex(2);
      }
    }, [removeTemplateInputsFromState, selectedCiOption, selectedCiTemplates, selectedDeploymentTemplate]);

  const onCiTemplateSelect = useCallback(
    (item: TemplateInfoWithRepoName) => {
      const _selectedCiTemplates = [...selectedCiTemplates];
      let found = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < _selectedCiTemplates.length; i++) {
        if (item.path === _selectedCiTemplates[i].path) {
          _selectedCiTemplates.splice(i, 1);
          removeTemplateInputsFromState(item);
          found = true;
        }
      }
      if (!found) {
        _selectedCiTemplates.push(item);
      }
      setSelectedCiTemplates(_selectedCiTemplates);
    }, [removeTemplateInputsFromState, selectedCiTemplates]);

  const onDeploymentTypeSelect = useCallback(
    (item: TextAndValue) => {
      if (item.value !== selectedDeploymentType?.value) {
        setSelectedDeploymentCategory(item);
        if (selectedDeploymentTemplate) {
          removeTemplateInputsFromState(selectedDeploymentTemplate);
        }
        setSelectedDeploymentTemplate(null);
      }
    }, [removeTemplateInputsFromState, selectedDeploymentType?.value, selectedDeploymentTemplate]);

  const onDeploymentTemplateSelect = useCallback(
    (template: TemplateInfoWithRepoName) => {
      if (selectedDeploymentTemplate?.path === template.path) {
        removeTemplateInputsFromState(selectedDeploymentTemplate);
        setSelectedDeploymentTemplate(null);
      } else {
        setSelectedDeploymentTemplate(template);
      }
    }, [removeTemplateInputsFromState, selectedDeploymentTemplate]);

  const onInstallationChange = useCallback((item: DropdownOption) => {
    if (typeof item.value === 'string') setAuthorisedTarget(item);
  }, []);

  const onTabSelect = useCallback((index: number) => {
    if (index > visitedTabIndex) {
      setVisitedTabIndex(index);
    }
    setTabIndex(index);
  }, [visitedTabIndex]);

  const onTabChange = useCallback(
    (next = true) => {
      if (next) {
        onTabSelect(tabIndex + 1);
      } else {
        onTabSelect(tabIndex - 1);
      }
    },
    [tabIndex, onTabSelect],
  );

  const onTemplateInputChange = useCallback(
    (values: ITemplateInputSaved[]) => {
      const _newTemplateInputs = [...templateInputs];
      values.forEach((newValue) => {
        const foundIndex = _newTemplateInputs.findIndex((input) => input.field === newValue.field);
        if (foundIndex > -1) {
          if (newValue.value === '') {
            _newTemplateInputs.splice(foundIndex, 1);
          } else {
            _newTemplateInputs[foundIndex] = newValue;
          }
        } else {
          _newTemplateInputs.push(newValue);
        }
      });

      setTemplateInputs(_newTemplateInputs);
    },
    [templateInputs],
  );

  const panels: IStepperPanel[] = [
    {
      title: 'Repository Details',
      id: 'repository-details-tab',
      accomplished: visitedTabIndex > 0,
      content: (
        <div className="repository-creation-tab">
          <RepositoryDetails
            key="repository-details-tab"
            authorisationTargets={authorisationTargets}
            selectedInstallation={authorisedTarget}
            name={name}
            onChangeHandler={onChangeHandler}
            onInstallationChange={onInstallationChange}
            onTabChange={onTabChange}
            privateRepo={privateRepo}
          />
        </div>
      ),
    },
    {
      title: 'Application',
      id: 'application-tab',
      accomplished: visitedTabIndex >= 1,
      content: (
        <div className="repository-creation-tab">
          <Application
            key="application-tab"
            appTemplates={appTemplates}
            onSelectType={onAppTypeSelect}
            selectedAppType={selectedAppType}
            selectedAppTemplates={selectedAppTemplates}
            onTabChange={onTabChange}
            onAppTemplateSelect={onAppTemplateSelect}
            templateInputs={templateInputs}
            onTemplateInputChange={onTemplateInputChange}
            storedValues={storedValues}
          />
        </div>
      ),
    },
    {
      title: 'CI',
      id: 'ci-tab',
      accomplished: visitedTabIndex >= 2,
      content: (
        <div className="repository-creation-tab">
          <CiProvider
            key="ci-tab"
            selectedCiTemplates={selectedCiTemplates}
            onCiTemplateSelect={onCiTemplateSelect}
            ciTemplates={ciTemplates}
            ciOptions={ciOptions}
            selectedCiOption={selectedCiOption}
            onCiOptionSelect={onCiOptionSelect}
            selectedAppTemplates={selectedAppTemplates}
            onTabChange={onTabChange}
            onTemplateInputChange={onTemplateInputChange}
            templateInputs={templateInputs}
            storedValues={storedValues}
          />
        </div>
      ),
    },
    {
      title: 'Deployment',
      id: 'deployment-tab',
      accomplished: visitedTabIndex >= 3,
      content: (
        <div className="repository-creation-tab">
          <DeploymentType
            key="deployment-tab"
            selectedDeploymentTemplate={selectedDeploymentTemplate}
            deploymentTemplates={deploymentTemplates}
            onDeploymentTemplateSelect={onDeploymentTemplateSelect}
            selectedDeploymentType={selectedDeploymentType}
            onDeploymentTypeSelect={onDeploymentTypeSelect}
            onTabChange={onTabChange}
            onTemplateInputChange={onTemplateInputChange}
            templateInputs={templateInputs}
            selectedCiOption={selectedCiOption}
            storedValues={storedValues}
          />
        </div>
      ),
    },
    {
      title: 'Overview',
      id: 'overview-tab',
      accomplished: visitedTabIndex >= 4,
      content: (
        <div className="repository-creation-tab">
          <RepositoryOverview
            key="overview-tab"
            name={name}
            authorisedTarget={authorisedTarget}
            selectedAppTemplates={selectedAppTemplates}
            selectedCiOption={selectedCiOption}
            selectedCiTemplates={selectedCiTemplates}
            selectedDeploymentType={selectedDeploymentType}
            selectedDeploymentTemplate={selectedDeploymentTemplate}
            templateInputs={templateInputs}
            onTabChange={onTabChange}
            submit={submit}
            loading={loading}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <h2>Create Repository</h2>
      <Stepper panels={panels} openPanel={tabIndex} onSelect={onTabSelect} />
    </div>
  );
}

export default CreateRepository;
