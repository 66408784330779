import { Organisation } from 'graphql/types';
import React, { ReactElement, useCallback, useState } from 'react';
import { DeleteTemplateRepository } from 'store/actions/projectActions';
import Button from 'stories/atoms/Button/Button';
import Input from 'stories/atoms/Input/Input';
import Card from 'stories/composite/Card/Card';
import { CardTopAction } from 'stories/composite/Card/types';
import Modal from 'stories/composite/Modal/Modal';
import { useAppDispatch, useAppSelector, useFormFields } from 'utils';
import TemplateImport from 'views/Common/TemplateImport/TemplateImport';

interface OrganisationTemplateRepositoriesProps {
  organisation: Organisation
}
export default function OrganisationTemplateRepositories({ organisation }: OrganisationTemplateRepositoriesProps): ReactElement {
  const [repositoryModalOpen, setRepositoryModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const templateRepositories = useAppSelector((state) => state.project.templateRepositories);
  const { formFields, createChangeHandler } = useFormFields({
    filter: '',
  });

  const deleteTemplateRepository = useCallback(
    async (uuid: string) => {
      await dispatch(DeleteTemplateRepository(uuid));
    },
    [dispatch],
  );

  return (
    <>
      <div key="org-teams-tab">
        <div className="org-entities-filter-bar">
          <Input placeholder="Filter template repositories" onChange={createChangeHandler('filter')} />
          <Button id="add-org-template-repository-button" onClick={() => setRepositoryModalOpen(true)}>Import template repository</Button>
        </div>

        <div className="org-entities-list">
          {templateRepositories && templateRepositories.filter((repo) => repo.organisation !== null && repo.name.includes(formFields.filter)).map((repo) => {
            const topActions: CardTopAction[] | undefined = organisation?.amIAdmin ? [
              {
                icon: 'delete',
                color: 'white',
                onClick: () => { deleteTemplateRepository(repo.uuid); },
              },
            ] : undefined;
            return (
              <Card
                key={repo.uuid}
                noTop
                title={repo.name}
                subtitle={repo.description || ''}
                link="View repository"
                linkHref={repo.url}
                topActions={topActions}
              />
            );
          })}
        </div>

      </div>
      <Modal open={repositoryModalOpen} onClose={() => setRepositoryModalOpen(false)} id="add-repository-modal">
        <TemplateImport orgId={organisation?.uuid} close={() => setRepositoryModalOpen(false)} />
      </Modal>
    </>
  );
}
