import { Parameter } from 'graphql/types';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useFormFields } from 'utils';
import * as projectActions from 'store/actions/projectActions';
import Spinner from 'stories/atoms/Spinner/Spinner';
import { TemplateInfoWithRepoName } from 'views/CreateRepository/types';
import ScriptCard from 'stories/composite/ScriptCard/ScriptCard';
import { ITemplateInputTypes, ITemplateRepositorySubmit } from 'types/types';
import './RepositoryDetails.css';
import Identicon from 'stories/atoms/Identicon/Identicon';
import Button from 'stories/atoms/Button/Button';
import Link from 'stories/atoms/Link/Link';
import Notification from 'stories/composite/Notification/Notification';
import Checkbox from 'stories/atoms/Checkbox/Checkbox';
import Input from 'stories/atoms/Input/Input';
import RepositoryDetailsInfos from './RepositoryDetailsInfos/RepositoryDetailsInfos';

export default function RepositoryDetails(): ReactElement {
  const [projectTemplates, setProjectTemplates] = useState<TemplateInfoWithRepoName[]>([]);
  const [scriptCategories, setScriptCategories] = useState<{ category: string, templates: TemplateInfoWithRepoName[] }[]>([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [hardDelete, setHardDelete] = useState(false);

  const { createChangeHandler, formFields, setFormFields } = useFormFields({
    actionFilter: '', confirmationInput: '',
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { projectUuid, repositoryUuid } = useParams<{ projectUuid: string, repositoryUuid: string }>();
  const repository = useAppSelector((state) => state.project.currentRepository);
  const project = useAppSelector((state) => state.project.currentProject);
  const templateRepositories = useAppSelector((state) => state.project.templateRepositories);

  useEffect(() => {
    const _projectTemplates: TemplateInfoWithRepoName[] = [];
    templateRepositories.forEach((repo) => {
      repo.templates?.forEach((template) => {
        if (template.component.name === 'executor_templates' && template.type.name === 'repository') {
          _projectTemplates.push({
            ...template,
            repository: {
              name: repo.name,
              uuid: repo.uuid,
            },
          });
        }
      });
    });
    setProjectTemplates(_projectTemplates);
  }, [templateRepositories]);

  useEffect(() => {
    const _scriptCategories: { category: string, templates: TemplateInfoWithRepoName[] }[] = [];
    projectTemplates.forEach((template) => {
      const alreadyMadeCategoryIndex = _scriptCategories.findIndex((category) => category.category === template.category?.description || category.category === template.category?.name);
      if (alreadyMadeCategoryIndex >= 0) {
        _scriptCategories[alreadyMadeCategoryIndex].templates.push(template);
      } else {
        _scriptCategories.push({
          category: template.category?.description || template.category?.name || '',
          templates: [template],
        });
      }
    });
    setScriptCategories(_scriptCategories);
  }, [projectTemplates]);

  useEffect(() => {
    dispatch(projectActions.GetRepositoryByUUID(repositoryUuid as string))
      .catch(() => navigate(`/projects/${projectUuid}`));
    return () => {
      dispatch(projectActions.clearCurrentRepository());
    };
  }, [dispatch, navigate, projectUuid, repositoryUuid]);

  const submit = useCallback(
    (template: TemplateInfoWithRepoName, values: { field: string, value: string, type: ITemplateInputTypes, text: string, valueType: 'value' | 'stored'}[]) => {
      if (project && repository) {
        const submitTemplates: ITemplateRepositorySubmit[] = [
          {
            templateRepositoryUuid: template.repository.uuid,
            templatePaths: [template.path],
          },
        ];
        const parameters: Parameter[] = values.map((value) => ({ field: value.field, value: value.value, type: value.valueType }));
        dispatch(projectActions.executeTemplatesAction(project.uuid, submitTemplates, parameters, repository.uuid));
      }
    },
    [dispatch, project, repository],
  );
  const toggleDelete = useCallback(
    () => {
      setOpenDelete(!openDelete);
      setHardDelete(false);
      setFormFields({ ...formFields, confirmationInput: '' });
    },
    [formFields, openDelete, setFormFields],
  );
  const deleteRepo = useCallback(
    () => {
      dispatch(projectActions.deleteRepository(repository?.uuid as string, hardDelete, project?.uuid as string))
        .then(() => navigate(`/projects/${project?.uuid}`));
      toggleDelete();
    },
    [dispatch, repository?.uuid, hardDelete, project?.uuid, toggleDelete, navigate],
  );

  return (
    repository && project ? (
      <>
        <div className="repository-details-top">
          <h3 className="repository-details-name">
            <Identicon className="repository-details-identicon" size={50} imageHash={repository.uuid} />{repository.name}
          </h3>
          <div className="repository-details-buttons">
            <Link type="button-secondary" href={repository.url}>View repository content</Link>
            <Button iconColor="black" icon="delete" onClick={toggleDelete} />
          </div>

        </div>
        <RepositoryDetailsInfos repository={repository} />
        <div className="repository-details-actions-header">
          <h3>Actions</h3>
          <Input onChange={createChangeHandler('actionFilter')} icon="filter" placeholder="Filter actions by name" />
        </div>

        {scriptCategories.map((category) => {
          const filteredTemplates = category.templates.filter((template) => template.name.toLowerCase().includes(formFields.actionFilter.toLowerCase()));
          if (filteredTemplates.length) {
            return (
              <div key={category.category}>
                <h4>{category.category}</h4>
                <div className="repository-details-actions-category">
                  {filteredTemplates.map((template) => (
                    <ScriptCard
                      key={template.uuid}
                      id={`${template.name}-selection-box`}
                      title={template.name}
                      subtitle={template.template?.description || ''}
                      source={`From: ${template.repository.name}`}
                      inputs={template.template?.inputs || []}
                      onDeploy={(values) => submit(template, values)}
                    />
                  ))}
                </div>
              </div>
            );
          }
          return null;
        })}
        <Notification
          open={openDelete}
          id="delete-repository-notification"
          className="delete-repository-notification"
          size="custom"
          onCancel={toggleDelete}
          onConfirm={deleteRepo}
          header="Delete repository"
          confirmButton="Delete"
          confirmButtonId="confirm-delete-repository-button"
          cancelButton="Cancel"
          cancelButtonId="cancel-delete-repository-button"
          confirmDisabled={hardDelete && formFields.confirmationInput !== repository.name}
        >Are you sure you want to delete {repository.name} from Sprout?
          {project.amIAdmin ? <Checkbox className="delete-repository-checkbox" label="Delete from also Version Control System" onChange={(event) => setHardDelete(event.target.checked)} /> : null }
          {hardDelete ? (
            <>
              <div className="delete-repository-confirmation-text">This action cannot be undone. This will permanently delete the <code>{repository.name}</code> repository and everything it contains.</div>
              <div className="delete-repository-confirmation-text">Please type <code>{repository.name}</code> to confirm.</div>
              <Input onChange={createChangeHandler('confirmationInput')} />
            </>
          ) : null}
        </Notification>
      </>
    ) : <Spinner />
  );
}
