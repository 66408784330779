import { Organisation, Project, Team } from 'graphql/types';
import React, {
  ChangeEvent, ReactElement, useCallback, useState,
} from 'react';
import Button from 'stories/atoms/Button/Button';
import Search from 'stories/atoms/Search/Search';
import { SearchResult } from 'stories/atoms/Search/types';
import UserCard from 'stories/composite/UserCard/UserCard';
import * as projectActions from 'store/actions/projectActions';
import * as organisationActions from 'store/actions/organisationActions';
import { useAppDispatch } from 'utils';

interface ProjectTeamsProps {
  project: Project;
  organisation: Organisation;
}
export default function ProjectTeams({ project, organisation }: ProjectTeamsProps): ReactElement {
  const dispatch = useAppDispatch();
  const [UserSearchResults, setUserSearchResults] = useState<SearchResult[]>([]);

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.value || event.target.value.length < 3) { return; }
      dispatch(organisationActions.FindOrganisationTeams(organisation.uuid, event.target.value)).then((teams) => {
        const results: SearchResult[] = [];
        teams?.forEach((team) => {
          results.push({
            text: `${team.name}`,
            id: `${team.name}-result`,
            key: team.uuid,
            uuid: team.uuid,
          });
        });
        setUserSearchResults(results);
      });
    },
    [dispatch, organisation.uuid],
  );

  const removeTeam = useCallback(
    (team: Team) => {
      dispatch(projectActions.deleteTeamFromProject(project.uuid, team.uuid));
    },
    [dispatch, project.uuid],
  );

  const onSearchSelect = useCallback(
    (result: SearchResult) => {
      dispatch(projectActions.addTeamToProject(project.uuid, result.uuid));
    },
    [dispatch, project.uuid],
  );

  return (
    <div>
      {project.amIAdmin ? (
        <Search
          minInputLength={3}
          debounceTimer={300}
          id="search-teams"
          placeholder="Add a team"
          onChange={onSearchChange}
          onResultSelect={onSearchSelect}
          results={UserSearchResults}
        />
      ) : null }
      <div className="project-members">
        {project.teams?.map((team) => {
          const popupContent = project.amIAdmin ? (
            <div className="project-members-popup">
              <Button onClick={() => removeTeam(team.team)}>Remove</Button>
            </div>
          ) : undefined;
          return (
            <UserCard
              id={`${team.team.name}-card`}
              key={team.team.uuid}
              title={team.team.name}
              subtitle={team.team.description || ''}
              icon="team"
              popupContent={popupContent}
            />
          );
        })}
      </div>
    </div>
  );
}
