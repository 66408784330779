import { StoredValue } from 'graphql/types';
import React, { ReactElement, useCallback } from 'react';
import Button from 'stories/atoms/Button/Button';
import Info from 'stories/atoms/Info/Info';
import Dropdown from 'stories/composite/Dropdown/Dropdown';
import { DropdownOption } from 'stories/composite/Dropdown/types';
import SelectionBox from 'stories/composite/SelectionBox/SelectionBox';
import { ITemplateInputSaved } from 'types/types';
import { formatUnderscoreStrings } from 'utils';
import { onTemplateInputChangeType, TemplateInfoWithRepoName } from '../types';

interface CiProviderProps {
  ciOptions: string[],
  selectedCiOption: string,
  onCiOptionSelect: (item: string) => void,
  ciTemplates: TemplateInfoWithRepoName[],
  selectedAppTemplates: TemplateInfoWithRepoName[],
  onCiTemplateSelect: (item: TemplateInfoWithRepoName) => void,
  selectedCiTemplates: TemplateInfoWithRepoName[],
  onTabChange: (next?: boolean) => void;
  onTemplateInputChange: onTemplateInputChangeType;
  templateInputs: ITemplateInputSaved[];
  storedValues?: StoredValue[];
}

export function CiProvider({
  ciOptions, selectedCiOption, onCiOptionSelect, selectedAppTemplates, onCiTemplateSelect, selectedCiTemplates, ciTemplates,
  onTabChange, onTemplateInputChange, templateInputs, storedValues,
}: CiProviderProps): ReactElement {
  const mandatoryInputMissing = useCallback(
    () => {
      if (selectedCiTemplates.length) {
        for (let i = 0; i < selectedCiTemplates.length; i += 1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (selectedCiTemplates[i].inputs && selectedCiTemplates[i].inputs.length) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
            for (let j = 0; j < selectedCiTemplates[i].inputs.length; j += 1) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (!selectedCiTemplates[i].inputs[j].optional) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (!templateInputs.find((templateInput) => templateInput.field === selectedCiTemplates[i].inputs[j].field)) {
                  return true;
                }
              }
            }
          }
        }
      }
      return false;
    },
    [selectedCiTemplates, templateInputs],
  );
  const defaultSelectedCi: DropdownOption = {
    key: 'Select CI provider',
    text: 'Select CI provider',
    id: 'select-ci-provider',
    value: '',
  };

  const ciOptionsOptions: DropdownOption[] = [];
  ciOptions.forEach((option) => {
    const unSupportedTemplates = selectedAppTemplates.filter((template) => !template.template?.ciOptions.includes(option)).map((template) => template.name);
    ciOptionsOptions.push({
      key: option,
      value: option,
      text: unSupportedTemplates.length
        ? (
          <>
            {option}
            {
              unSupportedTemplates.length
                ? <Info className="create-repository-warning-icon" icon="warning" color="primary" content={`Manual CI setup required for these templates: ${unSupportedTemplates.join(', ')}`} />
                : null
            }
          </>
        ) : formatUnderscoreStrings(option),
      id: `${option}-option`,
    });
  });

  const onCiOptionChangeHandler = useCallback(
    (option: DropdownOption) => {
      onCiOptionSelect(option.value as string);
    },
    [onCiOptionSelect],
  );

  return (
    <>
      <h3 className="create-repository-no-top-margin">CI provider</h3>
      <Dropdown className="create-repository-dropdown" defaultValue={selectedCiOption || defaultSelectedCi} options={ciOptionsOptions} id="ci-options-dropdown" onChange={onCiOptionChangeHandler} />
      {selectedCiOption ? (
        <>
          <h4>Additional CI templates</h4>
          <div className="template-selection-container">
            {ciTemplates.filter((template) => template.category?.name === selectedCiOption)
              .map((template) => {
                let found = false;
                if (selectedCiTemplates.find((item) => item.uuid === template.uuid)) {
                  found = true;
                }
                return (
                  <SelectionBox
                    id={`${template.name}-selection-box`}
                    key={template.uuid}
                    title={template.name}
                    subtitle={template.template?.description}
                    source={`From: ${template.repository.name}`}
                    onSelect={() => onCiTemplateSelect(template)}
                    onDeselect={() => onCiTemplateSelect(template)}
                    defaultSelected={found}
                    inputs={template.template?.inputs}
                    onInputSave={onTemplateInputChange}
                    inputDefaultValues={templateInputs}
                    storedValues={storedValues}
                  />
                );
              })}
          </div>
        </>
      ) : null}
      <div className="create-repository-buttons">
        <Button id="ci-provider-back-button" onClick={() => onTabChange(false)}>Back</Button>
        <Button disabled={!selectedCiOption || mandatoryInputMissing()} id="ci-provider-next-button" onClick={() => onTabChange()}>Next</Button>
      </div>
    </>
  );
}
