import { Auth } from 'aws-amplify';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';
import './Authentication.css';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'utils';
import Spinner from 'stories/atoms/Spinner/Spinner';
import { AuthPages } from './types';
import Signin from './Signin/Signin';
import Signup from './Signup/Signup';
import { ConfirmEmail } from './ConfirmEmail/ConfirmEmail';
import ResetPassword from './ResetPassword/ResetPassword';

export default function Authentication(): ReactElement {
  const [currentPage, setCurrentPage] = useState<AuthPages>('signin');
  const [confirmUsername, setConfirmUsername] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const queryParams = useQuery();

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    if (token) {
      navigate('/projects');
    } else if (!queryParams.get('nosso')) {
      Auth.federatedSignIn();
    } else {
      setLoading(false);
    }
  }, [navigate, queryParams]);

  const signIn = useCallback(
    async (username: string, password: string) => {
      try {
        await Auth.signIn(username, password);
      } catch (error) {
        if (error instanceof Error) {
          if (error.name === 'UserNotConfirmedException') {
            setConfirmUsername(username);
            setCurrentPage('confirm');
            toast.error(error.message);
          } else {
            toast.error(error.message);
          }
        }
      }
    },
    [],
  );

  const signUp = useCallback(
    async (username: string, password: string, email: string) => {
      try {
        await Auth.signUp({ username, password, attributes: { email } });
        setConfirmUsername(username);
        setCurrentPage('confirm');
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
      }
    },
    [],
  );

  const confirm = useCallback(
    async (username: string, code: string) => {
      try {
        await Auth.confirmSignUp(username, code);
        setCurrentPage('signin');
        toast.success('Account verified! Please sign in');
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
      }
    },
    [],
  );
  if (loading) {
    return (
      <div className="auth-container">
        <Spinner />
      </div>
    );
  }
  return (
    <div className="auth-container">
      <div className="auth">
        {currentPage === 'signin' ? <Signin changePage={setCurrentPage} signIn={signIn} /> : null}
        {currentPage === 'signup' ? <Signup changePage={setCurrentPage} signUp={signUp} /> : null}
        {currentPage === 'resetpw' ? <ResetPassword changePage={setCurrentPage} /> : null}
        {currentPage === 'confirm' ? <ConfirmEmail username={confirmUsername} changePage={setCurrentPage} confirm={confirm} /> : null}
      </div>
    </div>
  );
}
