import { Organisation, StoredValue, Team } from 'graphql/types';
import { ActionType, Action } from '../actionTypes';

interface IOrganisationState {
  organisation: Organisation | null;
  teams: Team[];
  currentTeam: Team | null;
  storedValues: StoredValue[];
}

const localStorageOrg = localStorage.getItem('organisation');
const initState: IOrganisationState = {
  organisation: localStorageOrg ? JSON.parse(localStorageOrg) : null,
  teams: [],
  currentTeam: null,
  storedValues: [],
};

export default function project(state: IOrganisationState = initState, action: Action): IOrganisationState {
  switch (action.type) {
    case ActionType.GET_ORGANISATION_SUCCESS: {
      return {
        ...state,
        organisation: action.payload,
      };
    }
    case ActionType.GET_TEAMS_SUCCESS: {
      return {
        ...state,
        teams: action.payload,
      };
    }
    case ActionType.CLEAR_CURRENT_TEAM: {
      return {
        ...state,
        currentTeam: null,
      };
    }
    case ActionType.GET_TEAM_SUCCESS: {
      return {
        ...state,
        currentTeam: action.payload,
      };
    }
    case ActionType.GET_STORED_VALUES_SUCCESS: {
      return {
        ...state,
        storedValues: action.payload,
      };
    }
    case ActionType.SIGNOUT: {
      return { ...initState };
    }
    default: {
      return state;
    }
  }
}
