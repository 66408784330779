import React, {
  ChangeEvent, FormEvent, ReactElement, useCallback, useState,
} from 'react';
import Input from 'stories/atoms/Input/Input';
import Button from 'stories/atoms/Button/Button';
import { AuthPages } from '../types';

interface SigninProps {
  signIn: (username: string, password: string) => Promise<void>;
  changePage: (page: AuthPages) => void;
}
export default function Signin({ signIn, changePage }: SigninProps): ReactElement {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const onChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'username':
        setUsername(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      default:
        break;
    }
  }, []);

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      setLoading(true);
      e.preventDefault();
      signIn(username, password).finally(() => setLoading(false));
    },
    [username, password, signIn],
  );

  return (
    <form className="auth-form" onSubmit={onSubmit}>
      <h3 className="auth-title">Sign in to your account</h3>
      <div className="auth-form-inputs">
        <Input wrapperClassName="auth-input" id="username" name="username" onChange={onChangeHandler} fluid required label="Username" />
        <Input wrapperClassName="auth-input" id="password" name="password" onChange={onChangeHandler} fluid required label="Password" type="password" />
        <div className="auth-forgot-password">Forgot your password? <span className="change-page" onClick={() => changePage('resetpw')}>Reset password</span></div>
      </div>
      <div className="auth-footer">
        <div>No account? <span className="change-page" onClick={() => changePage('signup')}>Create account</span></div>
        <Button id="sign-in" loading={loading} type="submit">Sign In</Button>
      </div>
    </form>

  );
}
