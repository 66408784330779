import { StoredValue } from 'graphql/types';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import Button from 'stories/atoms/Button/Button';
import Dropdown from 'stories/composite/Dropdown/Dropdown';
import { DropdownOption } from 'stories/composite/Dropdown/types';
import SelectionBox from 'stories/composite/SelectionBox/SelectionBox';
import { ITemplateInputSaved } from 'types/types';
import {
  onTemplateInputChangeType, TemplateInfoWithRepoName, TextAndValue,
} from '../types';

interface AppStackProps {
  appTemplates: TemplateInfoWithRepoName[];
  onSelectType: (text: string, value: string) => void;
  selectedAppType: TextAndValue | null;
  selectedAppTemplates: TemplateInfoWithRepoName[];
  onTabChange: (next?: boolean) => void;
  onAppTemplateSelect: (template: TemplateInfoWithRepoName) => void;
  templateInputs: ITemplateInputSaved[];
  onTemplateInputChange: onTemplateInputChangeType;
  storedValues?: StoredValue[]
}

export default function Application({
  appTemplates, onSelectType, selectedAppType, selectedAppTemplates, onTabChange,
  onAppTemplateSelect, onTemplateInputChange, templateInputs, storedValues,
}: AppStackProps): ReactElement {
  const [appCategories, setAppCategories] = useState<{category: string, templates: TemplateInfoWithRepoName[]}[]>([]);
  const [typeOptions, setTypeOptions] = useState<DropdownOption[]>([]);

  const mandatoryInputMissing = useCallback(
    () => {
      if (selectedAppTemplates.length) {
        for (let i = 0; i < selectedAppTemplates.length; i += 1) {
          if (selectedAppTemplates[i].template?.inputs && selectedAppTemplates[i].template?.inputs?.length) {
            for (let j = 0; j < (selectedAppTemplates[i].template?.inputs?.length || 0); j += 1) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (!selectedAppTemplates[i].template.inputs[j].optional) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (!templateInputs.find((templateInput) => templateInput.field === selectedAppTemplates[i].template.inputs[j].field)) {
                  return true;
                }
              }
            }
          }
        }
      }
      return false;
    },
    [selectedAppTemplates, templateInputs],
  );

  useEffect(() => {
    const _typeOptions: DropdownOption[] = [];
    appTemplates.forEach((template) => {
      if (!_typeOptions.find((option) => option.value === template.type.name)) {
        _typeOptions.push({
          key: template.type.name,
          value: template.type.name,
          text: template.type.description || template.type.name,
          id: `${template.type.name}-option`,
        });
      }
    });
    setTypeOptions(_typeOptions);
  }, [appTemplates]);

  let defaultSelectedType: DropdownOption = {
    key: 'Select app type',
    text: 'Select application type',
    id: 'select-app-type',
    value: '',
  };
  if (selectedAppType) {
    defaultSelectedType = {
      key: selectedAppType.value,
      text: selectedAppType.text,
      id: `${selectedAppType.value}-option`,
      value: selectedAppType.value,
    };
  }
  const onSelectAppTypeChangeHandler = useCallback(
    (option: DropdownOption) => {
      onSelectType(option.text as string, option.value as string);
    },
    [onSelectType],
  );

  useEffect(() => {
    const _appCategories: { category: string, templates: TemplateInfoWithRepoName[] }[] = [];
    if (selectedAppType) {
      appTemplates.filter((template) => template.type?.name === selectedAppType.value)
        .forEach((template) => {
          const alreadyMadeCategoryIndex = _appCategories.findIndex((category) => category.category === template.category?.description || category.category === template.category?.name);
          if (alreadyMadeCategoryIndex >= 0) {
            _appCategories[alreadyMadeCategoryIndex].templates.push(template);
          } else {
            _appCategories.push({
              category: template.category?.description || template.category?.name || '',
              templates: [template],
            });
          }
        });
    }
    setAppCategories(_appCategories);
  }, [selectedAppType, appTemplates]);

  return (
    <>
      <h3 className="create-repository-no-top-margin">Application type</h3>
      <Dropdown className="create-repository-dropdown" id="app-type-dropdown" defaultValue={defaultSelectedType} options={typeOptions} onChange={onSelectAppTypeChangeHandler} />
      {selectedAppType ? (<h3 className="create-repository-no-bottom-margin">Application templates</h3>) : null}
      {
        selectedAppType && appCategories.length ? appCategories.map((category) => (
          <div key={category.category}>
            <h4>{category.category}</h4>
            <div className="template-selection-container">
              {category.templates.map((template) => {
                let selected = false;
                if (selectedAppTemplates.findIndex((item) => item.uuid === template.uuid) >= 0) {
                  selected = true;
                }
                return (
                  <SelectionBox
                    key={template.uuid}
                    id={`${template.name}-selection-box`}
                    title={template.name}
                    subtitle={template.template?.description}
                    source={`From: ${template.repository.name}`}
                    onSelect={() => onAppTemplateSelect(template)}
                    onDeselect={() => onAppTemplateSelect(template)}
                    defaultSelected={selected}
                    inputs={template.template?.inputs}
                    onInputSave={onTemplateInputChange}
                    inputDefaultValues={templateInputs}
                    storedValues={storedValues}
                  />
                );
              })}
            </div>
          </div>
        )) : null
      }
      <div className="create-repository-buttons">
        <Button id="app-stack-back-button" onClick={() => onTabChange(false)}>Back</Button>
        <Button disabled={!selectedAppTemplates.length || mandatoryInputMissing()} id="app-stack-next-button" onClick={() => onTabChange()}>Next</Button>
      </div>
    </>
  );
}
