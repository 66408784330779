import React, { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project } from 'graphql/types';
import { joinWithAnd } from 'utils';
import Button from 'stories/atoms/Button/Button';
import Card from 'stories/composite/Card/Card';

interface ProjectRepositoriesProps {
  project: Project
}
export default function ProjectRepositories({ project }: ProjectRepositoriesProps): ReactElement {
  const navigate = useNavigate();

  const goToCreateRepository = useCallback(() => {
    navigate('repository/new');
  }, [navigate]);
  return (
    <>
      <Button id="create-repository-button" onClick={goToCreateRepository}>Create Repository</Button>
      <div className="project-repositories">
        {project.repositories?.length ? project.repositories?.map((repo) => {
          const repoAttributes: { value: string, displayText: string }[] = [];
          if (repo?.attributes) {
            const usedTemplates: string[] = [];
            repo.attributes.forEach((attribute) => {
              if (attribute.name.endsWith('.template_name')) {
                usedTemplates.push(attribute.displayText);
              } else {
                repoAttributes.push(attribute);
              }
            });
            if (usedTemplates.length) {
              repoAttributes.unshift({ value: joinWithAnd(usedTemplates), displayText: 'Used Templates' });
            }
          }
          if (repo) {
            return (
              <Card
                key={repo.uuid}
                title={repo.name}
                subtitle={repo?.description || ''}
                link="View Repository"
                linkHref={`repository/${repo.uuid}`}
                imageHash={repo.uuid}
              />
            );
          }
          return null;
        }) : <div className="project-no-repositories">Project does not have any repositories.</div>}
      </div>
    </>
  );
}
