import React, {
  ReactElement, useCallback, useState, FormEvent, useRef, useEffect,
} from 'react';
import Button from 'stories/atoms/Button/Button';
import Modal from 'stories/composite/Modal/Modal';
import TemplateInputField from 'stories/composite/TemplateInputField/TemplateInputField';
import { ITemplateInputSaved } from 'types/types';
import styles from '../SelectionBox.module.css';
import { SelectionBoxInputsProps } from '../types';

export default function SelectionBoxInputs({
  open, id, inputs, inputDefaultValues, onCancel, save, storedValues,
}: SelectionBoxInputsProps): ReactElement {
  const [valueChanges, setValueChanges] = useState<ITemplateInputSaved[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const onInputValueChangeHandler = useCallback(
    (value: ITemplateInputSaved) => {
      const _newValueChanges = [...valueChanges];
      const foundIndex = _newValueChanges.findIndex((valueChange) => valueChange.field === value.field);
      if (foundIndex > -1) {
        _newValueChanges.splice(foundIndex, 1);
      }
      if (value.value || value.type === 'list') {
        _newValueChanges.push(value);
      }
      setValueChanges(_newValueChanges);
    },
    [valueChanges],
  );

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      save(valueChanges);
      if (formRef.current) {
        formRef.current.reset();
      }
    },
    [valueChanges, save],
  );
  const onReset = useCallback(
    () => {
      setValueChanges([]);
    },
    [],
  );

  useEffect(() => {
    const foundDefaultValues: ITemplateInputSaved[] = [];
    if (inputDefaultValues && inputs) {
      inputs.forEach((input) => {
        const foundDefaultValue = inputDefaultValues.find((item) => item.field === input.field);
        if (foundDefaultValue) {
          foundDefaultValues.push(foundDefaultValue);
        }
      });
    }
    setValueChanges(foundDefaultValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal disableCloseOnDimmerClick key={`${id}-modal`} id={`${id}-modal`} hideClose className={styles.modal} size="custom" open={open} onClose={(() => { /* */ })}>
      <div className={styles['modal-content']} key={`${id}-modal-content`}>
        <h3 className={styles['modal-title']}>Template inputs</h3>
        <form ref={formRef} className={styles['input-form']} onReset={onReset} onSubmit={onSubmit}>
          <div className={styles.inputs}>
            {inputs?.map((input) => <TemplateInputField key={`${id}-${input.field}`} input={input} inputDefaultValues={inputDefaultValues} onInputValueChangeHandler={onInputValueChangeHandler} storedValues={storedValues} />)}
          </div>
          <div className={styles['form-buttons']}>
            <Button variant="secondary" className={styles['cancel-modal']} onClick={onCancel}>Cancel</Button>
            <Button className={styles['save-modal']} type="submit">Save</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
