import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { useAppDispatch, useAppSelector } from 'utils';
import * as projectActions from 'store/actions/projectActions';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from 'stories/atoms/Spinner/Spinner';
import './Project.css';
import Icon from 'stories/atoms/Icon/Icon';
import TextArea from 'stories/atoms/TextArea/TextArea';

import Tabs from 'stories/composite/Tabs/Tabs';
import { IPanel } from 'stories/composite/Tabs/types';
import ProjectRepositories from './ProjectRepositories/ProjectRepositories';
import ProjectMembers from './ProjectMembers/ProjectMembers';
import ProjectTeams from './ProjectTeams/ProjectTeams';
import ProjectScripts from './ProjectScripts/ProjectScripts';

export default function Project(): ReactElement {
  const [editDescriptionOpen, setEditDescriptionOpen] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { projectUuid } = useParams<{projectUuid: string}>();
  const loading = useAppSelector((state) => state.project.loading);
  const project = useAppSelector((state) => state.project.currentProject);
  const organisation = useAppSelector((state) => state.organisation.organisation);

  const [panels, setPanels] = useState<IPanel[]>([
    {
      id: 'repositories-tab',
      title: 'Repositories',
      content: project ? <ProjectRepositories project={project} /> : null,
    },
    {
      id: 'members-tab',
      title: 'Members',
      content: project ? <ProjectMembers project={project} /> : null,
    },
  ]);

  useEffect(() => {
    const _panels = [{
      id: 'repositories-tab',
      title: 'Repositories',
      content: project ? <ProjectRepositories project={project} /> : null,
    },
    {
      id: 'members-tab',
      title: 'Members',
      content: project ? <ProjectMembers project={project} /> : null,
    },
    ];
    if (organisation) {
      _panels.push({
        id: 'teams-tab',
        title: 'Teams',
        content: project ? <ProjectTeams organisation={organisation} project={project} /> : null,
      });
      _panels.splice(1, 0, {
        id: 'actions-tab',
        title: 'Actions',
        content: project ? <ProjectScripts project={project} /> : null,
      });
    }
    setPanels(_panels);
  }, [organisation, project]);

  const editDescription = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      dispatch(projectActions.UpdateProjectDescription(projectUuid as string, event.target.value)).finally(() => {
        setEditDescriptionOpen(false);
      });
    },
    [dispatch, projectUuid],
  );

  const deleteProject = useCallback(
    async () => {
      try {
        await dispatch(projectActions.deleteProject(projectUuid as string));
        navigate('/projects');
      } catch (error) {
        //
      }
    },
    [dispatch, projectUuid, navigate],
  );
  return (
    <div className="project-container">
      {loading ? <Spinner />
        : (
          <>
            <h3 className="project-subtitle">{project?.name}{project?.amIAdmin ? <Icon id="delete-project" onClick={deleteProject} name="delete" size="small" actionable color="primary" /> : null}</h3>
            <div className="project-description">
              {editDescriptionOpen ? (
                <TextArea
                  rows={2}
                  autoFocus
                  fluid
                  defaultValue={project?.description || ''}
                  onBlur={editDescription}
                />
              ) : (
                <>
                  <p>{project?.description}</p>
                  <Icon actionable name="edit" size="small" color="primary" onClick={() => setEditDescriptionOpen(true)} />
                </>
              )}
            </div>

            <Tabs panels={panels} />
          </>
        )}
    </div>
  );
}
