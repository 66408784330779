import React, { ReactElement, useEffect, useState } from 'react';
import Button from 'stories/atoms/Button/Button';
import { DropdownOption } from 'stories/composite/Dropdown/types';
import { ITemplateInputSaved } from 'types/types';
import { TemplateInfoWithRepoName, TextAndValue } from '../types';

interface RepositoryOverviewProps {
  name: string;
  authorisedTarget: DropdownOption;
  selectedAppTemplates: TemplateInfoWithRepoName[];
  selectedCiOption: string;
  selectedCiTemplates: TemplateInfoWithRepoName[];
  selectedDeploymentType: TextAndValue | null;
  selectedDeploymentTemplate: TemplateInfoWithRepoName | null;
  templateInputs: ITemplateInputSaved[];
  onTabChange: (next?: boolean) => void;
  submit: () => void;
  loading: boolean;
}
export function RepositoryOverview({
  name, authorisedTarget, selectedAppTemplates, selectedCiOption,
  selectedCiTemplates, selectedDeploymentType, selectedDeploymentTemplate,
  onTabChange, submit, loading, templateInputs,
}: RepositoryOverviewProps): ReactElement {
  const [postActions, setPostActions] = useState<string[]>([]);

  useEffect(() => {
    const _postActions: string[] = [];
    if (selectedDeploymentTemplate && selectedDeploymentTemplate.template && selectedDeploymentTemplate.template.postActions && selectedDeploymentTemplate.template.postActions.all) {
      _postActions.push(...selectedDeploymentTemplate.template.postActions.all);
    }
    if (selectedCiOption && selectedDeploymentTemplate && selectedDeploymentTemplate.template && selectedDeploymentTemplate.template.postActions && selectedDeploymentTemplate.template.postActions[selectedCiOption]) {
      _postActions.push(...selectedDeploymentTemplate.template.postActions[selectedCiOption]);
    }
    if (selectedCiTemplates.length) {
      selectedCiTemplates.forEach((template) => {
        if (template.template && template.template.postActions) {
          if (template.template.postActions?.all) {
            _postActions.push(...template.template.postActions.all);
          }
          if (template.template.postActions[selectedCiOption]) {
            _postActions.push(...template.template.postActions[selectedCiOption]);
          }
        }
      });
    }
    if (selectedAppTemplates.length) {
      selectedAppTemplates.forEach((template) => {
        if (template.template && template.template.postActions) {
          if (template.template.postActions.all) {
            _postActions.push(...template.template.postActions.all);
          }
          if (template.template.postActions[selectedCiOption]) {
            _postActions.push(...template.template.postActions[selectedCiOption]);
          }
        }
      });
    }
    setPostActions(_postActions);
  }, [selectedCiOption, selectedCiTemplates, selectedDeploymentTemplate, selectedAppTemplates]);

  return (
    <>
      <h3 className="create-repository-detail-heading">Repository Details</h3>
      <div className="create-repository-detail">
        <div className="create-repository-detail-title">Name:</div><div>{name}</div>
      </div>
      <div className="create-repository-detail">
        <div className="create-repository-detail-title">
          Target:
        </div>
        <div>{authorisedTarget.text}</div>
      </div>
      <h3 className="create-repository-detail-heading">Templates</h3>
      {selectedAppTemplates.map((template) => (
        <div key={template.uuid}>
          {template.name}
          <ul className="create-repository-detail-input-list">
            {template.template?.inputs?.map((input) => {
              const foundInput = templateInputs.find((templateInput) => templateInput.field === input.field);
              if (foundInput && foundInput.type === 'password') {
                return (<li key={input.field}>{input.name}: ***********</li>);
              } else if (foundInput) {
                return (<li key={input.field}>{input.name}: {foundInput.text}</li>);
              }
              return null;
            })}
          </ul>
        </div>
      ))}
      <h3 className="create-repository-detail-heading">CI</h3>
      <div className="create-repository-detail">
        <div className="create-repository-detail-title">
          Provider:
        </div>
        <div>{selectedCiOption}</div>
      </div>
      {selectedCiTemplates.length
        ? (
          <>
            <h4 className="create-repository-detail-subheading">Additional CI templates</h4>
            {selectedCiTemplates.map((template) => (
              <div key={template.uuid}>
                {template.name}
                <ul className="create-repository-detail-input-list">
                  {template.template?.inputs?.map((input) => {
                    const foundInput = templateInputs.find((templateInput) => templateInput.field === input.field);
                    if (foundInput && foundInput.type === 'password') {
                      return (<li key={input.field}>{input.name}: ***********</li>);
                    } else if (foundInput) {
                      return (<li key={input.field}>{input.name}: {foundInput.text}</li>);
                    }
                    return null;
                  })}
                </ul>
              </div>
            ))}
          </>
        ) : null}

      <h3 className="create-repository-detail-heading">Deployment</h3>
      <div className="create-repository-detail">
        <div className="create-repository-detail-title">
          Provider:
        </div>
        <div>{selectedDeploymentType?.text}</div>
      </div>
      <div>
        <div className="create-repository-detail">
          <div className="create-repository-detail-title">
            Template:
          </div>
          <div>{selectedDeploymentTemplate?.name}</div>
        </div>
        {selectedDeploymentTemplate?.template?.inputs?.length
          ? (
            <ul className="create-repository-detail-input-list">
              {selectedDeploymentTemplate.template?.inputs?.map((input) => {
                const foundInput = templateInputs.find((templateInput) => templateInput.field === input.field);
                if (foundInput && foundInput.type === 'password') {
                  return (<li key={input.field}>{input.name}: ***********</li>);
                } else if (foundInput) {
                  return (<li key={input.field}>{input.name}: {foundInput.text}</li>);
                }
                return null;
              })}
            </ul>
          ) : null}
      </div>

      <h3 className="create-repository-detail-heading">Actions after deployment</h3>
      {
        postActions.length
          ? postActions.map((postAction) => (
            <div key={postAction}>{postAction}</div>
          ))
          : 'None.'
      }
      <div className="create-repository-buttons">
        <Button id="overview-back-button" onClick={() => onTabChange(false)}>Back</Button>
        <Button loading={loading} disabled={!selectedDeploymentTemplate} id="overview-next-button" onClick={submit}>Deploy</Button>
      </div>
    </>
  );
}
