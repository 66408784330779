import { configureStore } from '@reduxjs/toolkit';
import user from './reducers/userReducer';
import project from './reducers/projectReducer';
import organisation from './reducers/organisationReducer';
import admin from './reducers/adminReducer';

const store = configureStore({
  reducer: {
    user,
    project,
    organisation,
    admin,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>
