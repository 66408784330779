import { DataOrigin, Organisation } from 'graphql/types';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { GetDataOrigins } from 'store/actions/userActions';
import Button from 'stories/atoms/Button/Button';
import Icon from 'stories/atoms/Icon/Icon';
import Popup from 'stories/atoms/Popup/Popup';
import Table from 'stories/atoms/Table/Table';
import { useAppDispatch, useAppSelector } from 'utils';
import AddIntegration from './AddIntegration/AddIntegration';
import EditIntegration from './EditIntegration/EditIntegration';
import './OrganisationAdmin.css';

interface OrganisationAdminProps {
  organisation: Organisation
}

export default function OrganisationAdmin({ organisation }: OrganisationAdminProps): ReactElement {
  const [addDataOriginOpen, setAddDataOriginOpen] = useState(false);
  const [editDataOriginOpen, setEditDataOriginOpen] = useState(false);

  const [editedDataOrigin, setEditedDataOrigin] = useState<DataOrigin | null>(null);

  const dataOrigins = useAppSelector((state) => state.user.dataOrigins);
  const publicDataOrigins = useAppSelector((state) => state.user.publicDataOrigins);
  const [dataOriginRows, setDataOriginRows] = useState<(string | ReactElement)[][]>([]);

  const dispatch = useAppDispatch();

  const closeAddDataOrigin = useCallback(
    () => {
      setAddDataOriginOpen(false);
    },
    [],
  );
  const closeEditDataOrigin = useCallback(
    () => {
      setEditDataOriginOpen(false);
      setEditedDataOrigin(null);
    },
    [],
  );

  const refresh = useCallback(
    () => {
      dispatch(GetDataOrigins());
      dispatch(GetDataOrigins(true));
    },
    [dispatch],
  );

  const editDataOrigin = useCallback(
    (dataOrigin: DataOrigin) => {
      setEditedDataOrigin(dataOrigin);
      setEditDataOriginOpen(true);
    },
    [],
  );

  useEffect(() => {
    dispatch(GetDataOrigins());
    dispatch(GetDataOrigins(true));
  }, [dispatch]);

  const getActionPopup = useCallback(
    (dataOrigin: DataOrigin): string | ReactElement => {
      if (!publicDataOrigins.find((publicDataOrigin) => publicDataOrigin.uuid === dataOrigin.uuid)) {
        return (
          <Popup key={dataOrigin.uuid} on="click" trigger={<Icon actionable className="stored-value-action-trigger" name="more" color="white" />}>
            <div className="stored-value-action" onClick={() => editDataOrigin(dataOrigin)}>Edit</div>
          </Popup>
        );
      } else {
        return '';
      }
    },
    [editDataOrigin, publicDataOrigins],
  );

  useEffect(() => {
    const _dataOriginsRows = dataOrigins.map((dataOrigin) => (
      [
        dataOrigin.name,
        dataOrigin.type,
        dataOrigin.url,
        dataOrigin.apiUrl,
        getActionPopup(dataOrigin),

      ]),
    );
    setDataOriginRows(_dataOriginsRows);
  }, [dataOrigins, getActionPopup]);

  return (
    <>
      <h3>Version controll system integrations <Button variant="secondary" onClick={() => setAddDataOriginOpen(true)}>Add VCS integration</Button></h3>
      {dataOriginRows.length
        ? (
          <Table
            headers={['Name', 'Type', 'URL', 'API URL', 'Actions']}
            colSizes={['1fr', '100px', '1fr', '1fr', '100px']}
            rows={dataOriginRows}
          />
        )
        : <div>No integrations. Please add one</div>}
      <AddIntegration
        open={addDataOriginOpen}
        onClose={closeAddDataOrigin}
        dataOrigins={dataOrigins}
        publicDataOrigins={publicDataOrigins}
        organisation={organisation}
        refresh={refresh}
      />
      {!editedDataOrigin ? null : (
        <EditIntegration
          open={editDataOriginOpen}
          onClose={closeEditDataOrigin}
          dataOrigin={editedDataOrigin}
          refresh={refresh}
        />
      )}

    </>
  );
}
