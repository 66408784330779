import React, {
  useEffect, useRef, ReactElement, RefObject,
} from 'react';

interface ClickOutsideProps {
  /** Fires this when clicked outside of it's children */
  onOutsideClick(e: MouseEvent): void;
  /** Children that do not fire onOutsideClick */
  children: React.ReactElement;
  ignoreList?: RefObject<HTMLElement>[]
}
export default function OutsideClickAlerter({ children, onOutsideClick, ignoreList }: ClickOutsideProps): ReactElement {
  const ref = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (!ref?.current) {
      return undefined;
    }
    const handleClickOutside = (e: MouseEvent) => {
      if (onOutsideClick && !ref?.current?.contains(e.target as Node)) {
        if (ignoreList && ignoreList.length) {
          let found = false;
          ignoreList.forEach((ignore) => {
            if (ignore?.current?.contains(e.target as Node)) {
              found = true;
            }
          });
          if (!found) {
            onOutsideClick(e);
          }
        } else {
          onOutsideClick(e);
        }
      }
    };
    document.addEventListener('click', handleClickOutside);
    return function cleanup() {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ignoreList, onOutsideClick, ref]);
  return (<span ref={ref}>{children}</span>);
}
