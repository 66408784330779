import React, {
  CSSProperties, ReactElement, useEffect, useState,
} from 'react';
import { TableProps } from './types';
import styles from './Table.module.css';

export default function Table({
  headers, rows, actionableRows, colSizes, onRowClick,
}: TableProps): ReactElement {
  const [rowStyles, setRowStyles] = useState<CSSProperties>({});

  useEffect(() => {
    const gridTemplateColumns: string[] = [];
    if (colSizes && colSizes.length === headers.length) {
      headers.forEach((_header, index) => {
        gridTemplateColumns.push(`[line${index}] ${colSizes[index]} `);
      });
    } else {
      headers.forEach((_header, index) => {
        gridTemplateColumns.push(`[line${index}] 1fr `);
      });
    }
    const _rowStyles = {
      gridTemplateColumns: gridTemplateColumns.join(' '),
    };
    setRowStyles(_rowStyles);
  }, [colSizes, headers]);

  return (
    <div className={styles.table}>
      <div style={rowStyles} className={styles.headers}>
        {headers.map((header) => <div className={styles.cell} key={header}>{header}</div>)}
      </div>
      {rows.map((row, index) => (
        <div onClick={onRowClick ? () => onRowClick(index) : undefined} style={rowStyles} className={`${styles.row} ${actionableRows ? styles.actionable : ''}`} key={index}>
          {row.map((cell, cellIndex) => (
            <div
              className={styles.cell}
              key={`${index}-${cellIndex}-${typeof cell === 'string' || typeof cell === 'number' ? cell : cell.key}`}
              title={typeof cell === 'string' || typeof cell === 'number' ? cell.toString() : undefined}
            >{cell}
            </div>
          ))}
        </div>
      ))}
    </div>

  );
}
