import { Organisation } from 'graphql/types';
import React, {
  ChangeEvent, ReactElement, useCallback, useState,
} from 'react';
import { AddMemberToOrganisation } from 'store/actions/adminActions';
import { FindUsers } from 'store/actions/userActions';
import Button from 'stories/atoms/Button/Button';
import Input from 'stories/atoms/Input/Input';
import { SearchResult } from 'stories/atoms/Search/types';
import SearchModal from 'stories/composite/SearchModal/SearchModal';
import IsAllowed from 'stories/misc/IsAllowed/IsAllowed';
import { useAppDispatch, useFormFields } from 'utils';
import OrganisationMember from './OrganisationMember/OrganisationMember';

interface OrganisationMembersProps {
  organisation: Organisation
}
export default function OrganisationMembers({ organisation }: OrganisationMembersProps): ReactElement {
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const dispatch = useAppDispatch();
  const { formFields, createChangeHandler } = useFormFields({
    filter: '',
  });

  const searchUsers = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length >= 3) {
        const users = await dispatch(FindUsers(event.target.value));
        if (users) {
          const results: SearchResult[] = users.map((user) => ({
            id: `${user.username}-result`,
            text: `${user.username} - ${user.email}`,
            key: user.uuid,
            uuid: user.uuid,
          }));
          setSearchResults(results);
        } else {
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
      }
    }, [dispatch],
  );

  const addUserToOrganisation = useCallback(
    async (user: SearchResult) => {
      dispatch(AddMemberToOrganisation(organisation.uuid, user.uuid));
      setAddMemberOpen(false);
    },
    [dispatch, organisation.uuid],
  );
  return (
    <>
      <div key="org-members-tab">
        <div className="org-entities-filter-bar">
          <Input placeholder="Filter members" onChange={createChangeHandler('filter')} />
          <IsAllowed requiredPrivilege="isSysadmin">
            <Button onClick={() => setAddMemberOpen(true)}>Add member</Button>
          </IsAllowed>
        </div>
        <div className="org-entities-list">
          {organisation.members?.filter((member) => member.user.username.includes(formFields.filter)).map((member) => (
            <OrganisationMember key={member.user.uuid} member={member} organisation={organisation} />
          ))}
        </div>
      </div>
      <SearchModal
        id="add-orgMember-member"
        open={addMemberOpen}
        onClose={() => setAddMemberOpen(false)}
        title="Add member to Organisation"
        results={searchResults}
        onResultSelect={addUserToOrganisation}
        onChange={searchUsers}
        debounceTimer={300}
        minInputLength={3}
      />
    </>
  );
}
