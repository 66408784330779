import { StoredValue } from 'graphql/types';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import Button from 'stories/atoms/Button/Button';
import Dropdown from 'stories/composite/Dropdown/Dropdown';
import { DropdownOption } from 'stories/composite/Dropdown/types';
import SelectionBox from 'stories/composite/SelectionBox/SelectionBox';
import { ITemplateInputSaved } from 'types/types';
import { onTemplateInputChangeType, TemplateInfoWithRepoName, TextAndValue } from '../types';

interface DeploymentTypeProps {
  deploymentTemplates: TemplateInfoWithRepoName[]
  onDeploymentTypeSelect: (value: TextAndValue) => void;
  selectedDeploymentType: null | TextAndValue;
  onDeploymentTemplateSelect: (template: TemplateInfoWithRepoName) => void;
  selectedDeploymentTemplate: TemplateInfoWithRepoName | null;
  onTabChange: (next?: boolean) => void;
  onTemplateInputChange: onTemplateInputChangeType;
  selectedCiOption: string,
  templateInputs: ITemplateInputSaved[];
  storedValues?: StoredValue[];
}
export function DeploymentType({
  onDeploymentTypeSelect, selectedDeploymentType, onDeploymentTemplateSelect, onTemplateInputChange,
  selectedDeploymentTemplate, onTabChange, templateInputs, deploymentTemplates, selectedCiOption,
  storedValues,
}: DeploymentTypeProps): ReactElement {
  const [providers, setProviders] = useState<DropdownOption[]>([]);

  useEffect(() => {
    const deploymentProviderOptions: DropdownOption[] = [];
    deploymentTemplates.forEach((template) => {
      if (!deploymentProviderOptions.find((option) => template.type?.name === option.value)) {
        deploymentProviderOptions.push({
          key: template.type.name,
          value: template.type.name,
          text: template.type.description || template.type.name,
          id: `${template.type.name}-option`,
        });
      }
    });
    setProviders(deploymentProviderOptions);
  }, [deploymentTemplates]);

  const mandatoryInputMissing = useCallback(
    () => {
      if (selectedDeploymentTemplate && selectedDeploymentTemplate.template?.inputs?.length) {
        for (let i = 0; i < selectedDeploymentTemplate.template.inputs.length; i += 1) {
          if (!selectedDeploymentTemplate.template.inputs[i].optional) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignores
            if (!templateInputs.find((templateInput) => templateInput.field === selectedDeploymentTemplate.template.inputs[i].field)) {
              return true;
            }
          }
        }
      }
      return false;
    },
    [templateInputs, selectedDeploymentTemplate],
  );

  let defaultSelectedDeploymentProvider: DropdownOption = {
    key: 'Select deployment provider',
    text: 'Select deployment provider',
    id: 'select-deployment-provider',
    value: '',
  };

  if (selectedDeploymentType) {
    defaultSelectedDeploymentProvider = {
      key: selectedDeploymentType.value,
      text: selectedDeploymentType.text,
      id: `${selectedDeploymentType.value}-option`,
      value: selectedDeploymentType.value,
    };
  }

  const onDeploymentProviderOptionChangeHandler = useCallback(
    (option: DropdownOption) => {
      onDeploymentTypeSelect({ text: option.text as string, value: option.value as string });
    },
    [onDeploymentTypeSelect],
  );
  return (
    <>
      <h3 className="create-repository-no-top-margin">Deployment providers</h3>
      <Dropdown className="create-repository-dropdown" defaultValue={defaultSelectedDeploymentProvider} options={providers} id="ci-options-dropdown" onChange={onDeploymentProviderOptionChangeHandler} />

      {
        selectedDeploymentType ? (
          <>
            <h4>Templates</h4>
            <div className="template-selection-container">
              {deploymentTemplates.filter(
                (template) => template.type.name === selectedDeploymentType.value && template.template?.ciOptions.find((ciOption) => ciOption === selectedCiOption))
                .map((template) => (
                  <SelectionBox
                    id={`${template.name}-selection-box`}
                    key={template.uuid}
                    title={template.name}
                    subtitle={template.template?.description}
                    source={`From: ${template.repository.name}`}
                    inputs={template.template?.inputs}
                    onSelect={() => onDeploymentTemplateSelect(template)}
                    onDeselect={() => onDeploymentTemplateSelect(template)}
                    defaultSelected={selectedDeploymentTemplate?.uuid === template.uuid}
                    onInputSave={onTemplateInputChange}
                    inputDefaultValues={templateInputs}
                    storedValues={storedValues}
                  />
                ))}
            </div>
          </>
        )
          : null
      }
      <div className="create-repository-buttons">
        <Button id="deployment-provider-back-button" onClick={() => onTabChange(false)}>Back</Button>
        <Button disabled={!selectedDeploymentTemplate || mandatoryInputMissing()} id="deployment-provider-next-button" onClick={() => onTabChange()}>Next</Button>
      </div>
    </>
  );
}
