import { DataOrigin, Organisation } from 'graphql/types';
import React, {
  FormEvent, ReactElement, useCallback, useEffect, useState,
} from 'react';
import { AddPublicDataOriginAction, CreateDataOriginAction } from 'store/actions/organisationActions';
import Button from 'stories/atoms/Button/Button';
import Divider from 'stories/atoms/Divider/Divider';
import Input from 'stories/atoms/Input/Input';
import Label from 'stories/atoms/Label/Label';
import Modal from 'stories/composite/Modal/Modal';
import VCSOption from 'stories/composite/VCSOption/VCSOption';
import { SupportedVCS } from 'types/types';
import {
  isHttps, isUrl, useAppDispatch, useFormFields,
} from 'utils';

interface AddIntegrationProps {
  open: boolean
  onClose: () => void;
  dataOrigins: DataOrigin[]
  publicDataOrigins: DataOrigin[]
  organisation: Organisation;
  refresh: () => void;
}
export default function AddIntegration({
  open, onClose, dataOrigins, publicDataOrigins, organisation, refresh,
}: AddIntegrationProps): ReactElement {
  const [publicDataOriginsNotInOrg, setPublicDataOriginsNotInOrg] = useState<DataOrigin[]>([]);
  const dispatch = useAppDispatch();

  const { formFields, createChangeHandler, setFormFields } = useFormFields<{
    name: string;
    url: string;
    apiUrl: string;
    type: SupportedVCS | '';
    clientId: string;
    clientSecret: string,
  }>({
    name: '',
    url: '',
    apiUrl: '',
    type: '',
    clientId: '',
    clientSecret: '',
  });

  useEffect(() => {
    const _publicDataOriginsNotInOrg: DataOrigin[] = [];
    publicDataOrigins.forEach((publicDataOrigin) => {
      if (!dataOrigins.find((privateDataOrigin) => privateDataOrigin.uuid === publicDataOrigin.uuid)) {
        _publicDataOriginsNotInOrg.push(publicDataOrigin);
      }
    });
    setPublicDataOriginsNotInOrg(_publicDataOriginsNotInOrg);
  },
  [dataOrigins, publicDataOrigins],
  );
  const onAddDataOriginSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      dispatch(CreateDataOriginAction(organisation.uuid, formFields.name, formFields.type, formFields.url, formFields.apiUrl, formFields.clientId, formFields.clientSecret)).then(() => {
        refresh();
        setFormFields({
          name: '',
          url: '',
          apiUrl: '',
          type: '',
          clientId: '',
          clientSecret: '',
        });
        onClose();
      });
    },
    [dispatch, formFields, onClose, organisation.uuid, refresh, setFormFields],
  );

  const isValidUrlCheck = useCallback(
    (value: string): string | boolean => {
      if (!value) {
        return false;
      }
      if (isUrl(value) && isHttps(value)) {
        return false;
      }
      return 'Needs to be valid https URL';
    },
    [],
  );

  const onAddPublicDataOrigin = useCallback(
    (dataOrigin: DataOrigin) => {
      dispatch(AddPublicDataOriginAction(organisation.uuid, dataOrigin.uuid))
        .then(() => {
          refresh();
          setFormFields({
            name: '',
            url: '',
            apiUrl: '',
            type: '',
            clientId: '',
            clientSecret: '',
          });
          onClose();
        });
    },
    [dispatch, onClose, organisation.uuid, refresh, setFormFields],
  );

  return (
    <Modal className="org-admin-add-integration-modal" open={open} onClose={onClose}>
      <>
        <h2 className="org-admin-add-integration-title">Add VCS Integration</h2>
        <form onSubmit={onAddDataOriginSubmit} className="org-admin-add-integration-form">
          <Input
            wrapperClassName="org-admin-add-integration-input"
            required
            label="Name"
            onChange={createChangeHandler('name')}
          />
          <Label text="Type *" />
          <div style={{
            display: 'flex', padding: '5px', justifyContent: 'space-around',
          }}
          >
            <VCSOption value="GitHub" current={formFields.type} onClick={() => setFormFields({ ...formFields, type: 'GitHub' })} />
            {/* <VCSOption value="Bitbucket" current={formFields.type} onClick={() => setFormFields({ ...formFields, type: 'Bitbucket' })} />
            <VCSOption value="GitLab" current={formFields.type} onClick={() => setFormFields({ ...formFields, type: 'GitLab' })} /> */}
          </div>
          <Input
            wrapperClassName="org-admin-add-integration-input"
            required
            label="URL"
            onChange={createChangeHandler('url')}
            info="Base URL of the version control system"
            error={isValidUrlCheck(formFields.url)}
          />
          <Input
            wrapperClassName="org-admin-add-integration-input"
            required
            label="API URL"
            onChange={createChangeHandler('apiUrl')}
            info="API URL of the version control system"
            error={isValidUrlCheck(formFields.apiUrl)}
          />
          <Input
            wrapperClassName="org-admin-add-integration-input"
            required
            label="Client ID"
            onChange={createChangeHandler('clientId')}
            info="Client ID of the OAuth application configured in the version control system"
          />
          <Input
            type="password"
            wrapperClassName="org-admin-add-integration-input"
            required
            label="Client secret"
            onChange={createChangeHandler('clientSecret')}
            info="Client secret of the OAuth application configured in the version control system"
          />
          <Button className="org-admin-add-integration-button" type="submit">Create</Button>
        </form>
        {publicDataOriginsNotInOrg.length ? (
          <>
            <Divider className="org-admin-add-integration-divider" text="OR" />
            <h3 className="org-admin-add-integration-title">Allow members to use a Cloud service</h3>
            {publicDataOriginsNotInOrg.map((dataOrigin) => (
              <VCSOption key={dataOrigin.uuid} value={dataOrigin.name as SupportedVCS} onClick={() => onAddPublicDataOrigin(dataOrigin)} />
            ))}
          </>
        ) : null}
      </>
    </Modal>
  );
}
