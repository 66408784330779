import React, { ReactElement, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { GetStoredValuesAction } from 'store/actions/organisationActions';
import { GetProjectByUUID, GetRepositoryTemplates } from 'store/actions/projectActions';
import { useAppDispatch, useAppSelector } from 'utils';

export default function ProjectWrapper(): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { projectUuid } = useParams<{ projectUuid: string }>();
  const organisation = useAppSelector((state) => state.organisation.organisation);
  useEffect(() => {
    dispatch(GetProjectByUUID(projectUuid as string)).catch(() => navigate('/projects'));
    dispatch(GetRepositoryTemplates());
    if (organisation) {
      dispatch(GetStoredValuesAction(organisation.uuid));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Outlet />
  );
}
