/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {
  ReactElement, useEffect, useRef, useState, KeyboardEvent, FocusEvent,
} from 'react';
import Icon from 'stories/atoms/Icon/Icon';
import OutsideClickAlerter from 'stories/misc/OutsideClickAlerter/OutsideClickAlerter';
import styles from './Dropdown.module.css';
import { DropdownOption, DropdownProps } from './types';

export default function Dropdown({
  defaultValue, onChange, id, options, className, fluid,
}: DropdownProps): ReactElement {
  const [listOpen, setListOpen] = useState(false);
  const [selected, setSelected] = useState<string | ReactElement>('');

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        setSelected(defaultValue);
      } else {
        setSelected(defaultValue.text);
      }
    }
  }, [defaultValue]);

  function close() {
    setListOpen(false);
  }

  function open() {
    setListOpen(true);
  }

  function selectItem(item: DropdownOption) {
    onChange(item);
    setSelected(item.text);
    setListOpen(false);
  }

  function onBlurHandler(event: FocusEvent<HTMLSpanElement, Element> | FocusEvent<HTMLLIElement, Element>) {
    if (wrapperRef === null || !wrapperRef.current) return;
    if (!wrapperRef.current.contains(event.nativeEvent.relatedTarget as Node)) {
      close();
    }
  }

  function onKeypressHandler(event: KeyboardEvent<HTMLLIElement>, item: DropdownOption) {
    if (event.key === 'Enter' || event.key === ' ') {
      selectItem(item);
    }
  }

  function generateWrapperClassName():string {
    let wrapperClassName = styles['dropdown-wrapper'];
    if (className) wrapperClassName += ` ${className}`;
    if (fluid) wrapperClassName += ` ${styles.fluid}`;
    return wrapperClassName;
  }
  return (
    <OutsideClickAlerter onOutsideClick={() => close()}>
      <div ref={wrapperRef} id={id} className={generateWrapperClassName()}>
        <div className={`${styles['dropdown-header']} ${listOpen ? styles['remove-border'] : ''}`} onClick={open}>
          <div className={styles['dropdown-filter-wrapper']}>
            <span className={styles['selected-label']} onBlur={onBlurHandler} tabIndex={0} onFocus={open}>{selected}</span>
            <Icon size="tiny" color="white" name="fullArrowDown" />
          </div>
        </div>
        {listOpen && (
          <ul className={styles['dropdown-menu']} onClick={close}>
            { options.map((item) => (
              <li
                role="option"
                aria-selected="false"
                tabIndex={0}
                className={`${styles['dropdown-item']} ${selected === item.text ? styles.active : ''}`}
                key={item.key}
                id={item.id}
                onClick={() => selectItem(item)}
                onBlur={onBlurHandler}
                onKeyPress={(event) => onKeypressHandler(event, item)}
              >
                {item.text}
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickAlerter>
  );
}
