import { StoredValue } from 'graphql/types';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import Button from 'stories/atoms/Button/Button';
import Checkbox from 'stories/atoms/Checkbox/Checkbox';
import Info from 'stories/atoms/Info/Info';
import Input from 'stories/atoms/Input/Input';
import Label from 'stories/atoms/Label/Label';
import Popup, { closePopup } from 'stories/atoms/Popup/Popup';
import { ITemplateJSONInput, ITemplateInputSaved } from 'types/types';
import { useFormFields } from 'utils';
import Dropdown from '../Dropdown/Dropdown';
import { DropdownOption } from '../Dropdown/types';
import styles from './TemplateInputField.module.css';

interface TemplateInputFieldProps {
  inputDefaultValues?: ITemplateInputSaved[];
  input: ITemplateJSONInput;
  onInputValueChangeHandler: (value: ITemplateInputSaved) => void
  storedValues?: StoredValue[];
}
export default function TemplateInputField({
  inputDefaultValues, input, onInputValueChangeHandler, storedValues,
}: TemplateInputFieldProps): ReactElement | null {
  const [defaultValue, setDefaultValue] = useState('');
  const [storedValueUsed, setStoredValueUsed] = useState<StoredValue | null>();
  const [loaded, setLoaded] = useState(false);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const { formFields, createChangeHandler } = useFormFields({
    filter: '',
  });
  useEffect(() => {
    let foundDefaultValue: ITemplateInputSaved | undefined;
    if (inputDefaultValues) {
      foundDefaultValue = inputDefaultValues.find((item) => item.field === input.field);
      if (foundDefaultValue) {
        if (foundDefaultValue.valueType === 'stored' && storedValues) {
          const foundStoredValueUsed = storedValues.find((storedValue) => storedValue.uuid === foundDefaultValue?.value);
          setStoredValueUsed(foundStoredValueUsed);
        } else {
          setDefaultValue(foundDefaultValue.text);
        }
      }
    }
    if (input.type === 'checkbox' && !input.optional && !foundDefaultValue) {
      onInputValueChangeHandler({
        field: input.field, value: 'false', type: input.type, text: 'Off', valueType: 'value',
      });
    }
    setLoaded(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.field, input.optional, input.type, inputDefaultValues]);

  useEffect(() => {
    if (input.type === 'list' && input.options?.length) {
      const _options: DropdownOption[] = [];
      input.options.forEach((option) => {
        _options.push({
          text: option.text,
          value: option.value,
          id: `${input.field}-${option.value}`,
          key: option.value,
        });
      });
      setOptions(_options);
    }
  }, [input.field, input.options, input.type]);

  const storedValueClick = useCallback(
    (storedValue: StoredValue) => {
      setStoredValueUsed(storedValue);
      onInputValueChangeHandler({
        field: input.field,
        value: storedValue.uuid,
        type: input.type,
        text: `Stored value: ${storedValue.name}`,
        valueType: 'stored',
      });
      closePopup();
    },
    [input.field, input.type, onInputValueChangeHandler],
  );

  const storedValueClear = useCallback(
    () => {
      setStoredValueUsed(null);
      onInputValueChangeHandler({
        field: input.field,
        value: '',
        type: input.type,
        text: '',
        valueType: 'stored',
      });
    },
    [input.field, input.type, onInputValueChangeHandler],
  );

  if (!loaded) { return null; }
  if (input.type === 'list') {
    return (
      <div key={input.field}>
        <Label className={styles['input-field']} text={`${input.name} ${!input.optional ? '*' : ''}`} info={input.description} />
        <Dropdown
          options={options}
          fluid
          defaultValue={defaultValue}
          id={input.field}
          onChange={(item) => {
            onInputValueChangeHandler({
              field: input.field as string,
              value: item.value as string,
              type: input.type,
              text: item.text as string,
              valueType: 'value',
            });
          }}
        />
      </div>
    );
  }
  if (input.type === 'checkbox') {
    return (
      <div key={input.field}>
        <Checkbox
          className={styles['input-field']}
          id={input.field}
          name={input.field}
          defaultChecked={defaultValue === 'On'}
          label={`${input.name} ${!input.optional ? '*' : ''}`}
          info={input.description}
          onChange={(event) => {
            onInputValueChangeHandler({
              field: event.target.name,
              value: event.target.checked.toString(),
              type: input.type,
              text: event.target.checked ? 'On' : 'Off',
              valueType: 'value',
            });
          }}
        />
      </div>
    );
  }

  return (
    <div className={styles['input-wrapper']}>
      {storedValues && storedValues.length
        ? (
          <Popup triggerClasses={styles['stored-value-trigger']} on="click" position="bottom-right" trigger={<div>Stored values</div>}>
            <>
              <Input onChange={createChangeHandler('filter')} defaultValue={formFields.filter} placeholder="Filter" icon="search" fluid />
              <div className={styles['stored-values']}>
                {storedValues
                  .filter((storedValue) => storedValue.name.toLowerCase().includes(formFields.filter.toLowerCase()))
                  .map((storedValue) => (
                    <div
                      onClick={() => storedValueClick(storedValue)}
                      className={styles['stored-value']} key={storedValue.uuid}
                    >{storedValue.name} {storedValue.description ? <Info className={styles['stored-value-info']} content={storedValue.description} /> : null}
                    </div>
                  ))}
              </div>
            </>
          </Popup>
        ) : null}
      {storedValueUsed ? (
        <div className={styles['stored-value-used']}>
          <Label className={styles['input-field']} text={`${input.name} ${!input.optional ? '*' : ''}`} />
          <div className={styles['stored-value-used-value']}>
            {storedValueUsed.name}
            <Button variant="secondary" onClick={storedValueClear}>Clear</Button>
          </div>
        </div>
      )
        : (
          <Input
            required={!input.optional}
            key={input.field}
            type={input.type}
            name={input.field}
            id={input.field}
            onChange={(event) => {
              onInputValueChangeHandler({
                field: event.target.name, value: event.target.value, type: input.type, text: event.target.value, valueType: 'value',
              });
            }}
            label={input.name}
            info={input.description}
            defaultValue={defaultValue}
            wrapperClassName={styles['input-field']}
          />
        )}

    </div>
  );
}
