import React, { ReactElement, useCallback, useState } from 'react';
import {
  Member, Organisation, TeamMember, User,
} from 'graphql/types';
import UserCard from 'stories/composite/UserCard/UserCard';
import Modal from 'stories/composite/Modal/Modal';
import { useAppDispatch, useFormFields } from 'utils';
import * as userActions from 'store/actions/userActions';
import * as organisationActions from 'store/actions/organisationActions';
import * as adminActions from 'store/actions/adminActions';
import Spinner from 'stories/atoms/Spinner/Spinner';
import Icon from 'stories/atoms/Icon/Icon';
import Input from 'stories/atoms/Input/Input';
import Dropdown from 'stories/composite/Dropdown/Dropdown';
import { DropdownOption } from 'stories/composite/Dropdown/types';
import IsAllowed from 'stories/misc/IsAllowed/IsAllowed';

interface OrganisationMemberProps {
  member: Member,
  organisation: Organisation;
}
export default function OrganisationMember({ member, organisation }: OrganisationMemberProps): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editingRole, setEditingRole] = useState(false);
  const [userDetails, setUserDetails] = useState<User | null>(null);
  const { formFields, createChangeHandler } = useFormFields({
    filter: '',
  });
  const dispatch = useAppDispatch();

  const onModalOpen = useCallback(
    async () => {
      setLoading(true);
      setModalOpen(true);
      const user = await dispatch(userActions.GetUserByUUID(member.user.uuid));
      if (user) {
        setUserDetails(user);
      } else {
        setModalOpen(false);
      }
      setLoading(false);
    },
    [dispatch, member.user.uuid],
  );
  const onModalClose = useCallback(
    () => {
      setModalOpen(false);
      setUserDetails(null);
    },
    [],
  );

  const removeTeam = useCallback(
    (team: TeamMember) => {
      dispatch(organisationActions.DeleteTeamMember(team.team.uuid, member.user.uuid));
    },
    [dispatch, member.user.uuid],
  );

  const editRole = useCallback(
    () => {
      setEditingRole(true);
    },
    [],
  );

  const changeRole = useCallback(
    (option: DropdownOption) => {
      setEditingRole(false);
      if (organisation && (option.value === 'User' || option.value === 'Admin')) {
        dispatch(organisationActions.UpdateOrgMember(organisation.uuid, member.user.uuid, option.value));
      }
    },
    [dispatch, member.user.uuid, organisation],
  );

  const removeUserFromOrganisation = useCallback(
    () => {
      dispatch(adminActions.RemoveMemberFromOrganisation(organisation.uuid, member.user.uuid));
      setModalOpen(false);
    },
    [dispatch, member.user.uuid, organisation.uuid],
  );

  return (
    <>
      <UserCard
        key={member.user.uuid}
        icon={member.role.name === 'Admin' ? 'admin' : 'user'}
        title={member.user.username}
        subtitle={member.user.email}
        onClick={onModalOpen}
      />
      <Modal open={modalOpen} onClose={onModalClose} hideClose={loading}>
        {loading ? <Spinner /> : (
          <div className="org-entity-modal">
            <div className="org-entity-modal-top">
              <Icon
                name={member.role.name === 'Admin' ? 'admin' : 'user'}
                size="large"
                color="white"
              />
              <h2>{userDetails?.username}</h2>
              <IsAllowed requiredPrivilege="isSysadmin">
                <Icon
                  name="delete"
                  size="small"
                  color="primary"
                  actionable
                  onClick={removeUserFromOrganisation}
                />
              </IsAllowed>
            </div>
            <div className="org-entity-modal-info">
              <div className="org-entity-modal-info-title">Email:</div>
              <div className="org-entity-modal-info-value">{userDetails?.email}</div>
              <div className="org-entity-modal-info-title">Role:</div>
              <div className="org-entity-modal-info-value">
                {editingRole ? (
                  <Dropdown
                    id="role-dropdown"
                    options={[
                      {
                        id: 'role-dropdown-user', key: 'User', text: 'User', value: 'User',
                      },
                      {
                        id: 'role-dropdown-admin', key: 'Admin', text: 'Admin', value: 'Admin',
                      }]}
                    onChange={changeRole}
                    defaultValue={member.role.name}
                  />
                )
                  : (
                    <>{member.role.name}
                      <IsAllowed requiredPrivilege="isAllowedOrgEdit" organisation={organisation}>
                        <Icon
                          name="edit"
                          size="tiny"
                          color="primary"
                          onClick={editRole}
                          actionable
                        />
                      </IsAllowed>
                    </>
                  )}
              </div>
            </div>
            <h3>Teams</h3>
            <div className="org-entities-filter-bar">
              <Input onChange={createChangeHandler('filter')} placeholder="Filter teams by name" />
            </div>
            <div className="org-entities-list">
              {userDetails?.teams?.filter((team) => (team.team.name.includes(formFields.filter))).map((team) => (
                <UserCard
                  key={team.team.uuid}
                  title={team.team.name}
                  subtitle={team.team.description || ''}
                  icon="team"
                  popupContent={(
                    <div className="entity-popup-action" onClick={() => removeTeam(team)}>Remove from team</div>
                  )}
                />
              ))}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
