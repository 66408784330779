import { Project, Repository, TemplateRepository } from 'graphql/types';
import { ActionType, Action } from '../actionTypes';

interface IProjectState {
  projects: Project[],
  currentProject: Project | null,
  loading: boolean,
  templateRepositories: TemplateRepository[],
  currentRepository: Repository | null,
}

const initState: IProjectState = {
  projects: [],
  currentProject: null,
  loading: false,
  templateRepositories: [],
  currentRepository: null,
};

export default function project(state:IProjectState = initState, action:Action):IProjectState {
  switch (action.type) {
    case ActionType.GET_PROJECTS_SUCCESS: {
      return {
        ...state,
        projects: action.payload,
        loading: false,
      };
    }
    case ActionType.PROJECTS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_CURRENT_PROJECT_SUCCESS:
    case ActionType.UPDATE_PROJECT_SUCCESS: {
      return {
        ...state,
        currentProject: action.payload,
        loading: false,
      };
    }
    case ActionType.GET_PROJECTS_FAILED:
    case ActionType.CREATE_PROJECT_FAILED:
    case ActionType.GET_CURRENT_PROJECT_FAILED: {
      return {
        ...state,
        projects: [],
        currentProject: null,
        loading: false,
      };
    }
    case ActionType.CLEAR_CURRENT_PROJECT:
    case ActionType.DELETE_PROJECT_SUCCESS: {
      return {
        ...state,
        currentProject: null,
      };
    }
    case ActionType.GET_TEMPLATE_REPOSITORIES_SUCCESS: {
      return {
        ...state,
        templateRepositories: action.payload,
      };
    }
    case ActionType.CREATE_REPOSITORY_FAILED:
    case ActionType.CREATE_REPOSITORY_SUCCESS:
    case ActionType.CREATE_PROJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.GET_CURRENT_REPOSITORY_SUCCESS: {
      return {
        ...state,
        currentRepository: action.payload,
      };
    }
    case ActionType.CLEAR_CURRENT_REPOSITORY: {
      return {
        ...state,
        currentRepository: null,
      };
    }
    case ActionType.SIGNOUT: {
      return { ...initState };
    }
    default: {
      return state;
    }
  }
}
