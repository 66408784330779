import React, { ReactElement, useCallback, useState } from 'react';

import { ITemplateInputSaved } from 'types/types';
import Icon from 'stories/atoms/Icon/Icon';
import Button from 'stories/atoms/Button/Button';
import { ScriptCardProps } from './types';

import styles from './ScriptCard.module.css';
import ScriptCardInputs from './ScriptCardInputs/ScriptCardInputs';

export default function ScriptCard({
  title, subtitle, inputs, id, source, onDeploy, storedValues,
}: ScriptCardProps): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);
  const select = useCallback(
    () => {
      setModalOpen(true);
    },
    [],
  );

  const closeModal = useCallback(
    () => {
      setModalOpen(false);
    },
    [],
  );

  const deploy = useCallback(
    (values: ITemplateInputSaved[]): void => {
      onDeploy(values);
    },
    [onDeploy],
  );
  return (
    <div
      className={`${styles['selection-box']}`}
      id={id}
    >
      <div className={styles.body}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.source}><Icon color="white" size="small" name="arrowDownRight" />{source}</div>
        {subtitle ? <p className={styles.subtitle}>{subtitle}</p> : null}

        <Button onClick={select} className={styles.button} icon="arrowRight" variant="secondary" iconPlace="right">Deploy action</Button>

      </div>
      <ScriptCardInputs
        title={title}
        open={modalOpen}
        id={`${id}-modal`}
        toggleModal={closeModal}
        inputs={inputs}
        deploy={deploy}
        storedValues={storedValues}
      />
    </div>
  );
}
