import { ReactElement, useCallback } from 'react';
import { Organisation, Project, Team } from 'graphql/types';
import { useAppSelector } from 'utils';

type RequiredPrivileges = 'any' | 'isSysadmin' | 'isNotSysadmin' | 'isOrganisationAdmin' | 'isTeamAdmin' | 'isProjectAdmin' |
'isAllowedOrgEdit' | 'isAllowedProjectEdit' | 'isAllowedTeamEdit'

interface IsAllowedProps {
  requiredPrivilege: RequiredPrivileges,
  children: ReactElement,
  organisation?: Organisation,
  team?: Team,
  project?: Project
}
export default function IsAllowed({
  requiredPrivilege, children, organisation, team, project,
}: IsAllowedProps): ReactElement | null {
  const currentUserRole = useAppSelector((state) => state.user.user?.role.name);

  const isTeamAdmin = useCallback(
    (): boolean => {
      if (team && team.amIAdmin) {
        return team.amIAdmin;
      }
      return false;
    },
    [team],
  );

  const isSysadmin = useCallback(
    (): boolean => (currentUserRole === 'SysAdmin'),
    [currentUserRole],
  );

  const isNotSysadmin = useCallback(
    (): boolean => (currentUserRole !== 'SysAdmin'),
    [currentUserRole],
  );

  const isOrganisationAdmin = useCallback(
    (): boolean => {
      if (organisation) {
        return organisation.amIAdmin;
      }
      return false;
    },
    [organisation],
  );

  const isProjectAdmin = useCallback(
    (): boolean => {
      if (project) {
        return project.amIAdmin;
      }
      return false;
    },
    [project],
  );

  const isAllowedOrgEdit = useCallback(
    (): boolean => isOrganisationAdmin() || isSysadmin(),
    [isOrganisationAdmin, isSysadmin],
  );

  const isAllowedTeamEdit = useCallback(
    (): boolean => isOrganisationAdmin() || isTeamAdmin() || isSysadmin(),
    [isOrganisationAdmin, isSysadmin, isTeamAdmin],
  );

  const isAllowedProjectEdit = useCallback(
    ():boolean => isProjectAdmin(),
    [isProjectAdmin],
  );

  const checkPrivileges = useCallback(
    (): boolean => {
      const privilegeConfig: {[x: string] : () => boolean} = {
        any: () => true,
        isSysadmin,
        isNotSysadmin,
        isOrganisationAdmin,
        isTeamAdmin,
        isProjectAdmin,
        isAllowedOrgEdit,
        isAllowedTeamEdit,
        isAllowedProjectEdit,
      };
      const matchingRole = privilegeConfig[requiredPrivilege];
      return matchingRole ? matchingRole() : false;
    },
    [isAllowedOrgEdit, isAllowedProjectEdit, isAllowedTeamEdit, isNotSysadmin,
      isOrganisationAdmin, isProjectAdmin, isSysadmin, isTeamAdmin, requiredPrivilege],
  );

  return checkPrivileges() ? children : null;
}
