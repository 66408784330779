import { Amplify, Hub } from 'aws-amplify';
import React, { ReactElement, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { LoginAction, LogoutAction } from 'store/actions/userActions';
import { useAppDispatch } from 'utils';

interface IHistoryFrom {
  from: {
    hash: string,
    key: string,
    pathname: string,
    search: string,
  }
}

const amplifyConfig = {
  Auth: {
    endpoint: process.env.REACT_APP_MOCK_COGNITO ? process.env.REACT_APP_MOCK_COGNITO : undefined,
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_MOCK_COGNITO ? 'USER_PASSWORD_AUTH' : undefined,
    oauth: {
      domain: process.env.REACT_APP_HOSTED_UI,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${window.location.origin}/authorize`,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

export default function CognitoAuth(): ReactElement {
  const dispatch = useAppDispatch();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  Amplify.configure(amplifyConfig);
  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      // eslint-disable-next-line default-case
      switch (payload.event) {
        case 'signIn':
        case 'cognitoHostedUI':
          if (pathname === '/authorize' || pathname === '/login') {
            dispatch(LoginAction()).then(() => {
              if (state) {
                const historyState = state as IHistoryFrom;
                navigate(historyState.from.pathname + historyState.from.search);
              } else {
                navigate('/projects');
              }
            });
          }
          break;
        case 'signOut':
        case 'oAuthSignOut':
          navigate('/logout');
          localStorage.removeItem('user');
          localStorage.removeItem('organisation');
          localStorage.removeItem('token');
          dispatch(LogoutAction());
      }
    });

    return unsubscribe;
  }, [dispatch, navigate, pathname, state]);
  return (
    <Outlet />
  );
}
