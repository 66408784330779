import { User, DataOrigin } from 'graphql/types';
import { IAuthorisedTargets } from 'types/types';
import { ActionType, Action } from '../actionTypes';

export interface ICognitoUserState {
  user: User | null,
  authorisationTargets: IAuthorisedTargets | null,
  githubState: string | null,
  loading: boolean,
  token: string | null,
  dataOrigins: DataOrigin[],
  publicDataOrigins: DataOrigin[],
}
const localStorageUser = localStorage.getItem('user');
const localStorageToken = localStorage.getItem('token');
const initState: ICognitoUserState = {
  user: localStorageUser ? JSON.parse(localStorageUser) : null,
  authorisationTargets: null,
  githubState: null,
  loading: false,
  token: localStorageToken || null,
  dataOrigins: [],
  publicDataOrigins: [],
};

export default function user(state:ICognitoUserState = initState, action:Action):ICognitoUserState {
  switch (action.type) {
    case ActionType.SIGNIN: {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    }
    case ActionType.SIGNOUT: {
      return { ...initState, user: null, token: null };
    }
    case ActionType.GET_USER_AUTHORISATION_TARGETS_SUCCESS: {
      return {
        ...state,
        authorisationTargets: action.payload,
        loading: false,
      };
    }
    case ActionType.CREATE_GITHUB_STATE_SUCCESS: {
      return {
        ...state,
        githubState: action.payload,
        loading: false,
      };
    }
    case ActionType.CREATE_GITHUB_STATE_FAILED: {
      return {
        ...state,
        githubState: null,
        loading: false,
      };
    }
    case ActionType.GET_USER_AUTHORISATION_TARGETS_FAILED: {
      return {
        ...state,
        authorisationTargets: null,
        loading: false,
      };
    }
    case ActionType.USER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_DATA_ORIGINS_SUCCESS: {
      return {
        ...state,
        dataOrigins: action.payload,
      };
    }
    case ActionType.GET_PUBLIC_DATA_ORIGINS_SUCCESS: {
      return {
        ...state,
        publicDataOrigins: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
