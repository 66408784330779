import { User } from 'graphql/types';
import React, { ReactElement, ReactNode } from 'react';
import { NavItemProps } from '../NavItem/types';
import PageContent from '../PageContent/PageContent';
import SideNav from '../SideNav/SideNav';
import styles from './Page.module.css';

interface PageProps {
  onLogout: () => void;
  children: ReactNode;
  topNavItems: NavItemProps[];
  bottomNavItems: NavItemProps[];
  user: User;
}
export default function Page({
  onLogout, topNavItems, bottomNavItems, children, user,
}: PageProps):ReactElement {
  return (
    <div className={styles.page}>
      <SideNav user={user} topNavItems={topNavItems} bottomNavItems={bottomNavItems} onLogout={onLogout} />
      <PageContent>{children}</PageContent>
    </div>
  );
}
