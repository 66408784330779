import React, { ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetOrganisationByUuid, GetOrganisationTeams } from 'store/actions/adminActions';
import Spinner from 'stories/atoms/Spinner/Spinner';
import { useAppDispatch, useAppSelector } from 'utils';
import Organisation from 'views/Organisation/Organisation';

export default function AdminOrganisationWrapper(): ReactElement {
  const { orgUuid } = useParams<{ orgUuid: string }>();
  const dispatch = useAppDispatch();
  const organisation = useAppSelector((state) => state.admin.currentOrganisation);
  const teams = useAppSelector((state) => state.admin.currentOrganisationTeams);

  useEffect(() => {
    if (orgUuid) {
      dispatch(GetOrganisationByUuid(orgUuid));
      dispatch(GetOrganisationTeams(orgUuid));
    }
    return () => {
      /** clear the org  */
    };
  }, [dispatch, orgUuid]);

  return (
    organisation ? <Organisation organisation={organisation} teams={teams} /> : <Spinner />
  );
}
