export function getProjectsQuery(): string { return 'query { projects { success errors projects { name description uuid }}}'; }

export function getProjectByUUIDQuery(uuid: string): string {
  return `query { 
    project(projectUuid: "${uuid}") { 
      success
      errors
      project {
        name
        description
        uuid
        repositories {
          uuid
          url
          name
          description
          attributes {
            displayText
            value
            name
          }
        }
        members {
          role {
            name
          }
          user {
            uuid
            username
            email
            name
          }
        }
        teams {
          team {
            uuid
            name
            description
          }
        }
      }
    }
  }`;
}

export function getTemplateRepositories(): string {
  return `query { 
    templateRepositories {
      success
      errors
      repositories {
        uuid
        url
        name
        description
        createdTimestamp
        updatedTimestamp
        createdBy {
          uuid
          username
        }
        dataOrigin {
          uuid
          name
        }
        organisation {
          uuid
          name
        }
        templates {
          uuid
          component {
            name
            description
          }
          type {
            name
            description
          }
          category {
            name
            description
          }
          name
          path
          documentation
          template
        }
      }
    }
  } `;
}

export function getAuthorisedTargetsQuery(): string { return 'query { authorisedTargets { success errors organisations { dataOrigin { uuid name } name } users { username dataOrigin { uuid name } } } }'; }

export function findUsersQuery(searchString:string): string { return `query { findUsers(searchString: "${searchString}") { success errors users { uuid email username }}}`; }

export function getUserOrganisationQuery(): string { return 'query { organisation { success errors organisation { uuid name members { user { username email uuid name } role { name } } }}}'; }

export function getTeamsQuery(): string { return 'query { teams { success errors teams { uuid name description groups { group { uuid name } role { name } } }}}'; }

export function findTeamsQuery(orgUuid: string, searchString: string): string { return `query { findTeams(organisationUuid: "${orgUuid}", searchString: "${searchString}") { success errors teams { uuid name description groups { group { uuid name } role { name }} }}}`; }

export function getTeamQuery(uuid: string): string { return `query { team(uuid: "${uuid}") { success errors team { uuid name description members { user { username email uuid name } role { name } } groups { group { uuid name } role { name }} }}}`; }

export function getUserQuery(uuid: string): string { return `query { getUser(uuid: "${uuid}") { success errors user { uuid username email name teams { team { uuid name description} role { name }} }}}`; }

export function getRepositoryByUuidQuery(uuid: string): string { return `query { repository (repositoryUuid: "${uuid}") { repository { uuid url name description attributes { displayText value name } } } }`; }

export function getAuthenticatedUserQuery(): string { return 'query { getUserInfo { success errors user { uuid username email name role { name } } }}'; }

export function adminGetOrganisationsQuery(): string { return 'query { organisations { success errors organisations { uuid name members { user { username } } }}}'; }

export function adminGetOrganisationByUuidQuery(uuid: string): string { return `query { organisation (uuid: "${uuid}") { success errors organisation { uuid name members { user { username email uuid name } role { name } } }} }`; }

export function adminGetOrganisationTeamsByOrgUuidQuery(orgUuid: string): string { return `query { getTeams(organisationUuid: "${orgUuid}") { success errors teams { uuid name description groups { group { uuid name }} }}}`; }

export function getStoredValuesQuery(orgUuid: string, projectUuid = ''): string { return `query { storedValues(organisationUuid: "${orgUuid}"${projectUuid ? `, projectUuid: "${projectUuid}"` : ''}) { success errors storedValues { uuid name description jsonValue valueType organisation {uuid} project {uuid}}}}`; }

export function getDataOrgins(): string { return 'query { dataOrigins { success errors dataOrigins { uuid name url type apiUrl } }}'; }

export function getPublicDataOrigins(): string { return 'query { publicDataOrigins { success errors dataOrigins { uuid name url apiUrl type } }}'; }
