import { User } from 'graphql/types';
import React, { ReactElement } from 'react';
import Button from 'stories/atoms/Button/Button';
import Icon from 'stories/atoms/Icon/Icon';
import Popup from 'stories/atoms/Popup/Popup';
import NavItem from '../NavItem/NavItem';
import { NavItemProps } from '../NavItem/types';
import styles from './SideNav.module.css';

interface SideNavProps {
  onLogout: () => void;
  topNavItems: NavItemProps[];
  bottomNavItems: NavItemProps[];
  user: User,
}
export default function SideNav({
  onLogout, topNavItems, bottomNavItems, user,
}: SideNavProps):ReactElement {
  return (
    <nav id="side-nav" className={styles.nav}>
      <div className={styles.logo}><img height="40px" width="40px" src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" /></div>
      <div className={styles.links}>
        <div className={styles.top}>
          {topNavItems.map((navItem) => (
            <NavItem text={navItem.text} key={navItem.to} icon={navItem.icon} to={navItem.to} />
          ))}
        </div>

        <div className={styles.bottom}>
          {bottomNavItems.map((navItem) => (
            <NavItem text={navItem.text} key={navItem.to} icon={navItem.icon} to={navItem.to} />
          ))}
          <div className={styles.user}>

            <Popup on="click" position="top" xOffset={-12} trigger={<Icon width={20} height={20} actionable color="white" name="user" />}>
              <div className={styles['user-popup']}>
                <div className={styles['user-title']}>Username: <span className={styles['user-value']}>{user.username}</span></div>
                <div className={styles['user-title']}>Email: <span className={styles['user-value']}>{user.email}</span></div>
                <Button className={styles.signout} id="signout" variant="secondary" icon="logout" iconPlace="right" onClick={onLogout}>Sign out</Button>
              </div>

            </Popup>
          </div>
        </div>

      </div>
    </nav>
  );
}
