import React, { ReactElement, useEffect, useState } from 'react';
import { AuthorizeUser } from 'store/actions/userActions';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useQuery } from 'utils';
import Spinner from 'stories/atoms/Spinner/Spinner';
import PageContent from 'stories/composite/PageContent/PageContent';

export default function Install(): ReactElement {
  const queryParams = useQuery();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const nav = useNavigate();
  useEffect(() => {
    const state = queryParams.get('state');
    const code = queryParams.get('code');
    const dataOriginUuid = window.localStorage.getItem('authorizeDataOrigin');
    if (!user.user || !user.token) {
      nav('/login');
    } else if (!dataOriginUuid) {
      nav('/settings');
    } else if (state && code) {
      dispatch(AuthorizeUser(state, code, dataOriginUuid))
        .finally(() => {
          setLoading(false);
          window.localStorage.removeItem('authorizeDataOrigin');
        });
    } else {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    loading
      ? <PageContent><Spinner /></PageContent>
      : <Navigate to="/settings" />
  );
}
