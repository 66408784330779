import React, { ReactElement, useState } from 'react';
import Tabs from 'stories/composite/Tabs/Tabs';
import { IPanel } from 'stories/composite/Tabs/types';
import { Organisation as OrganisationInterface, Team } from 'graphql/types';
import './Organisation.css';
import OrganisationTeams from './OrganisationTeams/OrganisationTeams';
import OrganisationMembers from './OrganisationMembers/OrganisationMembers';
import OrganisationTemplateRepositories from './OrganisationTemplateRepositories/OrganisationTemplateRepositories';
import OrganisationStoredValues from './OrganisationStoredValues/OrganisationStoredValues';
import OrganisationAdmin from './OrganisationAdmin/OrganisationAdmin';

interface OrganisationProps {
  organisation: OrganisationInterface;
  teams: Team[];
}

export default function Organisation({ organisation, teams }: OrganisationProps): ReactElement {
  const [orgTabs] = useState<IPanel[]>(organisation.amIAdmin
    ? [
      {
        title: 'Members',
        id: 'org-members-tab',
        content: <OrganisationMembers organisation={organisation} />,
      },
      {
        title: 'Teams',
        id: 'org-teams-tab',
        content: <OrganisationTeams organisation={organisation} teams={teams} />,
      },
      {
        title: 'Template repositories',
        id: 'org-template-repositories-tab',
        content: <OrganisationTemplateRepositories organisation={organisation} />,
      },
      {
        title: 'Stored values',
        id: 'org-stored-values-tab',
        content: <OrganisationStoredValues organisation={organisation} />,
      },
      {
        title: 'Admin',
        id: 'org-admin-tab',
        content: <OrganisationAdmin organisation={organisation} />,
      },
    ]
    : [
      {
        title: 'Members',
        id: 'org-members-tab',
        content: <OrganisationMembers organisation={organisation} />,
      },
      {
        title: 'Teams',
        id: 'org-teams-tab',
        content: <OrganisationTeams organisation={organisation} teams={teams} />,
      },
      {
        title: 'Template repositories',
        id: 'org-template-repositories-tab',
        content: <OrganisationTemplateRepositories organisation={organisation} />,
      },
      {
        title: 'Stored values',
        id: 'org-stored-values-tab',
        content: <OrganisationStoredValues organisation={organisation} />,
      },
    ],
  );

  return (
    <Tabs panels={orgTabs} />
  );
}
