import React, {
  ChangeEvent,
  ReactElement, useCallback, useEffect, useRef, useState,
} from 'react';
import _ from 'lodash';
import Input from '../Input/Input';
import SearchResults from './SearchResults/SearchResults';
import styles from './Search.module.css';
import Icon from '../Icon/Icon';
import { SearchProps, SearchResult } from './types';

export default function Search({
  debounceTimer, onChange, defaultText, onResultSelect, persistValueOnClick, id, placeholder, fluid, results, minInputLength = 1,
  noResultsText = 'No results found.', presetValue, presetNode, disabledKeys = [],
}: SearchProps): ReactElement {
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [typing, setTyping] = useState(false);
  const debouncedOnChange = useRef(_.debounce((event) => { onChange(event); setTyping(false); }, debounceTimer || 0)).current;
  useEffect(() => {
    if (defaultText) {
      setInputValue(defaultText);
    }
  }, [defaultText]);

  const onChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      setSelected(false);
      setPristine(false);
      if (debounceTimer) {
        event.persist();
        setTyping(true);
        debouncedOnChange(event);
      } else {
        onChange(event);
      }
    }, [debounceTimer, debouncedOnChange, onChange],
  );
  const resetComponent = useCallback(
    () => {
      setInputValue('');
      setSelected(false);
    },
    [],
  );

  const onResultSelectHandler = useCallback(
    (result: SearchResult) => {
      onResultSelect(result);
      if (persistValueOnClick) {
        setInputValue(result.text);
        setSelected(true);
      } else {
        resetComponent();
      }
    }, [onResultSelect, persistValueOnClick, resetComponent]);

  return (
    <div className={`${styles['search-wrapper']}${fluid ? ` ${styles.fluid}` : ''}`}>
      <Input
        type="text"
        fluid={fluid}
        id={id}
        icon="search"
        value={inputValue}
        placeholder={placeholder}
        onChange={onChangeHandler}
        autoComplete="off"
      />
      {inputValue.length
        ? <Icon onClick={resetComponent} name="cancel" size="tiny" className={styles['cancel-icon']} />
        : null}
      {presetValue && presetNode
        ? (
          <div
            onClick={() => { onResultSelectHandler(presetValue); }}
            className={styles.preset}
          >
            {presetNode}
          </div>
        )
        : null}
      {inputValue.length >= minInputLength && !selected && !pristine && !typing
        ? (
          <SearchResults
            results={results}
            onResultSelect={onResultSelectHandler}
            noResultsText={noResultsText}
            disabledKeys={disabledKeys}
          />
        )
        : null}
    </div>
  );
}
