import React, {
  ReactElement, useEffect, useState,
} from 'react';
import { useAppSelector, useAppDispatch } from 'utils';

import Card from 'stories/composite/Card/Card';
import * as projectActions from 'store/actions/projectActions';

import './Projects.css';
import Spinner from 'stories/atoms/Spinner/Spinner';
import Link from 'stories/atoms/Link/Link';

export default function Projects(): ReactElement {
  const projects = useAppSelector((state) => state.project.projects);
  const loading = useAppSelector((state) => state.project.loading);
  const dispatch = useAppDispatch();
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(projectActions.GetProjects()).catch(() => {
      setError(true);
    });
  }, [dispatch]);

  function projectsList():ReactElement {
    if (loading) {
      return (<Spinner id="projects-spinner" />);
    } else if (error || !projects) {
      return (
        <div className="projects-none">
          Could not load projects.
        </div>
      );
    } else if (projects.length === 0) {
      return (
        <div className="projects-none">No projects</div>
      );
    }
    return (
      <>
        {projects.map((project): ReactElement => (
          <Card
            key={project.uuid}
            id={`${project.name}-card`}
            title={project.name}
            subtitle={project.description || ''}
            link="View project"
            linkHref={`/projects/${project.uuid}`}
            imageHash={project.uuid}
          />
        ))}
      </>
    );
  }
  return (
    <div className="projects-container">
      <Link id="new-project" type="button-primary" href="/projects/create">Create new project</Link>
      <div className="projects">
        {projectsList()}
      </div>
    </div>
  );
}
