import { Dispatch } from 'redux';
import {
  baseUrl, path, headers, checkErrors, getErrorString,
} from 'utils';
import Axios from 'axios';
import { toast } from 'react-toastify';
import {
  findTeamsQuery, getStoredValuesQuery, getTeamQuery, getTeamsQuery, getUserOrganisationQuery,
} from 'graphql/queries';
import store from 'store/configureStore';
import { Organisation, StoredValue, Team } from 'graphql/types';
import {
  updateOrgMemberMutation, addTeamMemberMutation, createTeamMutation, deleteTeamMemberMutation, deleteTeamMutation, updateTeamMemberMutation, createStoredValueMutation, deleteStoredValueMutation, updateStoredValueMutation, deleteTeamGroupMutation, addTeamGroupMutation, createDataOriginMutation, addDataOriginMutation, editDataOriginMutation,
} from 'graphql/mutations';
import { SupportedVCS } from 'types/types';
import { ActionType, Action } from '../actionTypes';

const toastUpdateDefaults = {
  isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true,
};

async function _getOrg(): Promise<Organisation | null> {
  const { user, token } = store.getState().user;
  const response = await Axios({
    method: 'POST',
    url: `${baseUrl}${path}`,
    headers: { ...headers, Authorization: `Bearer ${token}` },
    data: { query: getUserOrganisationQuery() },
  });
  checkErrors(response);
  const { organisation }: { organisation: Organisation | null } = response.data.data.organisation;
  if (organisation) {
    const orgMember = organisation.members?.find((member) => member.user.uuid === user?.uuid);
    organisation.amIAdmin = orgMember?.role.name === 'Admin';
    localStorage.setItem('organisation', JSON.stringify(organisation));
    return organisation;
  }
  return null;
}

export const GetOrganisation = () => async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    const organisation = await _getOrg();
    if (!organisation) {
      dispatch({
        type: ActionType.GET_ORGANISATION_SUCCESS,
        payload: null,
      });
      return Promise.resolve();
    }
    dispatch({
      type: ActionType.GET_ORGANISATION_SUCCESS,
      payload: organisation,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    dispatch({
      type: ActionType.GET_ORGANISATION_FAILED,
    });
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const GetOrganisationTeams = () => async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: getTeamsQuery() },
    });
    checkErrors(response);
    const { teams }: { teams: Team[] } = response.data.data.teams;
    teams.forEach((team) => {
      // eslint-disable-next-line no-param-reassign
      team.isExternal = team.groups.length > 0;
    });
    dispatch({
      type: ActionType.GET_TEAMS_SUCCESS,
      payload: teams,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const FindOrganisationTeams = (organisationUuid: string, searchString: string) => async (): Promise<Team[] | void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: findTeamsQuery(organisationUuid, searchString) },
    });
    checkErrors(response);
    const { teams }: { teams: Team[] } = response.data.data.findTeams;
    return Promise.resolve(teams);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
};

export const ClearCurrentTeam = () => async (dispatch: Dispatch<Action>): Promise<void> => {
  dispatch({ type: ActionType.CLEAR_CURRENT_TEAM });
};

export const GetTeamByUUID = (uuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: getTeamQuery(uuid) },
    });
    checkErrors(response);
    const { team }: { team: Team } = response.data.data.team;
    const groupMember = team.members?.find((member) => member.user.uuid === store.getState().user.user?.uuid);
    team.amIAdmin = groupMember?.role.name === 'Admin';
    team.isExternal = team.groups.length > 0;
    dispatch({
      type: ActionType.GET_TEAM_SUCCESS,
      payload: team,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const CreateTeam = (orgUuid: string, name: string, description: string, groups?: string[]) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Creating team');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: createTeamMutation(orgUuid, name, description, groups) },
    });
    checkErrors(response);
    GetOrganisationTeams()(dispatch);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Team created', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const DeleteTeam = (uuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Deleting team');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: deleteTeamMutation(uuid) },
    });
    checkErrors(response);
    GetOrganisationTeams()(dispatch);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Team deleted', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const AddTeamMember = (teamUuid: string, memberUuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Adding member to team');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: addTeamMemberMutation(teamUuid, memberUuid) },
    });
    checkErrors(response);
    GetTeamByUUID(teamUuid)(dispatch);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Member added to team', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const UpdateTeamMember = (teamUuid: string, memberUuid: string, role: 'User' | 'Admin') => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Updating member');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: updateTeamMemberMutation(teamUuid, memberUuid, role) },
    });
    checkErrors(response);
    GetTeamByUUID(teamUuid)(dispatch);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Member updated', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const DeleteTeamMember = (teamUuid: string, memberUuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Removing member from team');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: deleteTeamMemberMutation(teamUuid, memberUuid) },
    });
    checkErrors(response);
    GetTeamByUUID(teamUuid)(dispatch);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Member removed from team', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const UpdateOrgMember = (orgUuid: string, memberUuid: string, role: 'User' | 'Admin') => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Updating organisation member');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: updateOrgMemberMutation(orgUuid, memberUuid, role) },
    });
    checkErrors(response);
    const org = await _getOrg();
    dispatch({ type: ActionType.GET_ORGANISATION_SUCCESS, payload: org });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Organisation member updated', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const CreateStoredValueAction = (organisationUuid: string, name: string, description: string, valueType: string, value: string, projectUuid?: string) => async (): Promise<void> => {
  const id = toast.loading('Creating stored value');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: createStoredValueMutation(name, description, valueType, value, organisationUuid, projectUuid) },
    });
    checkErrors(response);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Stored value created', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const UpdateStoredValueAction = (storedValueUuid: string, name: string, description: string, valueType: string, value?: string) => async (): Promise<void> => {
  const id = toast.loading('Updating stored value');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: updateStoredValueMutation(storedValueUuid, name, description, valueType, value) },
    });
    checkErrors(response);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Stored value updated', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const GetStoredValuesAction = (organisationUuid: string, projectUuid = '') => async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: getStoredValuesQuery(organisationUuid, projectUuid) },
    });
    checkErrors(response);
    const { storedValues }: { storedValues: StoredValue[] } = response.data.data.storedValues;
    storedValues.forEach((storedValue) => {
      if (storedValue.valueType === 'json') {
        // eslint-disable-next-line no-param-reassign
        storedValue.jsonValue.value = JSON.stringify(storedValue.jsonValue.value);
      }
    });
    dispatch({
      type: ActionType.GET_STORED_VALUES_SUCCESS,
      payload: storedValues,
    });
    return Promise.resolve();
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
};

export const DeleteStoredValueAction = (storedValueUuid: string) => async (): Promise<void> => {
  const id = toast.loading('Deleting stored value');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: deleteStoredValueMutation(storedValueUuid) },
    });
    checkErrors(response);
    toast.update(id, {
      render: 'Stored value deleted', type: 'success', ...toastUpdateDefaults,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  return Promise.resolve();
};

export const DeleteTeamGroup = (teamUuid: string, groupUuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Removing group from team');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: deleteTeamGroupMutation(teamUuid, groupUuid) },
    });
    checkErrors(response);
    GetTeamByUUID(teamUuid)(dispatch);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Group removed from team', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const AddTeamGroup = (teamUuid: string, groupName: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Adding group to team');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: addTeamGroupMutation(teamUuid, groupName) },
    });
    checkErrors(response);
    GetTeamByUUID(teamUuid)(dispatch);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Group added to team', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const CreateDataOriginAction = (organisationUuid: string, name: string, vcsType: string, url: string, apiUrl: string, clientId: string, clientSecret: string) => async (): Promise<void> => {
  const id = toast.loading('Creating integration');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: createDataOriginMutation(organisationUuid, name, vcsType, url, apiUrl, clientId, clientSecret) },
    });
    checkErrors(response);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Integration created', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const AddPublicDataOriginAction = (organisationUuid: string, dataOriginUuid: string) => async (): Promise<void> => {
  const id = toast.loading('Adding integration');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: addDataOriginMutation(organisationUuid, dataOriginUuid) },
    });
    checkErrors(response);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Integration added', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const EditDataOriginAction = (dataOriginUuid: string, name: string, vcsType: SupportedVCS, url: string, apiUrl: string, clientId?: string, clientSecret?: string) => async (): Promise<void> => {
  const id = toast.loading('Updating integration');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: editDataOriginMutation(dataOriginUuid, name, vcsType, url, apiUrl, clientId, clientSecret) },
    });
    checkErrors(response);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Updating created', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};
