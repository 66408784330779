import { Organisation } from 'graphql/types';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateOrganisation } from 'store/actions/adminActions';
import Button from 'stories/atoms/Button/Button';
import Input from 'stories/atoms/Input/Input';
import Spinner from 'stories/atoms/Spinner/Spinner';
import Table from 'stories/atoms/Table/Table';
import Modal from 'stories/composite/Modal/Modal';
import { useAppDispatch, useAppSelector, useFormFields } from 'utils';

export default function Organisations(): ReactElement {
  const [createOrgOpen, setCreateOrgOpen] = useState(false);
  const [filteredOrgs, setFilteredOrgs] = useState<Organisation[]>([]);
  const [rows, setRows] = useState<Array<Array<string | number>>>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const organisations = useAppSelector((state) => state.admin.organisations);
  const { formFields, createChangeHandler, setFormFields } = useFormFields({
    filter: '',
    newOrgName: '',
    newOrgGroupMap: '',
  });
  const onRowClick = useCallback(
    (index: number) => {
      navigate(`/admin/organisation/${filteredOrgs[index].uuid}`);
    },
    [filteredOrgs, navigate],
  );

  const createOrganisation = useCallback(
    () => {
      dispatch(CreateOrganisation(formFields.newOrgName, formFields.newOrgGroupMap)).finally(() => {
        setCreateOrgOpen(false);
        setFormFields({
          ...formFields,
          newOrgName: '',
          newOrgGroupMap: '',
        });
      });
    },
    [dispatch, formFields, setFormFields],
  );
  const cancelCreate = useCallback(
    () => {
      setCreateOrgOpen(false);
      setFormFields({
        ...formFields,
        newOrgName: '',
        newOrgGroupMap: '',
      });
    },
    [formFields, setFormFields],
  );

  useEffect(() => {
    const _filteredOrgs = organisations.filter((org) => org.name.toLowerCase().includes(formFields.filter.toLowerCase()));
    setFilteredOrgs(_filteredOrgs);
  }, [formFields.filter, organisations]);

  useEffect(() => {
    const _rows: Array<Array<string | number>> = [];
    filteredOrgs.forEach((org) => {
      _rows.push([org.name, org.uuid, org.members ? org.members.length : 0]);
    });
    setRows(_rows);
  }, [filteredOrgs, organisations]);

  return (
    organisations
      ? (
        <>
          <div className="admin-org-filter-row">
            <Input placeholder="Filter" icon="search" onChange={createChangeHandler('filter')} />
            <Button onClick={() => setCreateOrgOpen(true)}>Create</Button>
          </div>
          <Table
            headers={['Name', 'UUID', 'Member count', '']}
            rows={rows}
            actionableRows
            colSizes={['minmax(200px, 1fr)', 'minmax(350px, 1fr)', 'minmax(150px, 1fr)', 'minmax(0px, 200px)']}
            onRowClick={onRowClick}
          />
          <Modal open={createOrgOpen} onClose={cancelCreate}>
            <div className="admin-org-modal">
              <h3>Create a new organisation</h3>
              <Input required onChange={createChangeHandler('newOrgName')} placeholder="Name" />
              <Input onChange={createChangeHandler('newOrgGroupMap')} placeholder="Group name mapped to this organisation" />
              <div className="admin-org-modal-buttons">
                <Button onClick={cancelCreate} variant="secondary">Cancel</Button>
                <Button disabled={formFields.newOrgName.length < 1} onClick={createOrganisation}>Create</Button>
              </div>
            </div>
          </Modal>
        </>
      )
      : <Spinner />
  );
}
