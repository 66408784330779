import { Auth } from 'aws-amplify';
import React, {
  ChangeEvent, FormEvent, ReactElement, useCallback, useState,
} from 'react';
import { toast } from 'react-toastify';
import Button from 'stories/atoms/Button/Button';
import Input from 'stories/atoms/Input/Input';
import { AuthPages } from '../types';

interface ConfirmEmailProps {
  username: string;
  confirm: (username: string, code: string) => Promise<void>;
  changePage: (page: AuthPages) => void;
}
export function ConfirmEmail({ username, confirm, changePage }: ConfirmEmailProps): ReactElement {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const onChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'code':
        setCode(event.target.value);
        break;
      default:
        break;
    }
  }, []);

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      setLoading(true);
      e.preventDefault();
      confirm(username, code).finally(() => setLoading(false));
    },
    [username, code, confirm],
  );

  const resendCode = useCallback(
    async () => {
      try {
        await Auth.resendSignUp(username);
        toast.success('Code sent to your email again');
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        }
      }
    },
    [username],
  );

  return (
    <form className="auth-form" onSubmit={onSubmit}>
      <h3 className="auth-title">Confirm Sign up</h3>
      <div className="auth-form-inputs">
        <Input wrapperClassName="auth-input" disabled defaultValue={username} id="username" name="username" onChange={onChangeHandler} fluid required label="Username" />
        <Input wrapperClassName="auth-input" id="code" name="code" onChange={onChangeHandler} fluid required label="Code" />
        <div className="auth-forgot-password">Lost your code? <span className="change-page" onClick={resendCode}>Resend code</span></div>
      </div>
      <div className="auth-footer">
        <div><span className="change-page" onClick={() => changePage('signin')}>Back to Sign In</span></div>
        <Button id="confirm" loading={loading} type="submit">Confirm</Button>
      </div>
    </form>
  );
}
