import React, {
  JSXElementConstructor, ReactElement, useEffect, useState,
} from 'react';
import {
  Navigate, Route, Routes,
} from 'react-router-dom';
import useBreadcrumbs, { createRoutesFromChildren } from 'use-react-router-breadcrumbs';
import { NavItemProps } from 'stories/composite/NavItem/types';
import Install from 'views/Install/Install';
import Authentication from 'views/Authentication/Authentication';
import { useAppSelector } from 'utils';
import Spinner from 'stories/atoms/Spinner/Spinner';
import PageContent from 'stories/composite/PageContent/PageContent';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import RequiresAuth from './RequiresAuth/RequiresAuth';
import AppRoutes from './AppRoutes';
import CognitoAuth from './CognitoAuth/CognitoAuth';
import Logout from './Logout/Logout';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function RootRoutes(): ReactElement<any, string | JSXElementConstructor<any>> | null {
  const organisation = useAppSelector((state) => state.organisation.organisation);
  const user = useAppSelector((state) => state.user.user);
  const appRoutes = AppRoutes();
  const appRoutesObject = createRoutesFromChildren(appRoutes);
  const breadcrumbs = useBreadcrumbs(appRoutesObject, { excludePaths: ['/', '/projects/:projectUuid/repository', '/admin/organisation'] });

  const [topNavItems, setTopNavItems] = useState<NavItemProps[]>([]);
  const [bottomNavItems] = useState<NavItemProps[]>([{
    to: '/settings',
    icon: 'gear',
    text: 'Settings',
  }]);

  useEffect(() => {
    const _topNavItems: NavItemProps[] = [
      {
        to: '/projects',
        icon: 'projects',
        text: 'Projects',
      },
    ];
    if (organisation) {
      _topNavItems.push({
        to: '/organisation',
        icon: 'organisation',
        text: 'Organisation',
      });
    }
    if (user && user.role && user?.role.name === 'SysAdmin') {
      _topNavItems.push({
        to: '/admin',
        icon: 'admin',
        text: 'Admin',
      });
    }
    setTopNavItems(_topNavItems);
  }, [organisation, user]);

  return (
    <Routes>
      <Route
        path="/install"
      >
        <Route index element={<Install />} />
      </Route>
      <Route element={<CognitoAuth />}>
        <Route element={<RequiresAuth topNavItems={topNavItems} bottomNavItems={bottomNavItems} />}>
          <Route element={<Breadcrumbs breadcrumbs={breadcrumbs} />}>
            {appRoutes}
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/projects" />} />
        <Route path="/login" element={<Authentication />} />
        <Route
          path="/authorize" element={(
            <PageContent>
              <div style={{
                display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center',
              }}
              ><Spinner />
              </div>
            </PageContent>
          )}
        />
        <Route
          path="/logout" element={(<Logout />)}
        />
      </Route>
    </Routes>
  );
}
