import React, { ReactElement, useCallback } from 'react';
import Button from 'stories/atoms/Button/Button';
import Checkbox from 'stories/atoms/Checkbox/Checkbox';
import Input from 'stories/atoms/Input/Input';
import Label from 'stories/atoms/Label/Label';
import Dropdown from 'stories/composite/Dropdown/Dropdown';
import { DropdownOption } from 'stories/composite/Dropdown/types';
import { IAuthorisedTargets } from 'types/types';

interface RepositoryDetailsProps {
  name: string;
  onChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onInstallationChange: (item: DropdownOption) => void;
  selectedInstallation: DropdownOption;
  authorisationTargets: IAuthorisedTargets | null;
  onTabChange: (next?: boolean) => void;
  privateRepo: boolean;
}

export function RepositoryDetails({
  name, selectedInstallation, authorisationTargets, onChangeHandler, onInstallationChange, onTabChange, privateRepo,
}: RepositoryDetailsProps): ReactElement {
  const options: DropdownOption[] = [];
  if (authorisationTargets) {
    authorisationTargets.users.forEach((user) => {
      options.push({
        key: `${user.dataOrigin.name}-${user.username}`,
        text: `${user.dataOrigin.name} - ${user.username}`,
        id: `${user.dataOrigin.name}-${user.username}-option`,
        value: '',
        dataOrigin: user.dataOrigin,
      });
    });
    authorisationTargets.organisations.forEach((organisation) => {
      options.push({
        key: `${organisation.dataOrigin.name}-${organisation.name}`,
        text: `${organisation.dataOrigin.name} - ${organisation.name}`,
        id: `${organisation.dataOrigin.name}-${organisation.name}-option`,
        value: organisation.name,
        dataOrigin: organisation.dataOrigin,
      });
    });
  }

  const checkName = useCallback(
    () => {
      if (name === '') return '';
      if (!/^[A-Za-z0-9_.-]*$/.test(name)) {
        return 'Can only contain letters, numbers, -, _, and .';
      }
      return '';
    },
    [name],
  );

  function isDisabled():boolean {
    return !name || !!checkName() || selectedInstallation.key === '';
  }

  return (
    <div className="create-repository-details">
      <div className="create-repository-field">
        <Input
          required error={checkName()}
          label="Repository Name"
          id="name-input"
          name="name"
          defaultValue={name}
          onChange={onChangeHandler}
          info="Then name can only contain letters, numbers, -, _, and ."
        />
      </div>
      <div className="create-repository-field">
        <Label text="Repository target *" info="Select where the repository is created." />
        <Dropdown fluid id="installation-dropdown" defaultValue={selectedInstallation} options={options} onChange={onInstallationChange} />
      </div>
      <div className="create-repository-field">
        <Checkbox label="Private repository" name="private" onChange={onChangeHandler} defaultChecked={privateRepo} />
      </div>
      <div className="create-repository-buttons">
        <Button disabled={isDisabled()} id="repository-details-next-button" onClick={() => onTabChange()}>Next</Button>
      </div>
    </div>
  );
}
