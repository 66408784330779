import { toSvg } from 'jdenticon';
import React, { ReactElement } from 'react';

interface IdenticonProps {
  className?: string,
  imageHash: string,
  size?: number,
  backgroundColor?: string,
}

export default function Identicon({
  className, imageHash, size = 56, backgroundColor = '#2E3643',
}: IdenticonProps): ReactElement {
  const identiconOptions = {
    lightness: {
      color: [0.41, 0.64],
    // grayscale: [0.40, 0.55]
    },
    saturation: {
      color: 0.70,
      grayscale: 0.69,
    },
    backColor: backgroundColor,
    // every 4 colors are new color
    // yellows, grey, orange, blue, green, indigo, purple (not all included )
    // https://htmlcolors.com/hex-to-hsl
    hues: [43, 49, 55, 51, 27, 35, 60, 25, 29, 201, 174, 299],
  };
  return (
    // eslint-disable-next-line react/no-danger
    <div className={className} dangerouslySetInnerHTML={{ __html: toSvg(imageHash, size, identiconOptions) }}></div>
  );
}
