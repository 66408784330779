import React, { ReactElement } from 'react';
import { useAppSelector } from 'utils';

export default function OrganisationNameBreadcrumb(): ReactElement {
  const name = useAppSelector((state) => state.organisation.organisation?.name);
  const adminName = useAppSelector((state) => state.admin.currentOrganisation?.name);
  return (
    <span>{name || adminName || 'Loading...'}</span>
  );
}
