import React, { ReactElement, useCallback } from 'react';
import Button from 'stories/atoms/Button/Button';
import Checkbox from 'stories/atoms/Checkbox/Checkbox';
import Input from 'stories/atoms/Input/Input';
import TextArea from 'stories/atoms/TextArea/TextArea';
import { isUrl, useAppDispatch, useFormFields } from 'utils';
import * as projectActions from 'store/actions/projectActions';
import * as adminActions from 'store/actions/adminActions';

import './TemplateImport.css';
import { useNavigate } from 'react-router-dom';
import IsAllowed from 'stories/misc/IsAllowed/IsAllowed';

interface TemplateImportProps {
  orgId?: string
  close?: () => void;
  isSysadmin?: boolean;
}

export default function TemplateImport({ orgId, close, isSysadmin = false }: TemplateImportProps): ReactElement {
  const { formFields, createChangeHandler } = useFormFields({
    url: '',
    name: '',
    description: '',
    authRequired: false,
    username: '',
    token: '',
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      dispatch(
        isSysadmin
          ? adminActions.importPublicTemplateRepository(formFields.url, formFields.name, formFields.description, formFields.token)
          : projectActions.importTemplateRepository(formFields.url, formFields.name, formFields.description, formFields.username, formFields.token, orgId))
        .then(
          () => {
            if (!orgId && !isSysadmin) {
              navigate('/settings');
            }
            if (close) {
              dispatch(projectActions.GetRepositoryTemplates());
              close();
            }
          });
    },
    [dispatch, formFields.url, formFields.name, formFields.description, formFields.username, formFields.token, orgId, isSysadmin, close, navigate],
  );

  const changeAuthRequired = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (formFields.authRequired) {
        formFields.username = '';
        formFields.token = '';
      }
      createChangeHandler('authRequired')(event);
    },
    [formFields, createChangeHandler],
  );

  const isDisabled = useCallback(
    () => {
      if (!formFields.url || !formFields.name) {
        return true;
      }
      if (formFields.authRequired && (!formFields.username || !formFields.token)) {
        return true;
      }
      return false;
    },
    [formFields],
  );

  const isValidGitUrl = useCallback(
    () => {
      if (!formFields.url) return false;
      if (!isUrl(formFields.url) || !formFields.url.endsWith('.git')) {
        return 'Provide a valid git url';
      }
      return false;
    },
    [formFields],
  );

  return (
    <div className="template-import-container">
      <form onSubmit={onSubmit} className="template-import-form">
        <h3>Import template repository</h3>
        <Input error={isValidGitUrl()} wrapperClassName="template-import-input" required onChange={createChangeHandler('url')} name="url" label="Repository url" />
        <Input wrapperClassName="template-import-input" required onChange={createChangeHandler('name')} name="name" label="Name" />
        <TextArea className="template-import-input" onChange={createChangeHandler('description')} name="description" label="Description" />
        <IsAllowed requiredPrivilege="isSysadmin">
          <Input required wrapperClassName="template-import-input" onChange={createChangeHandler('token')} type="password" name="token" label="Token" />
        </IsAllowed>
        <IsAllowed requiredPrivilege="isNotSysadmin">
          <>
            <Checkbox
              className="template-import-input"
              onChange={changeAuthRequired}
              name="authRequired"
              label="Requires authentication"
              id="requires-auth-checkbox"
              info="Check this if the repository is not a public one"
            />
            {
              formFields.authRequired ? (
                <>
                  <Input required wrapperClassName="template-import-input" onChange={createChangeHandler('username')} name="username" label="Username" />
                  <Input required wrapperClassName="template-import-input" onChange={createChangeHandler('token')} type="password" name="token" label="Token" />
                </>
              ) : null
            }
          </>
        </IsAllowed>

        <Button className="template-import-submit" disabled={isDisabled()} id="submit-template-import" type="submit">Import</Button>
      </form>
    </div>
  );
}
