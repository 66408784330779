import React, { ReactElement } from 'react';
import { Route } from 'react-router-dom';
import CreateProject from 'views/CreateProject/CreateProject';
import CreateRepository from 'views/CreateRepository/CreateRepository';
import Projects from 'views/Projects/Projects';
import Project from 'views/Project/Project';
import Settings from 'views/Settings/Settings';
import TemplateImport from 'views/Common/TemplateImport/TemplateImport';
import OrganisationWrapper from 'views/Organisation/OrganisationWrapper';
import ProjectWrapper from 'views/Project/ProjectWrapper';
import RepositoryDetails from 'views/Project/RepositoryDetails/RepositoryDetails';
import { useAppSelector } from 'utils';
import Admin from 'views/Admin/Admin';
import AdminOrganisationWrapper from 'views/Admin/Organisations/AdminOrganisationWrapper/AdminOrganisationWrapper';
import ProjectNameBreadcrumb from './Breadcrumbs/ProjectNameBreadcrumb/ProjectNameBreadcrumb';
import OrganisationNameBreadcrumb from './Breadcrumbs/OrganisationNameBreadcrumb/OrganisationNameBreadcrumb';
import RepositoryNameBreadcrumb from './Breadcrumbs/RepositoryNameBreadcrumb/RepositoryNameBreadcrumb';
import AllowedRoute from './AllowedRoute/AllowedRoute';

export default function AppRoutes(): ReactElement {
  const user = useAppSelector((state) => state.user.user);
  const organisation = useAppSelector((state) => state.organisation.organisation);
  return (
    <>
      <Route path="/projects">
        <Route element={<Projects />} index />
        <Route path=":projectUuid" element={<ProjectWrapper />}>
          {/* @ts-expect-error breadcrumb */}
          <Route element={<Project />} index breadcrumb={ProjectNameBreadcrumb} />
          {/* @ts-expect-error breadcrumb */}
          <Route path="repository/new" element={<CreateRepository />} breadcrumb="New repository" />
          {/* @ts-expect-error breadcrumb */}
          <Route path="repository/:repositoryUuid" element={<RepositoryDetails />} breadcrumb={RepositoryNameBreadcrumb} />
        </Route>
        {/* @ts-expect-error breadcrumb */}
        <Route path="create" element={<CreateProject />} breadcrumb="Create a new project" />
      </Route>
      <Route path="/settings">
        <Route element={<Settings />} index />
        {/* @ts-expect-error breadcrumb */}
        <Route path="template" element={<TemplateImport />} breadcrumb="Import template repository" />
      </Route>
      <Route element={<AllowedRoute isAllowed={!!organisation} />}>
        <Route path="/organisation">
          {/* @ts-expect-error breadcrumb */}
          <Route element={<OrganisationWrapper />} index breadcrumb={OrganisationNameBreadcrumb} />
        </Route>
      </Route>
      <Route element={<AllowedRoute isAllowed={!!user && !!user.role && user.role.name === 'SysAdmin'} />}>
        <Route path="/admin">
          <Route element={<Admin />} index />
          {/* @ts-expect-error breadcrumb */}
          <Route path="organisation/:orgUuid" element={<AdminOrganisationWrapper />} breadcrumb={OrganisationNameBreadcrumb} />
        </Route>
      </Route>
    </>
  );
}
