import React, {
  FormEvent, ReactElement, useCallback, useEffect, useState,
} from 'react';
import { Organisation, StoredValue } from 'graphql/types';
import Button from 'stories/atoms/Button/Button';
import Input from 'stories/atoms/Input/Input';
import Label from 'stories/atoms/Label/Label';
import Table from 'stories/atoms/Table/Table';
import TextArea from 'stories/atoms/TextArea/TextArea';
import Dropdown from 'stories/composite/Dropdown/Dropdown';
import { DropdownOption } from 'stories/composite/Dropdown/types';
import Modal from 'stories/composite/Modal/Modal';
import IsAllowed from 'stories/misc/IsAllowed/IsAllowed';
import { useAppDispatch, useAppSelector, useFormFields } from 'utils';
import {
  CreateStoredValueAction, DeleteStoredValueAction, GetStoredValuesAction, UpdateStoredValueAction,
} from 'store/actions/organisationActions';
import Popup from 'stories/atoms/Popup/Popup';
import Icon from 'stories/atoms/Icon/Icon';

type StoredValueTypeType = 'text' | 'number' | 'email' | 'secret' | 'json'
interface OrganisationStoredValuesProps {
  organisation: Organisation
}
export default function OrganisationStoredValues({ organisation }: OrganisationStoredValuesProps): ReactElement {
  const typeOptions = [
    {
      text: 'Text', value: 'text', id: 'stored-value-type-text', key: 'stored-value-type-text',
    },
    {
      text: 'Number', value: 'number', id: 'stored-value-type-number', key: 'stored-value-type-number',
    },
    {
      text: 'Email', value: 'email', id: 'stored-value-type-email', key: 'stored-value-type-email',
    },
    {
      text: 'Secret', value: 'secret', id: 'stored-value-type-secret', key: 'stored-value-type-secret',
    },
    {
      text: 'JSON', value: 'json', id: 'stored-value-type-json', key: 'stored-value-type-json',
    },
  ];
  const [open, setOpen] = useState(false);
  const [headers, setHeaders] = useState<string[]>([]);
  const [rows, setRows] = useState<Array<Array<string | number | ReactElement>>>([]);
  const [colWidth, setColWidth] = useState<string[]>([]);
  const [editing, setEditing] = useState<null | string>(null);
  const dispatch = useAppDispatch();
  const storedValues = useAppSelector((state) => state.organisation.storedValues);
  const { formFields, createChangeHandler, setFormFields } = useFormFields<{
    filter: string;
    name: string;
    description: string;
    value: string | number;
    valueType: StoredValueTypeType;
  }>({
    filter: '',
    name: '',
    description: '',
    value: '',
    valueType: 'text',
  });
  const onTypeChange = useCallback(
    (item: DropdownOption) => {
      setFormFields({ ...formFields, valueType: item.value as StoredValueTypeType, value: '' });
    },
    [formFields, setFormFields],
  );

  useEffect(() => {
    dispatch(GetStoredValuesAction(organisation.uuid));
  }, [dispatch, organisation.uuid]);

  useEffect(() => {
    const _headers = ['Name', 'Description', 'Value', 'Type'];
    const _colWidth = ['1fr', '1fr', '1fr', '100px'];
    if (organisation.amIAdmin) {
      _headers.push('Actions');
      _colWidth.push('80px');
    }
    setHeaders(_headers);
    setColWidth(_colWidth);
  }, [organisation.amIAdmin]);

  const edit = useCallback(
    (storedValue: StoredValue) => {
      setEditing(storedValue.uuid);
      setFormFields({
        ...formFields,
        name: storedValue.name,
        description: storedValue.description || '',
        value: storedValue.jsonValue.value || '',
        valueType: storedValue.valueType as StoredValueTypeType,
      });
      setOpen(true);
    },
    [formFields, setFormFields],
  );

  const deleteStoredValue = useCallback(
    (storedValue: StoredValue) => {
      dispatch(DeleteStoredValueAction(storedValue.uuid)).then(() => dispatch(GetStoredValuesAction(organisation.uuid)));
    },
    [dispatch, organisation.uuid],
  );

  useEffect(() => {
    const _rows: Array<Array<string | number | ReactElement>> = storedValues.map((storedValue) => {
      const row: Array<string | number | ReactElement> = [
        storedValue.name, storedValue.description || '',
        storedValue.jsonValue.value || '******',
        storedValue.valueType,
      ];
      if (organisation.amIAdmin) {
        row.push((
          <Popup
            on="click"
            trigger={<Icon actionable className="stored-value-action-trigger" name="more" color="white" />}
          >
            <>
              <div className="stored-value-action" onClick={() => edit(storedValue)}>Edit</div>
              <div className="stored-value-action" onClick={() => deleteStoredValue(storedValue)}>Delete</div>
            </>
          </Popup>
        ));
      }
      return row;
    });
    setRows(_rows);
  }, [deleteStoredValue, edit, organisation.amIAdmin, storedValues]);

  const checkJson = useCallback(
    (): boolean | string => {
      if (formFields.valueType === 'json' && formFields.value) {
        try {
          JSON.parse(formFields.value as string);
          return false;
        } catch (error) {
          return 'Invalid JSON';
        }
      }
      return false;
    },
    [formFields.valueType, formFields.value],
  );

  const closeModal = useCallback(
    () => {
      setOpen(false);
      setFormFields({
        ...formFields,
        name: '',
        description: '',
        value: '',
        valueType: 'text',
      });
      setEditing(null);
    },
    [formFields, setFormFields],
  );

  const createStoredValue = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      await dispatch(CreateStoredValueAction(organisation.uuid, formFields.name, formFields.description, formFields.valueType, formFields.value.toString()));
      closeModal();
      dispatch(GetStoredValuesAction(organisation.uuid));
    },
    [closeModal, dispatch, formFields.description, formFields.name, formFields.value, formFields.valueType, organisation.uuid],
  );

  const editStoredValue = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (editing) {
        await dispatch(UpdateStoredValueAction(editing, formFields.name, formFields.description, formFields.valueType, formFields.value ? formFields.value.toString() : undefined));
      }
      closeModal();
      dispatch(GetStoredValuesAction(organisation.uuid));
    },
    [closeModal, dispatch, editing, formFields.description, formFields.name, formFields.value, formFields.valueType, organisation.uuid],
  );

  return (
    <>
      <div className="org-entities-filter-bar">
        <Input placeholder="Filter stored values" onChange={createChangeHandler('filter')} />
        <IsAllowed requiredPrivilege="isAllowedOrgEdit" organisation={organisation}>
          <Button icon="add" iconColor="black" onClick={() => setOpen(true)}>Add</Button>
        </IsAllowed>
      </div>
      <Table
        headers={headers}
        rows={rows}
        colSizes={colWidth}
      />

      <Modal open={open} onClose={closeModal}>
        <div className="org-create-team-modal">
          <h3>{editing ? 'Edit' : 'Create a'} stored value</h3>
          <form onSubmit={editing ? editStoredValue : createStoredValue}>
            <Input
              wrapperClassName="org-input"
              required
              id="stored-value-name"
              placeholder="Name"
              label="Name"
              onChange={createChangeHandler('name')}
              defaultValue={formFields.name}
            />
            <TextArea
              rows={2}
              className="org-input"
              id="stored-value-description"
              label="Description"
              onChange={createChangeHandler('description')}
              defaultValue={formFields.description}
            />
            <Label className="stored-value-type-label" text="Type *" htmlFor="stored-value-type" />
            <Dropdown
              className="org-input"
              id="stored-value-type" options={typeOptions}
              onChange={onTypeChange}
              defaultValue={typeOptions.find((typeOption) => typeOption.value === formFields.valueType)}
            />
            {formFields.valueType === 'text'
              ? (
                <Input
                  id="stored-value-value"
                  wrapperClassName="org-input"
                  required placeholder="Value"
                  label="Value"
                  onChange={createChangeHandler('value')}
                  defaultValue={formFields.value}
                />
              )
              : null}
            {formFields.valueType === 'email'
              ? (
                <Input
                  id="stored-value-value"
                  wrapperClassName="org-input"
                  required
                  placeholder="example@example.com"
                  label="Value"
                  type="email"
                  onChange={createChangeHandler('value')}
                  defaultValue={formFields.value}
                />
              )
              : null}
            {formFields.valueType === 'number'
              ? (
                <Input
                  id="stored-value-value"
                  wrapperClassName="org-input"
                  required
                  placeholder="123"
                  label="Value"
                  type="number"
                  onChange={createChangeHandler('value')}
                  defaultValue={formFields.value}
                />
              )
              : null}
            {formFields.valueType === 'secret'
              ? (
                <Input
                  id="stored-value-value"
                  wrapperClassName="org-input"
                  required={!editing}
                  placeholder="Secret value"
                  label="Value"
                  info={editing ? 'Leave this empty if you don\'t want to change the value' : undefined}
                  type="password"
                  onChange={createChangeHandler('value')}
                />
              )
              : null}
            {formFields.valueType === 'json'
              ? (
                <TextArea
                  id="stored-value-value"
                  className="org-input"
                  required
                  error={checkJson()}
                  placeholder={'{"key": "value", "array": ["value1", "value2"]}'}
                  label="Value"
                  onChange={createChangeHandler('value')}
                  defaultValue={formFields.value}
                />
              )
              : null}
            <div className="org-stored-value-buttons">
              <Button onClick={closeModal} variant="secondary">Cancel</Button>
              <Button type="submit">{editing ? 'Edit' : 'Create'}</Button>
            </div>

          </form>
        </div>

      </Modal>
    </>

  );
}
