import React, {
  ReactNode, MouseEvent, useCallback, ReactElement, useRef,
} from 'react';
import PropTypes from 'prop-types';
import Portal from 'stories/misc/Portal/Portal';
import Icon from 'stories/atoms/Icon/Icon';
import styles from './Modal.module.css';

export interface ModalProps {
  id?: string,
  children: ReactNode,
  /** Extra CSS classes for the modal */
  className?: string,
  /** Show or hide the modal */
  open: boolean,
  /** Function that gets called when closing the modal */
  onClose: () => void,
  /** Disables closing on dimmer click */
  disableCloseOnDimmerClick?: boolean,
  size?: 'small' | 'big' | 'custom',
  /** Hides the close icon */
  hideClose?: boolean,
}

export default function Modal({
  id, children, className, open, onClose, size = 'custom', hideClose = false, disableCloseOnDimmerClick = false,
}: ModalProps): ReactElement | null {
  const dimmer = useRef(null);
  const onDimmerClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (!disableCloseOnDimmerClick && event.target === dimmer.current) {
        onClose();
      }
    },
    [disableCloseOnDimmerClick, onClose],
  );
  const getClassName = useCallback(
    () => {
      let _className = styles.wrapper;

      if (size !== 'custom') {
        _className += ` ${styles[size]}`;
      }
      if (className) {
        _className += ` ${className}`;
      }
      return _className;
    },
    [className, size],
  );

  if (!open) { return null; }
  return (
    <Portal key={`${id}-key`}>
      <div
        ref={dimmer}
        onClick={onDimmerClick}
        className={styles.dimmer}
      >
        <div
          id={id}
          className={getClassName()}
        >
          <div
            className={size === 'custom' ? styles['custom-size'] : `${styles[size]} ${styles['modal-content']}`}
          >
            {!hideClose ? (
              <Icon
                id="close"
                ariaLabel="close"
                onClick={() => onClose()}
                actionable
                color="white"
                className={styles['close-icon']}
                name="cancel"
                width={12}
                height={12}
              />
            )
              : null}
            { children }
          </div>
        </div>
      </div>
    </Portal>
  );
}

Modal.propTypes = {
  id: PropTypes.string,
  children: PropTypes.element.isRequired,
  /** Extra CSS classes for the modal */
  className: PropTypes.string,
  /** Show or hide the modal */
  open: PropTypes.bool.isRequired,
  /** Function that gets called when closing the modal */
  onClose: PropTypes.func.isRequired,
  /** Disables closing on dimmer click */
  disableCloseOnDimmerClick: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'big', 'custom']),
  /** Hides the close icon */
  hideClose: PropTypes.bool,
};

Modal.defaultProps = {
  id: undefined,
  className: '',
  disableCloseOnDimmerClick: false,
  size: 'custom',
  hideClose: false,
};
