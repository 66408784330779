import React, {
  ChangeEvent, ReactElement, useCallback, useEffect, useState,
} from 'react';
import Button from 'stories/atoms/Button/Button';
import Icon from 'stories/atoms/Icon/Icon';
import Input from 'stories/atoms/Input/Input';
import { InputProps } from 'stories/atoms/Input/types';
import styles from './InputList.module.css';

interface InputListProps {
  defaultList?: string[]
  inputProps: InputProps;
  onChange: (list: string[]) => void;
  id: string;
  allowEmptyList?: boolean;
  allowEmptyValue?: boolean;
}

interface InputList {
  value: string;
  hash: string;
}

function getRandomString(): string {
  return Math.random().toString(36).substring(2, 5);
}

export default function InputList({
  onChange, inputProps, id, defaultList, allowEmptyList, allowEmptyValue,
}: InputListProps): ReactElement {
  const [list, setList] = useState<InputList[]>([{
    value: '',
    hash: getRandomString(),
  }]);

  useEffect(() => {
    if (defaultList && defaultList.length) {
      setList(defaultList.map((value) => ({
        value,
        hash: getRandomString(),
      })));
    } else if (allowEmptyList) {
      setList([]);
    }
  }, [allowEmptyList, defaultList]);

  const removeIndex = useCallback(
    (index: number) => {
      const _list = [...list];
      _list.splice(index, 1);
      setList(_list);
      onChange(_list.map((obj) => obj.value));
    },
    [list, onChange],
  );

  const addIndex = useCallback(
    () => {
      const _list = [...list];
      _list.push({
        value: '',
        hash: getRandomString(),
      });
      setList(_list);
      onChange(_list.map((obj) => obj.value));
    },
    [list, onChange],
  );

  const listChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, index: number) => {
      const _list = [...list];
      _list[index].value = event.target.value;
      setList(_list);
      if (list.length === 1 && list[0].value === '') {
        onChange([]);
      } else {
        onChange(_list.map((obj) => obj.value));
      }
    },
    [list, onChange],
  );

  const isDisabled = useCallback(
    () => {
      if (allowEmptyValue) {
        return false;
      } else {
        return list.at(-1)?.value === '';
      }
    },
    [allowEmptyValue, list],
  );
  return (
    <div>
      {list.map((defaultValue, index) => (
        <div key={defaultValue.hash} className={styles['input-list']}>
          <Input onChange={(event) => listChange(event, index)} id={`${id}-${index + 1}`} {...inputProps} defaultValue={defaultValue.value} />
          {
            list.length > 1 || allowEmptyList ? <Icon className={styles['input-list-icon']} actionable size="small" color="white" name="cancel" onClick={() => removeIndex(index)} /> : null
          }
        </div>
      ))}
      <Button disabled={isDisabled()} onClick={addIndex}>Add</Button>
    </div>
  );
}
