import React, { ReactElement } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';
import styles from './Breadcrumbs.module.css';

export default function Breadcrumbs({ breadcrumbs }: {breadcrumbs: BreadcrumbData<string>[]}): ReactElement {
  return (
    <>
      <div className={styles.breadcrumbs}>
        {breadcrumbs.map(({ breadcrumb, key }, index) => (
          <span key={key}>
            <NavLink className={styles.breadcrumb} to={key}>{breadcrumb}</NavLink>
            {index + 1 === breadcrumbs.length ? null : <span className={styles.separator}>»</span>}
          </span>
        ))}
      </div>
      <Outlet />
    </>
  );
}
