import React, { ReactElement, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'utils';
import Modal from 'stories/composite/Modal/Modal';
import Spinner from 'stories/atoms/Spinner/Spinner';
import './Settings.css';
import Card from 'stories/composite/Card/Card';
import Link from 'stories/atoms/Link/Link';
import { CardTopAction } from 'stories/composite/Card/types';
import { GetRepositoryTemplates, DeleteTemplateRepository } from 'store/actions/projectActions';
import { GetUserAuthorisationTargets, NavigateToVCSAuthorizationUrl, GetDataOrigins } from 'store/actions/userActions';
import { DataOrigin } from 'graphql/types';
import VCSCard from 'stories/composite/VCSCard/VCSCard';

export default function Settings(): ReactElement {
  const stateLoading = useAppSelector((state) => state.user.loading);
  const user = useAppSelector((state) => state.user.user);
  const organisation = useAppSelector((state) => state.organisation.organisation);
  const authorisationTargets = useAppSelector((state) => state.user.authorisationTargets);
  const templateRepositories = useAppSelector((state) => state.project.templateRepositories);
  const dataOrigins = useAppSelector((state) => (state.organisation.organisation ? state.user.dataOrigins : state.user.publicDataOrigins));
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetUserAuthorisationTargets());
    dispatch(GetRepositoryTemplates());
    dispatch(GetDataOrigins(organisation === null));
  }, [dispatch, organisation]);

  const authorize = useCallback(async (dataOrigin: DataOrigin) => {
    await dispatch(NavigateToVCSAuthorizationUrl(dataOrigin));
  }, [dispatch]);

  const deleteTemplateRepository = useCallback(
    async (uuid: string) => {
      await dispatch(DeleteTemplateRepository(uuid));
    },
    [dispatch],
  );

  return (
    <>
      <h3 className="settings-title">Version control integrations</h3>
      <div className="settings-dataorigins">
        {dataOrigins.map((dataOrigin) => {
          let authorized = !!authorisationTargets?.users.find((target) => target.dataOrigin.uuid === dataOrigin.uuid);
          if (!authorized) {
            authorized = !!authorisationTargets?.organisations.find((target) => target.dataOrigin.uuid === dataOrigin.uuid);
          }
          return (
            <VCSCard
              className="settings-dataorigin"
              title={dataOrigin.name}
              onAuthorize={() => authorize(dataOrigin)}
              icon={dataOrigin.type}
              authorized={authorized}
            />
          );
        })}
      </div>

      <h3 className="settings-title">Template repositories</h3>
      <p>You can import a repository into Sprout, which contains your customly created templates. Then these templates will be available for selection when you create a new repository.</p>
      <Link type="button-primary" href="template" id="import-template-repository">Import template repository</Link>
      <div className="settings-template-repositories">
        {templateRepositories && templateRepositories.filter((repo) => repo.organisation === null).map((repo) => {
          const topActions: CardTopAction[] | undefined = repo.createdBy?.username === user?.username ? [
            {
              icon: 'delete',
              color: 'white',
              onClick: () => { deleteTemplateRepository(repo.uuid); },
            },
          ] : undefined;
          return (
            <Card
              key={repo.uuid}
              noTop
              title={repo.name}
              subtitle={repo.description || ''}
              link="View repository"
              linkHref={repo.url}
              topActions={topActions}
            />
          );
        })}
      </div>
      <Modal hideClose disableCloseOnDimmerClick onClose={() => { /** */ }} open={stateLoading}>
        <div className="authorize-modal-spinner"><Spinner /></div>
      </Modal>
    </>
  );
}
