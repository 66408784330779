import React, { ReactElement } from 'react';
import { Auth } from 'aws-amplify';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
} from 'react-router-dom';
import store from 'store/configureStore';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Icon from 'stories/atoms/Icon/Icon';
import Axios from 'axios';
import { LoginAction } from './store/actions/userActions';
import RootRoutes from './routing/RootRoutes';

Axios.interceptors.response.use((response) => response, (error) => {
  const status = error.response ? error.response.status : null;
  if (status === 401) {
    return Auth.currentSession().then((resp) => {
      if (!resp.isValid() || error.config.refreshed) {
        Auth.signOut();
        return Promise.reject(error);
      }
      const newConfig = {
        ...error.config,
        refreshed: true,
      };
      newConfig.headers.Authorization = `Bearer ${resp.getIdToken().getJwtToken()}`;
      store.dispatch(LoginAction());
      return Axios.request(newConfig);
    });
  }
  return Promise.reject(error);
});

function App(): ReactElement {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <RootRoutes />
        <ToastContainer bodyClassName="toast-body" toastClassName="toast-body" closeButton={<Icon name="cancel" color="white" size="mini" />} />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
