import React, {
  ReactElement, useState, useCallback, ChangeEvent,
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as projectActions from 'store/actions/projectActions';
import { useAppSelector, useAppDispatch } from 'utils';
import Input from 'stories/atoms/Input/Input';
import TextArea from 'stories/atoms/TextArea/TextArea';
import Button from 'stories/atoms/Button/Button';
import './CreateProject.css';

export default function Project(): ReactElement {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.project.loading);
  const navigate = useNavigate();

  const onChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    switch (event.target.name) {
      case 'name':
        setName(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      default:
        break;
    }
  }, []);

  const onSubmit = async (): Promise<void> => {
    dispatch(projectActions.CreateProjectAction(name, description))
      .then((projectUuid) => {
        navigate(`/projects/${projectUuid}`);
      });
  };

  return (
    <div className="create-project-container">
      <div className="create-project-form">
        <h3 className="create-project-form-title">Create a new project</h3>
        <Input
          className="create-project-form-input"
          fluid
          name="name"
          onChange={onChangeHandler}
          id="name"
          placeholder="Project name"
          label="Project name"
        />
        <TextArea
          className="create-project-form-input"
          rows={5}
          name="description"
          onChange={onChangeHandler}
          id="description"
          placeholder="Project description"
          label="Project description"
        />
        <div className="create-project-button">
          <Button loading={loading} onClick={onSubmit} id="create">Create project</Button>
        </div>
      </div>
    </div>

  );
}
