import React, { ReactElement, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'utils';
import * as organisationActions from 'store/actions/organisationActions';
import * as projectActions from 'store/actions/projectActions';
import Spinner from 'stories/atoms/Spinner/Spinner';
import Organisation from './Organisation';

export default function OrganisationWrapper(): ReactElement {
  const dispatch = useAppDispatch();
  const organisation = useAppSelector((state) => state.organisation.organisation);
  const teams = useAppSelector((state) => state.organisation.teams);
  useEffect(() => {
    dispatch(organisationActions.GetOrganisation());
    dispatch(organisationActions.GetOrganisationTeams());
    dispatch(projectActions.GetRepositoryTemplates());
  }, [dispatch]);
  return (
    organisation ? <Organisation organisation={organisation} teams={teams} /> : <Spinner />
  );
}
