import React, { ReactElement } from 'react';
import Button from 'stories/atoms/Button/Button';
import { SupportedVCS } from 'types/types';
import GitHubLogo from '../../images/GitHub.png';
import GitLabLogo from '../../images/GitLab.png';
import BitbucketLogo from '../../images/Bitbucket.png';
import styles from './VCSCard.module.css';

interface VCSCardProps {
  icon: SupportedVCS;
  title: string;
  authorized: boolean;
  className?: string;
  onAuthorize: () => void;
}
export default function VCSCard({
  icon, title, authorized, onAuthorize, className,
}: VCSCardProps): ReactElement {
  let logo;
  if (icon === 'GitHub') {
    logo = GitHubLogo;
  } else if (icon === 'Bitbucket') {
    logo = BitbucketLogo;
  } else if (icon === 'GitLab') {
    logo = GitLabLogo;
  }
  return (
    <div className={className ? `${styles.container} ${className}` : styles.container}>
      <img className={styles.image} width={48} height={48} src={logo} alt={`${icon} logo`} />
      <div>{title}</div>
      <div className={styles.actions}>
        {authorized ? <div className={styles.authorized}>Authorized</div> : <Button onClick={onAuthorize}>Authorize</Button>}
      </div>
    </div>
  );
}
