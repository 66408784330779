import React, { ReactElement } from 'react';
import Search from 'stories/atoms/Search/Search';
import { SearchProps } from 'stories/atoms/Search/types';
import Modal from '../Modal/Modal';
import styles from './SearchModal.module.css';

interface SearchModalProps extends SearchProps{
  open: boolean;
  onClose: () => void;
  title: string;
}

export default function SearchModal({
  open, onClose, title, ...rest
}: SearchModalProps): ReactElement {
  return (
    <Modal className={styles.modal} open={open} onClose={onClose}>
      <>
        <h3 className={styles.title}>{title}</h3>
        <Search {...rest} />
      </>
    </Modal>
  );
}
