import { Auth } from 'aws-amplify';
import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import {
  useNavigate, useLocation, Outlet, Navigate,
} from 'react-router-dom';
import { LogoutAction } from 'store/actions/userActions';
import { NavItemProps } from 'stories/composite/NavItem/types';
import Page from 'stories/composite/Page/Page';
import { useAppDispatch, useAppSelector } from 'utils';
import * as organisationActions from 'store/actions/organisationActions';
import Loading from 'routing/Loading/Loading';
import { User } from 'graphql/types';

interface IRequiresAuthProps {
  topNavItems: NavItemProps[];
  bottomNavItems: NavItemProps[];
}
export default function RequiresAuth({ topNavItems, bottomNavItems }: IRequiresAuthProps): ReactElement | null {
  const [orgsLoading, setOrgsLoading] = useState(true);
  const [loggingOut, setLogginOut] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const onLogout = useCallback(async () => {
    setLogginOut(true);
    await Auth.signOut();
  }, []);

  useEffect(() => {
    setOrgsLoading(true);
    if (user?.uuid) {
      dispatch(organisationActions.GetOrganisation()).finally(() => {
        setOrgsLoading(false);
      });
    }
  }, [dispatch, user?.uuid]);

  useEffect(() => {
    const redirectToLogin = () => {
      dispatch(LogoutAction());
      navigate('/login', { state: { from: location } });
    };
    Auth.currentSession().then((response) => {
      if (!response.isValid()) {
        redirectToLogin();
      }
    }).catch(() => {
      redirectToLogin();
    });
  }, [navigate, dispatch, location]);

  if (!user && !loggingOut) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  if (!user && loggingOut) {
    return <Navigate to="/logout" />;
  }
  if (orgsLoading) {
    return <Loading />;
  }
  return (
    <Page user={user as User} topNavItems={topNavItems} bottomNavItems={bottomNavItems} onLogout={onLogout}><Outlet /></Page>
  );
}
