import { Organisation, Team, TemplateRepository } from 'graphql/types';
import { Action, ActionType } from 'store/actionTypes';

interface IAdminState {
  organisations: Organisation[];
  templateRepositories: TemplateRepository[];
  currentOrganisation: Organisation | null;
  currentOrganisationTeams: Team[];
}
const initState: IAdminState = {
  organisations: [],
  templateRepositories: [],
  currentOrganisation: null,
  currentOrganisationTeams: [],
};

export default function project(state: IAdminState = initState, action: Action): IAdminState {
  switch (action.type) {
    case ActionType.ADMIN_GET_ORGANISATIONS: {
      return {
        ...state,
        organisations: action.payload,
      };
    }
    case ActionType.ADMIN_GET_CURRENT_ORGANISATION: {
      return {
        ...state,
        currentOrganisation: action.payload,
      };
    }
    case ActionType.ADMIN_GET_CURRENT_ORGANISATION_TEAMS: {
      return {
        ...state,
        currentOrganisationTeams: action.payload,
      };
    }
    case ActionType.SIGNOUT: {
      return initState;
    }
    default: {
      return state;
    }
  }
}
