import {
  Organisation, Team, TemplateRepository, Project, Repository, User, StoredValue, DataOrigin,
} from 'graphql/types';
import {
  IAuthorisedTargets,
} from '../types/types';

export enum ActionType {
  SIGNIN = 'SIGNIN',
  SIGNOUT = 'SIGNOUT',
  GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS',
  PROJECTS_LOADING = 'PROJECTS_LOADING',
  USER_LOADING = 'USER_LOADING',
  GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED',
  CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED',
  GET_CURRENT_PROJECT_SUCCESS = 'GET_CURRENT_PROJECT_SUCCESS',
  GET_CURRENT_PROJECT_FAILED = 'GET_CURRENT_PROJECT_FAILED',
  CLEAR_CURRENT_PROJECT = 'CLEAR_CURRENT_PROJECT',
  BIND_PROJECT_USER_SUCCESS = 'BIND_PROJECT_USER_SUCCESS',
  BIND_PROJECT_USER_FAILED = 'BIND_PROJECT_USER_FAILED',
  CREATE_REPOSITORY_SUCCESS = 'CREATE_REPOSITORY_SUCCESS',
  CREATE_REPOSITORY_FAILED = 'CREATE_REPOSITORY_FAILED',
  GET_USER_AUTHORISATION_TARGETS_SUCCESS = 'GET_USER_AUTHORISATION_TARGETS_SUCCESS',
  GET_USER_AUTHORISATION_TARGETS_FAILED = 'GET_USER_AUTHORISATION_TARGETS_FAILED',
  GET_TEMPLATE_REPOSITORIES_SUCCESS = 'GET_TEMPLATE_REPOSITORIES_SUCCESS',
  GET_TEMPLATE_REPOSITORIES_FAILED = 'GET_TEMPLATE_REPOSITORIES_FAILED',
  CREATE_GITHUB_STATE_SUCCESS = 'CREATE_GITHUB_STATE_SUCCESS',
  CREATE_GITHUB_STATE_FAILED = 'CREATE_GITHUB_STATE_FAILED',
  UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS',
  DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS',
  GET_ORGANISATION_SUCCESS = 'GET_ORGANISATION_SUCCESS',
  GET_ORGANISATION_FAILED = 'GET_ORGANISATION_FAILED',
  GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS',
  GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS',
  CLEAR_CURRENT_TEAM = 'CLEAR_CURRENT_TEAM',
  CLEAR_CURRENT_REPOSITORY = 'CLEAR_CURRENT_REPOSITORY',
  GET_CURRENT_REPOSITORY_SUCCESS = 'GET_CURRENT_REPOSITORY_SUCCESS',
  ADMIN_GET_ORGANISATIONS = 'ADMIN_GET_ORGANISATIONS',
  ADMIN_GET_CURRENT_ORGANISATION = 'ADMIN_GET_CURRENT_ORGANISATION',
  ADMIN_GET_CURRENT_ORGANISATION_TEAMS = 'ADMIN_GET_CURRENT_ORGANISATION_TEAMS',
  GET_STORED_VALUES_SUCCESS = 'GET_STORED_VALUES_SUCCESS',
  GET_DATA_ORIGINS_SUCCESS = 'GET_DATA_ORIGINS_SUCCESS',
  GET_PUBLIC_DATA_ORIGINS_SUCCESS = 'GET_PUBLIC_DATA_ORIGINS_SUCCESS',
}

interface actionSignin {
  type: ActionType.SIGNIN;
  payload: {
    user: User,
    token: string,
  };
}

interface actionSignout {
  type: ActionType.SIGNOUT;
}

interface actionGetProjectsSuccess {
  type: ActionType.GET_PROJECTS_SUCCESS;
  payload: Project[]
}

interface actionProjectsLoading {
  type: ActionType.PROJECTS_LOADING;
}

interface actionUserLoading {
  type: ActionType.USER_LOADING;
}

interface actionGetProjectsFailed {
  type: ActionType.GET_PROJECTS_FAILED;
}

interface actionCreateProjectSuccess {
  type: ActionType.CREATE_PROJECT_SUCCESS;
}
interface actionCreateProjectFailed {
  type: ActionType.CREATE_PROJECT_FAILED;
}
interface actionGetCurrentProjectSuccess {
  type: ActionType.GET_CURRENT_PROJECT_SUCCESS;
  payload: Project,
}
interface actionGetCurrentProjectFailed {
  type: ActionType.GET_CURRENT_PROJECT_FAILED;
}
interface actionClearCurrentProject {
  type: ActionType.CLEAR_CURRENT_PROJECT
}
interface actionCreateRepositorySuccess {
  type: ActionType.CREATE_REPOSITORY_SUCCESS
}
interface actionCreateRepositoryFailed {
  type: ActionType.CREATE_REPOSITORY_FAILED
}

interface actionGetAuthorisationTargetsSuccess {
  type: ActionType.GET_USER_AUTHORISATION_TARGETS_SUCCESS;
  payload: IAuthorisedTargets;
}

interface actionGetAuthorisationTargetsFailed {
  type: ActionType.GET_USER_AUTHORISATION_TARGETS_FAILED;
}

interface actionGetTemplateRepositoriesSuccess {
  type: ActionType.GET_TEMPLATE_REPOSITORIES_SUCCESS;
  payload: TemplateRepository[];
}

interface actionGetTemplateRepositoriesFailed {
  type: ActionType.GET_TEMPLATE_REPOSITORIES_FAILED;
}

interface actionCreateGithubStateSuccess {
  type: ActionType.CREATE_GITHUB_STATE_SUCCESS;
  payload: string;
}
interface actionCreateGithubStateFailed {
  type: ActionType.CREATE_GITHUB_STATE_FAILED;
}

interface actionDeleteProjectSuccess {
  type: ActionType.DELETE_PROJECT_SUCCESS;
}
interface actionUpdateProjectSuccess{
  type: ActionType.UPDATE_PROJECT_SUCCESS;
  payload: Project;
}

interface actionGetOrganisationSuccess {
  type: ActionType.GET_ORGANISATION_SUCCESS;
  payload: Organisation | null;
}
interface actionGetOrganisationFailed {
  type: ActionType.GET_ORGANISATION_FAILED;
}

interface actionGetTeamsSuccss {
  type: ActionType.GET_TEAMS_SUCCESS;
  payload: Team[];
}
interface actionGetTeamSuccss {
  type: ActionType.GET_TEAM_SUCCESS;
  payload: Team;
}

interface actionClearCurrentTeam {
  type: ActionType.CLEAR_CURRENT_TEAM;
}

interface actionGetCurrentRepositorySuccess {
  type: ActionType.GET_CURRENT_REPOSITORY_SUCCESS;
  payload: Repository;
}
interface actionClerCurrentRepository {
  type: ActionType.CLEAR_CURRENT_REPOSITORY;
}
interface actionAdminGetOrganisations {
  type: ActionType.ADMIN_GET_ORGANISATIONS;
  payload: Organisation[];
}

interface actionAdminGetCurrentOrganisation {
  type: ActionType.ADMIN_GET_CURRENT_ORGANISATION;
  payload: Organisation;
}

interface actionAdminGetOrganisationTeams {
  type: ActionType.ADMIN_GET_CURRENT_ORGANISATION_TEAMS;
  payload: Team[];
}

interface actionGetStoredValues {
  type: ActionType.GET_STORED_VALUES_SUCCESS;
  payload: StoredValue[];
}

interface actionGetDataOrigins {
  type: ActionType.GET_DATA_ORIGINS_SUCCESS;
  payload: DataOrigin[];
}

interface actionGetPublicDataOrigins {
  type: ActionType.GET_PUBLIC_DATA_ORIGINS_SUCCESS;
  payload: DataOrigin[];
}

export type Action = actionSignin | actionSignout | actionGetProjectsSuccess | actionProjectsLoading | actionGetProjectsFailed |
actionCreateProjectSuccess | actionCreateProjectFailed | actionGetCurrentProjectSuccess | actionGetCurrentProjectFailed |
actionClearCurrentProject | actionCreateRepositorySuccess | actionDeleteProjectSuccess | actionUpdateProjectSuccess |
actionCreateRepositoryFailed | actionGetAuthorisationTargetsSuccess | actionGetAuthorisationTargetsFailed | actionGetTemplateRepositoriesSuccess |
actionGetTemplateRepositoriesFailed | actionCreateGithubStateSuccess | actionCreateGithubStateFailed | actionUserLoading |
actionGetOrganisationSuccess | actionGetOrganisationFailed | actionGetTeamsSuccss | actionGetTeamSuccss | actionClearCurrentTeam |
actionClerCurrentRepository | actionGetCurrentRepositorySuccess | actionAdminGetOrganisations | actionAdminGetCurrentOrganisation |
actionAdminGetOrganisationTeams | actionGetStoredValues | actionGetDataOrigins | actionGetPublicDataOrigins;
