import { Member, Organisation, Team } from 'graphql/types';
import React, {
  ChangeEvent, ReactElement, useCallback, useState,
} from 'react';
import Icon from 'stories/atoms/Icon/Icon';
import Modal from 'stories/composite/Modal/Modal';
import UserCard from 'stories/composite/UserCard/UserCard';
import { useAppDispatch, useAppSelector, useFormFields } from 'utils';
import * as organisationActions from 'store/actions/organisationActions';
import * as userActions from 'store/actions/userActions';
import Button from 'stories/atoms/Button/Button';
import Input from 'stories/atoms/Input/Input';
import Spinner from 'stories/atoms/Spinner/Spinner';
import SearchModal from 'stories/composite/SearchModal/SearchModal';
import { SearchResult } from 'stories/atoms/Search/types';
import IsAllowed from 'stories/misc/IsAllowed/IsAllowed';
import Tabs from 'stories/composite/Tabs/Tabs';
import { IPanel } from 'stories/composite/Tabs/types';

interface OrganisationTeamProps {
  team: Team;
  organisation: Organisation;
}
export default function OrganisationTeam({ team, organisation }: OrganisationTeamProps): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [addGroupOpen, setAddGroupOpen] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const { formFields, createChangeHandler, setFormFields } = useFormFields({
    filter: '',
    groupfilter: '',
    groupName: '',
  });
  const currentTeam = useAppSelector((state) => state.organisation.currentTeam);

  const dispatch = useAppDispatch();

  const onModalOpen = useCallback(
    () => {
      dispatch(organisationActions.GetTeamByUUID(team.uuid));
      setModalOpen(true);
    },
    [dispatch, team.uuid],
  );
  const onModalClose = useCallback(
    () => {
      dispatch(organisationActions.ClearCurrentTeam());
      setModalOpen(false);
    },
    [dispatch],
  );
  const deleteTeam = useCallback(
    async () => {
      await dispatch(organisationActions.DeleteTeam(team.uuid));
    },
    [dispatch, team.uuid],
  );

  const searchUsers = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length >= 3) {
        const users = await dispatch(userActions.FindUsers(event.target.value));
        if (users) {
          const results: SearchResult[] = users.map((user) => ({
            id: `${user.username}-result`,
            text: user.username,
            key: user.uuid,
            uuid: user.uuid,
          }));
          setSearchResults(results);
        } else {
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
      }
    }, [dispatch],
  );

  const addUserToTeam = useCallback(
    async (user: SearchResult) => {
      dispatch(organisationActions.AddTeamMember(team.uuid, user.uuid));
      setAddMemberOpen(false);
    },
    [dispatch, team.uuid],
  );

  const changeUserRole = useCallback(
    (member: Member) => {
      dispatch(organisationActions.UpdateTeamMember(team.uuid, member.user.uuid, member.role.name === 'Admin' ? 'User' : 'Admin'));
    },
    [dispatch, team.uuid],
  );

  const removeMember = useCallback(
    (member: Member) => {
      dispatch(organisationActions.DeleteTeamMember(team.uuid, member.user.uuid));
    },
    [dispatch, team.uuid],
  );

  const removeGroup = useCallback(
    (groupUuid: string) => {
      dispatch(organisationActions.DeleteTeamGroup(team.uuid, groupUuid));
    },
    [dispatch, team.uuid],
  );

  const addTeamGroup = useCallback(
    async () => {
      await dispatch(organisationActions.AddTeamGroup(team.uuid, formFields.groupName));
      setAddGroupOpen(false);
      setFormFields({
        ...formFields,
        groupName: '',
      });
    },
    [dispatch, formFields, setFormFields, team.uuid],
  );

  const getPanels = useCallback(
    (): IPanel[] => {
      if (!currentTeam) {
        return [];
      }
      const _panels = [
        {
          id: 'members-tab',
          title: 'Members',
          content: (
            <>
              <div className="org-entities-filter-bar">
                <Input onChange={createChangeHandler('filter')} placeholder="Filter members by username" />
                {!team.isExternal
                  ? (
                    <IsAllowed requiredPrivilege="isAllowedTeamEdit" organisation={organisation} team={currentTeam}>
                      <Button onClick={() => setAddMemberOpen(true)}>Add member</Button>
                    </IsAllowed>
                  )
                  : null}
              </div>
              <div className="org-entities-list">
                {currentTeam.members?.filter((member) => (member.user.username.includes(formFields.filter))).map((member) => (
                  <UserCard
                    key={member.user.uuid}
                    title={member.user.username}
                    subtitle={member.user.email}
                    icon={member.role.name === 'Admin' ? 'admin' : 'user'}
                    popupContent={(
                      <>
                        <div className="entity-popup-action" onClick={() => changeUserRole(member)}>{member.role.name === 'Admin' ? 'Demote to user' : 'Promote to admin'}</div>
                        <div className="entity-popup-action" onClick={() => removeMember(member)}>Remove member</div>
                      </>
                    )}
                  />
                ))}
              </div>

            </>
          ),
        }];
      if (currentTeam.isExternal) {
        _panels.unshift({
          id: 'groups-tab',
          title: 'External groups',
          content: (
            <>
              <div className="org-entities-filter-bar">
                <Input onChange={createChangeHandler('groupfilter')} placeholder="Filter groups by name" />
                {team.isExternal
                  ? (
                    <IsAllowed requiredPrivilege="isAllowedTeamEdit" organisation={organisation} team={currentTeam}>
                      <Button onClick={() => setAddGroupOpen(true)}>Add group</Button>
                    </IsAllowed>
                  )
                  : null}
              </div>
              <div>
                {currentTeam.groups.map((group) => (
                  <UserCard
                    key={group.group.uuid}
                    icon="team"
                    id={group.group.uuid}
                    title={group.group.name}
                    subtitle=""
                    popupContent={currentTeam.amIAdmin || organisation.amIAdmin ? (
                      <div className="entity-popup-action" onClick={() => removeGroup(group.group.uuid)}>Remove group</div>
                    ) : undefined}
                  />
                ))}
              </div>
            </>
          ),
        });
      }
      return _panels;
    },
    [changeUserRole, createChangeHandler, currentTeam, formFields.filter, organisation, removeGroup, removeMember, team.isExternal],
  );

  return (
    <>
      <UserCard onClick={onModalOpen} title={team.name} icon="team" subtitle={team.description || ''} />
      <Modal open={modalOpen} onClose={onModalClose} hideClose={!currentTeam}>
        {!currentTeam ? <Spinner /> : (
          <div className="org-entity-modal">
            <div className="org-entity-modal-top">
              <Icon
                name="team"
                size="large"
                color="white"
              />
              <h2>{currentTeam?.name}</h2>
              <IsAllowed requiredPrivilege="isAllowedTeamEdit" organisation={organisation} team={currentTeam}>
                <Icon
                  name="delete"
                  size="small"
                  color="primary"
                  actionable
                  onClick={deleteTeam}
                />
              </IsAllowed>
            </div>
            <div className="org-entity-modal-info">
              <div className="org-entity-modal-info-title">Description:</div>
              <div className="org-entity-modal-info-value">{currentTeam?.description}</div>
            </div>
            {currentTeam.isExternal
              ? (
                <div className="org-entity-modal-info">
                  <div className="org-entity-modal-info-title">External:</div>
                  <div className="org-entity-modal-info-value">Members synced from external source</div>
                </div>
              ) : null}

            <Tabs
              className="org-entities-tabs"
              panels={getPanels()}
            />

          </div>
        )}
      </Modal>
      <SearchModal
        id="add-team-member"
        open={addMemberOpen}
        onClose={() => setAddMemberOpen(false)}
        title="Add member to team"
        results={searchResults}
        onResultSelect={addUserToTeam}
        onChange={searchUsers}
        debounceTimer={300}
        minInputLength={3}
      />
      <Modal
        id="add-team-group"
        open={addGroupOpen}
        onClose={() => { setFormFields({ ...formFields, groupName: '' }); setAddGroupOpen(false); }}
      >
        <div className="add-team-group-modal">
          <h2>Add external group to team</h2>
          <Input onChange={createChangeHandler('groupName')} label="Group name" placeholder="Group name" />
          <div className="button-row">
            <Button onClick={() => setAddGroupOpen(false)} variant="secondary">Cancel</Button>
            <Button onClick={addTeamGroup}>Add</Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
