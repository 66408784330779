import React, { ReactElement, useCallback, useState } from 'react';
import Button from 'stories/atoms/Button/Button';
import Icon from 'stories/atoms/Icon/Icon';
import Link from 'stories/atoms/Link/Link';
import { ITemplateInputSaved } from 'types/types';
import styles from './SelectionBox.module.css';
import SelectionBoxInputs from './SelectionBoxInputs/SelectionBoxInputs';
import { SelectionBoxProps } from './types';

export default function SelectionBox({
  title, subtitle, defaultSelected, onSelect, onDeselect, inputs, onInputSave, inputDefaultValues, link, id, source, storedValues,
}: SelectionBoxProps): ReactElement {
  const [isSelected, setIsSelected] = useState(defaultSelected);
  const [modalOpen, setModalOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showMoreInputValues, setShowMoreInputValues] = useState(false);

  const select = useCallback(
    () => {
      if (!isSelected && inputs?.length) {
        setModalOpen(true);
      }
      if (!isSelected) {
        setIsSelected(true);
        onSelect();
      }
    },
    [inputs?.length, isSelected, onSelect],
  );

  const toggleModal = useCallback(
    () => {
      setModalOpen(!modalOpen);
    },
    [modalOpen],
  );

  const updateValues = useCallback(
    (values: ITemplateInputSaved[]): void => {
      if (onInputSave) {
        onInputSave(values);
        toggleModal();
      }
    },
    [onInputSave, toggleModal],
  );

  const deselect = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setIsSelected(false);
      onDeselect();
      if (modalOpen) {
        setModalOpen(false);
      }
    },
    [modalOpen, onDeselect],
  );

  const containsInputs = isSelected && inputs && inputs.length && onInputSave;
  return (
    <div
      className={`${styles['selection-box']}${isSelected ? ` ${styles.selected}` : ''}`}
      onClick={select}
      id={id}
      key={id}
    >
      <div className={styles.top}>
        {isSelected ? (
          <div className={styles['top-actions']}>
            <Button variant="link" onClick={deselect}>Deselect</Button>
          </div>
        ) : null}
      </div>
      <div className={styles.body}>
        <h4 className={styles.title}>{title}</h4>
        {subtitle ? <p className={styles.subtitle}>{subtitle}</p> : null}
        <p className={styles.subtitle}>{source}</p>
        {containsInputs
          ? (
            <>
              <span className={styles['required-info']}>Required information <Icon actionable color="primary" width={16} height={16} name="edit" onClick={() => { setEditing(true); toggleModal(); }} /></span>
              <div className={styles['input-value-list']}>
                {inputs.map((input, index) => {
                  const value = inputDefaultValues?.find((defaultValue) => defaultValue.field === input.field);
                  if (index >= 5 && !showMoreInputValues) return null;
                  if (value) {
                    return <div key={`${id}-${input.field}`}><span>{input.name}:</span> {value.type === 'password' ? '**********' : value.text}</div>;
                  }
                  return null;
                })}
                {inputs.length > 5
                  ? <div className={styles['show-more-list-items']} onClick={() => { setShowMoreInputValues(!showMoreInputValues); }}>{ showMoreInputValues ? 'Show less' : 'Show more'}</div>
                  : null}
              </div>
            </>
          )
          : null}
        {link ? <span onClick={(event) => event.stopPropagation()} className={styles.link}><Link type="button-secondary" href={link.href}>{link.text}</Link></span> : null}
      </div>
      <SelectionBoxInputs
        open={modalOpen}
        id={`${id}-modal`}
        onCancel={editing ? () => { setEditing(false); toggleModal(); } : deselect}
        inputs={inputs}
        inputDefaultValues={inputDefaultValues}
        save={updateValues}
        storedValues={storedValues}
      />
    </div>
  );
}
