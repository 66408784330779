import React, {
  ReactElement, useCallback, useState, FormEvent, useRef,
} from 'react';
import Button from 'stories/atoms/Button/Button';
import Modal from 'stories/composite/Modal/Modal';
import TemplateInputField from 'stories/composite/TemplateInputField/TemplateInputField';
import { ITemplateInputSaved } from 'types/types';
import styles from '../ScriptCard.module.css';
import { ScriptCardInputsProps } from '../types';

export default function ScriptCardInputs({
  open, id, inputs, inputDefaultValues, toggleModal, deploy, title, storedValues,
}: ScriptCardInputsProps): ReactElement {
  const [valueChanges, setValueChanges] = useState<ITemplateInputSaved[]>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const onInputValueChangeHandler = useCallback(
    (value: ITemplateInputSaved) => {
      const _newValueChanges = [...valueChanges];
      const foundIndex = _newValueChanges.findIndex((valueChange) => valueChange.field === value.field);
      if (foundIndex > -1) {
        _newValueChanges.splice(foundIndex, 1);
      }
      if (value.value || value.type === 'list') {
        _newValueChanges.push(value);
      }
      setValueChanges(_newValueChanges);
    },
    [valueChanges],
  );

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      deploy(valueChanges);
      if (formRef.current) {
        formRef.current.reset();
      }
      toggleModal();
    },
    [valueChanges, deploy, toggleModal],
  );
  const onReset = useCallback(
    () => {
      setValueChanges([]);
    },
    [],
  );

  return (
    <Modal key={`${id}-modal`} id={`${id}-modal`} hideClose className={styles.modal} size="custom" open={open} onClose={toggleModal}>
      <div className={styles['modal-content']} key={`${id}-modal-content`}>
        <h3 className={styles['modal-title']}>{title}</h3>
        <form ref={formRef} className={styles['input-form']} onSubmit={onSubmit} onReset={onReset}>
          <div className={styles.inputs}>
            {inputs?.map((input) => (
              <TemplateInputField
                key={input.field}
                input={input}
                inputDefaultValues={inputDefaultValues}
                onInputValueChangeHandler={onInputValueChangeHandler}
                storedValues={storedValues}
              />
            ))}
          </div>
          <div className={styles['form-buttons']}>
            <Button variant="secondary" className={styles['cancel-modal']} onClick={(event) => { event.stopPropagation(); toggleModal(); }}>Cancel</Button>
            <Button className={styles['save-modal']} type="submit">Deploy</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
