import React, { ReactElement } from 'react';
import { SupportedVCS } from 'types/types';
import GitHubLogo from '../../images/GitHub.png';
import GitLabLogo from '../../images/GitLab.png';
import BitbucketLogo from '../../images/Bitbucket.png';
import styles from './VCSOption.module.css';

interface VCSOptionProps {
  value: SupportedVCS;
  current?: SupportedVCS | '' | null;
  onClick: () => void;
}
export default function VCSOption({ value, current, onClick }: VCSOptionProps): ReactElement {
  let logo;
  if (value === 'GitHub') {
    logo = GitHubLogo;
  } else if (value === 'Bitbucket') {
    logo = BitbucketLogo;
  } else if (value === 'GitLab') {
    logo = GitLabLogo;
  }
  return (
    <div
      className={value === current ? `${styles.container} ${styles.selected}` : styles.container}
      onClick={onClick}
    >
      <img className={styles.image} width={30} height={30} src={logo} alt={`${value}`} />
      {value}
    </div>
  );
}
