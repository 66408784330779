import React, { ReactElement, useCallback } from 'react';
import Portal from 'stories/misc/Portal/Portal';
import Button from 'stories/atoms/Button/Button';
import styles from './Notification.module.css';
import { NotificationProps } from './types';

export default function Notification({
  id, open, header, children, cancelButton, confirmButton, onCancel, onConfirm,
  disableCloseOnDimmerClick, confirmButtonId, cancelButtonId, className, size = 'small', confirmDisabled,
}: NotificationProps): ReactElement | null {
  function stopClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }

  const onDimmerClick = useCallback(
    () => {
      if (!disableCloseOnDimmerClick && onCancel) {
        onCancel();
      } else if (!disableCloseOnDimmerClick && onConfirm) {
        onConfirm();
      }
    },
    [disableCloseOnDimmerClick, onCancel, onConfirm],
  );

  if (!open) { return null; }
  return (
    <Portal>
      <div
        className={`${styles.dimmer} ${styles.notification} ${className || ''}`}
        onClick={onDimmerClick}
      >
        <div
          id={id}
          onClick={(e) => stopClick(e)}
          className={`${styles.wrapper} ${styles[size]}`}
        >
          {header ? (
            <h4 className={`${styles['notification-header']}`}>
              {header}
            </h4>
          ) : null}
          {children ? (
            <div className={` ${styles[size]} ${styles['notification-content']}`}>
              {children}
            </div>
          ) : null}
          {cancelButton || confirmButton ? (
            <div className={`${styles['notification-buttons-wrapper']}`}>
              {cancelButton ? (
                <Button
                  onClick={onCancel}
                  id={cancelButtonId || 'cancel'}
                  variant="secondary"
                >{cancelButton}
                </Button>
              ) : null}
              {confirmButton ? (
                <Button
                  id={confirmButtonId || 'confirm'}
                  onClick={onConfirm}
                  variant="primary"
                  disabled={confirmDisabled}
                >{confirmButton}
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </Portal>
  );
}
