/* eslint-disable @typescript-eslint/no-explicit-any */
import Axios, { AxiosResponse } from 'axios';
import React from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState, AppDispatch } from 'types/types';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>();

export function useQuery():URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export function useFormFields<T>(initialValues: T):
{
  formFields: T,
  createChangeHandler: (key: keyof T) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  setFormFields: React.Dispatch<React.SetStateAction<T>>
} {
  const [formFields, setFormFields] = React.useState<T>(initialValues);
  const createChangeHandler = (key: keyof T) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let value: string | boolean;
    if (e.target.type === 'checkbox') {
      value = (e as React.ChangeEvent<HTMLInputElement>).target.checked;
    } else {
      value = e.target.value;
    }
    setFormFields((prev: T) => ({ ...prev, [key]: value }));
  };
  return { formFields, createChangeHandler, setFormFields };
}

export const baseUrl = process.env.REACT_APP_PROJECT_API_URL;
export const path = '/api';
export const adminPath = '/admin-api';
export const headers = {
  'content-type': 'application/json',
  Accept: 'application/json',
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function checkErrors(response: AxiosResponse): void {
  if (response.data.errors) {
    throw response.data.errors;
  }
  if (response.data.data && response.data.data.executeTemplates && response.data.data.executeTemplates.errors && response.data.data.executeTemplates.errors.length) {
    throw response.data.data.executeTemplates.errors;
  }
}

export function getErrorString(error: unknown): string {
  if (typeof error === 'string') {
    return error;
  } else if (Axios.isAxiosError(error)) {
    if (error.response?.data.message) {
      return error.response.data.message;
    }
    return error.message;
  } else if (error instanceof Error) {
    return error.message;
  } else if (error instanceof Array) {
    if (error[0].message) {
      return error[0].message;
    } else {
      return error[0];
    }
  }
  return 'Error occured';
}

export function formatUnderscoreStrings(toFormat: string): string {
  let _string = toFormat;
  _string = _string.charAt(0).toUpperCase() + _string.slice(1);
  return _string.replaceAll('_', ' ');
}

export const joinWithAnd = (arr:string[]):string => [arr.slice(0, -1).join(', '), arr.slice(-1)[0]].join(arr.length < 2 ? '' : ' and ');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function objectToGraphqlString(object: any):string {
  const json = JSON.stringify(object);
  const unquoted = json.replace(/"([^"]+)":/g, '$1:');
  return unquoted;
}

export function isUrl(value: string): boolean {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return regex.test(value);
}

export function isHttps(value: string): boolean {
  const regex = /^(https:\/\/)/;
  return regex.test(value);
}
