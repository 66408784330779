import React, { ReactElement } from 'react';
import { Link as DomLink } from 'react-router-dom';
import styles from './Link.module.css';

interface LinkProps {
  href: string;
  children: string | ReactElement;
  newTab?: boolean;
  type?: 'button-primary' | 'button-secondary' | 'text';
  id?: string;
  className?: string;
}
export default function Link({
  href, children, id, newTab = true, type = 'text', className,
}: LinkProps): ReactElement {
  function getClassName(): string {
    let _name = '';
    if (type === 'button-primary') {
      _name = `${_name} ${styles.button} ${styles['button-primary']}`;
    }
    if (type === 'button-secondary') {
      _name = `${_name} ${styles.button} ${styles['button-secondary']}`;
    }
    if (type === 'text') {
      _name = `${_name} ${styles.text}`;
    }
    if (className) {
      _name = `${_name} ${className}`;
    }
    return _name;
  }

  return (
    href.includes('://') || href.includes('www.')
      ? (
        <a
          id={id}
          className={getClassName()}
          target={newTab ? '_blank' : undefined}
          href={href}
          rel="noreferrer"
        >
          { children }
        </a>
      )
      : (
        <DomLink
          id={id}
          to={href}
          className={getClassName()}
        >
          {children}
        </DomLink>
      )
  );
}
