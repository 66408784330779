import { Repository } from 'graphql/types';
import React, {
  ReactElement, useEffect, useState,
} from 'react';
import RepositoryDetailsInfo from './RepositoryDetailsInfo/RepositoryDetailsInfo';

interface RepositoryDetailsInfoProps {
  repository: Repository;
}
export interface IInfo {
  text: string,
  value?: string,
  uuid: string
}
export default function RepositoryDetailsInfos({ repository }: RepositoryDetailsInfoProps): ReactElement {
  const [usedTemplates, setUsedTemplates] = useState<IInfo[]>([]);
  const [documentation, setDocumentation] = useState<IInfo[]>([]);
  const [otherDocumentation, setOtherDocumentation] = useState<IInfo[]>([]);

  useEffect(() => {
    if (repository) {
      const _usedTemplates = repository?.attributes.filter((attribute) => attribute.name.endsWith('.template_name'))
        .map((attribute) => ({ text: attribute.displayText, uuid: attribute.uuid }));
      const _documentations = repository?.attributes.filter((attribute) => attribute.name.includes('documentation.'))
        .map((attribute) => ({ text: attribute.displayText, value: attribute.value, uuid: attribute.uuid }));
      const _other = repository?.attributes.filter((attribute) => !attribute.name.includes('documentation.'))
        .filter((attribute) => !attribute.name.endsWith('.template_name'))
        .map((attribute) => ({ text: attribute.displayText, value: attribute.value, uuid: attribute.uuid }));
      setUsedTemplates(_usedTemplates);
      setDocumentation(_documentations);
      setOtherDocumentation(_other);
    }
  }, [repository]);
  return (
    <div className="repository-details-info">
      <RepositoryDetailsInfo title="Used Templates" info={usedTemplates} icon="code" />
      <RepositoryDetailsInfo title="Documentation" info={documentation} icon="documentation" />
      { otherDocumentation.length ? <RepositoryDetailsInfo title="Other" info={otherDocumentation} icon="other" /> : null}
    </div>
  );
}
