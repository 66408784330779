import { Dispatch } from 'redux';
import { Auth } from 'aws-amplify';
import Axios from 'axios';
import store from 'store/configureStore';
import {
  findUsersQuery, getAuthorisedTargetsQuery, getUserQuery, getAuthenticatedUserQuery, getDataOrgins, getPublicDataOrigins,
} from 'graphql/queries';
import {
  baseUrl, path, headers, checkErrors, getErrorString,
} from 'utils';
import { toast } from 'react-toastify';
import { authoriseUserMutation, generateUserState } from 'graphql/mutations';
import {
  IAuthorisationTargetOrganisation, IAuthorisationTargetUser, IAuthorisedTargets,
} from 'types/types';
import { DataOrigin, User } from 'graphql/types';
import { ActionType, Action } from '../actionTypes';

export const LoginAction = () => async (dispatch: Dispatch<Action>): Promise<void> => {
  const userSession = await Auth.currentSession();
  const token = userSession.getIdToken().getJwtToken();
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${token}` },
      data: { query: getAuthenticatedUserQuery() },
    });
    checkErrors(response);
    const { user } = response.data.data.getUserInfo;
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
    dispatch({
      type: ActionType.SIGNIN,
      payload: {
        user,
        token,
      },
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const LogoutAction = () => async (dispatch: Dispatch<Action>): Promise<void> => {
  dispatch({
    type: ActionType.SIGNOUT,
  });
};

export const GetUserAuthorisationTargets = () => async (dispatch: Dispatch<Action>): Promise<void | string> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: getAuthorisedTargetsQuery() },
    });
    checkErrors(response);
    const authorisationTargets: IAuthorisedTargets = {
      users: response.data.data?.authorisedTargets.users as IAuthorisationTargetUser[],
      organisations: response.data.data?.authorisedTargets.organisations as IAuthorisationTargetOrganisation[],
    };
    dispatch({
      type: ActionType.GET_USER_AUTHORISATION_TARGETS_SUCCESS,
      payload: authorisationTargets,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    dispatch({
      type: ActionType.GET_USER_AUTHORISATION_TARGETS_FAILED,
      payload: errorString,
    });
    toast.error(errorString);
    return Promise.reject(errorString);
  }
  return Promise.resolve();
};

export const NavigateToVCSAuthorizationUrl = (dataOrigin: DataOrigin) => async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    dispatch({ type: ActionType.USER_LOADING });
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: generateUserState(dataOrigin.uuid) },
    });
    checkErrors(response);
    const userState: string = response.data.data?.generateUserState?.state.state;
    const scopes = process.env.REACT_APP_REQUIRED_GITHUB_SCOPES as string;
    const githubClientId = response.data.data?.generateUserState?.state.dataOriginClientId;
    window.localStorage.setItem('authorizeDataOrigin', dataOrigin.uuid);
    if (dataOrigin.type === 'GitHub') {
      const installURL = `${dataOrigin.url}login/oauth/authorize`;
      window.location.href = `${installURL}?client_id=${githubClientId}&scope=${scopes}&state=${userState}`;
    }
    return Promise.resolve();
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
};

export const AuthorizeUser = (state:string, code:string, dataOriginUuid: string) => async (): Promise<void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: authoriseUserMutation(state, code, dataOriginUuid) },
    });
    checkErrors(response);
    toast.success('Success!');
    return Promise.resolve();
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
};

export const FindUsers = (searchString: string) => async (): Promise<User[] | void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: findUsersQuery(searchString) },
    });
    checkErrors(response);
    const usersResponse: User[] = response.data.data.findUsers.users;
    return Promise.resolve(usersResponse);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
};

export const GetUserByUUID = (uuid: string) => async (): Promise<User | void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: getUserQuery(uuid) },
    });
    checkErrors(response);
    const userResponse: User = response.data.data.getUser.user;
    return Promise.resolve(userResponse);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
};

export const GetDataOrigins = (publicDataOrigins = false) => async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: publicDataOrigins ? getPublicDataOrigins() : getDataOrgins() },
    });
    checkErrors(response);

    let dataOrigins: DataOrigin[];
    if (publicDataOrigins) {
      dataOrigins = response.data.data.publicDataOrigins.dataOrigins;
      dispatch({
        type: ActionType.GET_PUBLIC_DATA_ORIGINS_SUCCESS,
        payload: dataOrigins,
      });
    } else {
      dataOrigins = response.data.data.dataOrigins.dataOrigins;
      dispatch({
        type: ActionType.GET_DATA_ORIGINS_SUCCESS,
        payload: dataOrigins,
      });
    }
    return Promise.resolve();
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
};
