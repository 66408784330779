import React, { ReactElement, useEffect } from 'react';
import { GetAllOrganisations } from 'store/actions/adminActions';
import Tabs from 'stories/composite/Tabs/Tabs';
import { IPanel } from 'stories/composite/Tabs/types';
import { useAppDispatch } from 'utils';
import Organisations from './Organisations/Organisations';
import TemplateRepositories from './TemplateRepositories/TemplateRepositories';
import './Admin.css';

export default function Admin(): ReactElement {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(GetAllOrganisations());
  }, [dispatch]);
  const panels: IPanel[] = [
    {
      id: 'trganisations',
      title: 'Organisations',
      content: <Organisations />,
    },
    {
      id: 'template-repositories',
      title: 'Public template repositories',
      content: <TemplateRepositories />,
    },
  ];
  return (
    <Tabs panels={panels} />
  );
}
