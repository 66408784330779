import React, { ReactElement } from 'react';
import Icon from 'stories/atoms/Icon/Icon';
import Popup from 'stories/atoms/Popup/Popup';
import styles from './UserCard.module.css';

interface UserCardProps {
  id?: string;
  icon: 'admin' | 'user' | 'team';
  title: string;
  subtitle: string;
  onClick?: () => void;
  popupContent?: ReactElement
}

export default function UserCard({
  icon, title, subtitle, onClick, popupContent, id,
}: UserCardProps): ReactElement {
  const content = (
    <div className={styles.container} id={id} onClick={onClick}>
      <div className={styles.icon}>
        <Icon name={icon} size="large" color="white" />
      </div>
      <div className={styles.info}>
        <div className={`${styles['info-field']} ${styles.title}`}>{title}</div>
        <div className={`${styles['info-field']} ${styles.subtitle}`}>{subtitle}</div>
      </div>
    </div>
  );
  if (popupContent) {
    return (
      <Popup
        on="click"
        position="top-right"
        trigger={content}
        className={styles.popup}
      >
        {popupContent}
      </Popup>
    );
  }
  return content;
}
