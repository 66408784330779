import { Auth } from 'aws-amplify';
import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageContent from 'stories/composite/PageContent/PageContent';
import { useAppSelector } from 'utils';

export default function Logout(): ReactElement {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  useEffect(() => {
    setTimeout(() => {
      navigate('/login');
    }, 5000);
  }, [navigate]);

  useEffect(() => {
    if (user) {
      Auth.signOut();
    }
  }, [user]);

  return (
    <PageContent>
      Logged out. Redirecting in 5 seconds
    </PageContent>
  );
}
