import { DataOrigin } from 'graphql/types';
import React, { FormEvent, ReactElement, useCallback } from 'react';
import { EditDataOriginAction } from 'store/actions/organisationActions';
import Button from 'stories/atoms/Button/Button';
import Input from 'stories/atoms/Input/Input';
import Modal from 'stories/composite/Modal/Modal';
import {
  isHttps, isUrl, useAppDispatch, useFormFields,
} from 'utils';

interface EditIntegrationProps {
  open: boolean;
  onClose: () => void;
  dataOrigin: DataOrigin;
  refresh: () => void;
}
export default function EditIntegration({
  open, onClose, dataOrigin, refresh,
}: EditIntegrationProps): ReactElement | null {
  const dispatch = useAppDispatch();
  const { formFields, createChangeHandler } = useFormFields({
    name: dataOrigin?.name || '',
    url: dataOrigin?.url || '',
    apiUrl: dataOrigin?.apiUrl || '',
    clientId: '',
    clientSecret: '',
  });

  const onEditDataOriginSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (!dataOrigin) { return; }
      dispatch(EditDataOriginAction(dataOrigin.uuid, formFields.name, dataOrigin.type, formFields.url, formFields.apiUrl, formFields.clientId || undefined, formFields.clientSecret || undefined)).then(() => {
        refresh();
        onClose();
      });
    },
    [dataOrigin, dispatch, formFields, onClose, refresh],
  );

  const isValidUrlCheck = useCallback(
    (value: string): string | boolean => {
      if (!value) {
        return false;
      }
      if (isUrl(value) && isHttps(value)) {
        return false;
      }
      return 'Needs to be valid https URL';
    },
    [],
  );

  return (
    <Modal className="org-admin-add-integration-modal" open={open} onClose={onClose}>
      <>
        <h2 className="org-admin-add-integration-title">Edit VCS Integration</h2>
        <form onSubmit={onEditDataOriginSubmit} className="org-admin-add-integration-form">
          <Input
            wrapperClassName="org-admin-add-integration-input"
            defaultValue={dataOrigin?.name}
            required
            label="Name"
            onChange={createChangeHandler('name')}
          />
          <Input
            wrapperClassName="org-admin-add-integration-input"
            defaultValue={dataOrigin?.url}
            required
            label="URL"
            onChange={createChangeHandler('url')}
            info="Base URL of the version control system"
            error={isValidUrlCheck(formFields.url)}
          />
          <Input
            wrapperClassName="org-admin-add-integration-input"
            defaultValue={dataOrigin?.apiUrl}
            required
            label="API URL"
            onChange={createChangeHandler('apiUrl')}
            info="API URL of the version control system"
            error={isValidUrlCheck(formFields.apiUrl)}
          />
          <Input
            wrapperClassName="org-admin-add-integration-input"
            label="Client ID"
            onChange={createChangeHandler('clientId')}
            info="Client ID of the OAuth application configured in the version control system. Leave empty if not changing this value"
          />
          <Input
            type="password"
            wrapperClassName="org-admin-add-integration-input"
            label="Client secret"
            onChange={createChangeHandler('clientSecret')}
            info="Client secret of the OAuth application configured in the version control system. Leave empty if not changing this value"
          />
          <Button className="org-admin-add-integration-button" type="submit">Edit</Button>
        </form>
      </>
    </Modal>
  );
}
