import React, {
  ChangeEvent, FormEvent, ReactElement, useCallback, useState,
} from 'react';
import Button from 'stories/atoms/Button/Button';
import Input from 'stories/atoms/Input/Input';
import { AuthPages } from '../types';

interface SignupProps {
  changePage: (page: AuthPages) => void;
  signUp: (username: string, password: string, email: string) => Promise<void>;
}
export default function Signup({ changePage, signUp }: SignupProps): ReactElement {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const onChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'username':
        setUsername(event.target.value);
        break;
      case 'password':
        setPassword(event.target.value);
        break;
      case 'password-confirmation':
        setPasswordConfirmation(event.target.value);
        break;
      case 'email':
        setEmail(event.target.value);
        break;
      default:
        break;
    }
  }, []);

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      setLoading(true);
      e.preventDefault();
      signUp(username, password, email).finally(() => setLoading(false));
    },
    [username, password, signUp, email],
  );

  function checkPassword(): string {
    if (!password) { return ''; }
    if (password.length < 8) {
      return 'Password must contain at least 8 characters';
    }
    const specialCharacters = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (!specialCharacters.test(password)) {
      return 'Password must contain at least 1 symbol';
    }
    if (password.toLowerCase() === password) {
      return 'Password must contain at least 1 uppercase letter';
    }
    if (password.toUpperCase() === password) {
      return 'Password must contain at least 1 lowercase letter';
    }
    return '';
  }

  function checkPasswordConfirmation(): string {
    if (!passwordConfirmation) { return ''; }
    if (passwordConfirmation !== password) {
      return 'Passwords must match';
    }
    return '';
  }

  function checkDisabled(): boolean {
    if (!password || !passwordConfirmation || !username || !email) {
      return true;
    }
    if (checkPassword() !== '') {
      return true;
    }
    if (checkPasswordConfirmation() !== '') {
      return true;
    }
    return false;
  }
  return (
    <form onSubmit={onSubmit}>
      <h3 className="auth-title">Create a new account</h3>
      <div className="auth-form-inputs">
        <Input wrapperClassName="auth-input" id="email" name="email" onChange={onChangeHandler} fluid required label="Email" type="email" />
        <Input wrapperClassName="auth-input" id="username" name="username" onChange={onChangeHandler} fluid required label="Username" />
        <Input
          wrapperClassName="auth-input"
          error={checkPassword()}
          id="password"
          name="password"
          onChange={onChangeHandler}
          fluid
          required
          label="Password"
          type="password"
          info="Password must contain at least 8 characters and contain at least 1 lowercase 1 uppercase and 1 symbol"
        />
        <Input
          wrapperClassName="auth-input"
          error={checkPasswordConfirmation()}
          id="password-confirmation"
          name="password-confirmation"
          onChange={onChangeHandler}
          fluid
          required
          label="Password confirmation"
          type="password"
        />
      </div>
      <div className="auth-footer">
        <div>Have an account? <span className="change-page" onClick={() => changePage('signin')}>Sign in</span></div>
        <Button id="sign-up" disabled={checkDisabled()} loading={loading} type="submit">Create account</Button>
      </div>

    </form>
  );
}
