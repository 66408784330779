import React, { ReactElement } from 'react';
import Info from '../Info/Info';
import styles from './Checkbox.module.css';

export interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  /** Label for the checkbox */
  label: string,
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  info?: string,
}

export default function Checkbox({
  label, disabled, className, info, ...rest
}: CheckboxProps): ReactElement {
  return (
    <label
      className={disabled ? `${styles['checkbox-label']} ${styles['checkbox-disabled']}` : `${className} ${styles['checkbox-label']}`}
    >
      <input
        type="checkbox"
        className={`${styles.checkbox}`}
        disabled={disabled}
        {...rest}
      />
      <span className={styles.checkmark}></span>
      {label}
      {info ? <span className={styles.info}><Info content={info} /></span> : null}
    </label>
  );
}
