import { Dispatch } from 'redux';
import {
  addProjectMemberMutation, createRepositoryMutation, deleteProjectMemberMutation, deleteProjectMutation, deleteRepositoryMutation,
  addTemplateRepositoryMutation, deleteTemplateRepositoryMutation, updateProjectDescriptionMutation, updateProjectMemberMutation,
  createProjectMutation,
  addTeamToProjectMutation,
  deleteTeamFromProjectMutation,
  executeTemplatesMutation,
} from 'graphql/mutations';
import {
  getProjectByUUIDQuery, getProjectsQuery, getRepositoryByUuidQuery, getTemplateRepositories,
} from 'graphql/queries';
import { ITemplateRepositorySubmit } from 'types/types';
import store from 'store/configureStore';
import Axios from 'axios';
import {
  baseUrl, path, headers, checkErrors, getErrorString,
} from 'utils';
import { toast } from 'react-toastify';
import {
  Parameter, Project, Repository, TemplateRepository, VcsTarget,
} from 'graphql/types';
import { ActionType, Action } from '../actionTypes';

const toastUpdateDefaults = {
  isLoading: false, autoClose: 5000, closeOnClick: true, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true,
};

function checkProjectAdmin(project: Project): Project {
  const _project = { ...project };
  _project.amIAdmin = false;
  for (let i = 0; i < project?.members?.length; i += 1) {
    if (project.members[i].user.uuid === store.getState().user.user?.uuid && project.members[i].role.name === 'Admin') {
      _project.amIAdmin = true;
      break;
    }
  }
  return _project;
}

async function _getProject(uuid: string): Promise<Project> {
  const response = await Axios({
    method: 'POST',
    url: `${baseUrl}${path}`,
    headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
    data: { query: getProjectByUUIDQuery(uuid) },
  });
  checkErrors(response);
  return checkProjectAdmin(response.data.data?.project?.project);
}

export const CreateRepositoryAction = (name: string, vcsTarget: VcsTarget, projectUUID: string,
  templates: ITemplateRepositorySubmit[], ciProvider: string, parameters: Parameter[], options: Parameter[],
) => async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    dispatch({ type: ActionType.PROJECTS_LOADING });
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: createRepositoryMutation(name, vcsTarget, projectUUID, templates, ciProvider, parameters, options) },
    });
    checkErrors(response);
    dispatch({
      type: ActionType.CREATE_REPOSITORY_SUCCESS,
    });
    toast.success('Repository created!');
  } catch (error) {
    const errorString = getErrorString(error);
    dispatch({
      type: ActionType.CREATE_REPOSITORY_FAILED,
      payload: errorString,
    });
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const CreateProjectAction = (name: string, description: string) => async (dispatch: Dispatch<Action>): Promise<string> => {
  dispatch({ type: ActionType.PROJECTS_LOADING });
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: createProjectMutation(name, description) },
    });

    checkErrors(response);
    const projectUUID: string = response.data.data?.createProject?.project?.uuid;
    dispatch({
      type: ActionType.CREATE_PROJECT_SUCCESS,
    });
    toast.success('Project created!');
    toast.success('You can now add repositories to this project.');
    return Promise.resolve(projectUUID);
  } catch (error) {
    const errorString = getErrorString(error);
    dispatch({
      type: ActionType.CREATE_PROJECT_FAILED,
      payload: errorString,
    });
    toast.error(errorString);
    return Promise.reject(errorString);
  }
};

export const GetProjects = () => async (dispatch: Dispatch<Action>): Promise<void> => {
  dispatch({ type: ActionType.PROJECTS_LOADING });
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: getProjectsQuery() },
    });
    checkErrors(response);
    const projectsResponse: Project[] = response.data.data?.projects?.projects;
    dispatch({
      type: ActionType.GET_PROJECTS_SUCCESS,
      payload: projectsResponse,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    dispatch({
      type: ActionType.GET_PROJECTS_FAILED,
      payload: errorString,
    });
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const GetProjectByUUID = (uuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  dispatch({ type: ActionType.CLEAR_CURRENT_PROJECT });
  dispatch({ type: ActionType.PROJECTS_LOADING });
  try {
    const projectResponse = await _getProject(uuid);
    dispatch({
      type: ActionType.GET_CURRENT_PROJECT_SUCCESS,
      payload: projectResponse,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    dispatch({
      type: ActionType.GET_CURRENT_PROJECT_FAILED,
      payload: errorString,
    });
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const ClearCurrentProject = () => async (dispatch: Dispatch<Action>): Promise<void> => {
  dispatch({ type: ActionType.CLEAR_CURRENT_PROJECT });
};

export const GetRepositoryTemplates = () => async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: getTemplateRepositories() },
    });
    checkErrors(response);
    const templateRepositories: TemplateRepository[] = response.data.data?.templateRepositories?.repositories;
    dispatch({
      type: ActionType.GET_TEMPLATE_REPOSITORIES_SUCCESS,
      payload: templateRepositories,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    dispatch({
      type: ActionType.GET_TEMPLATE_REPOSITORIES_FAILED,
      payload: errorString,
    });
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const UpdateProjectDescription = (projectUuid: string, description: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Updating description');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: updateProjectDescriptionMutation(projectUuid, description) },
    });
    checkErrors(response);
    const projectResponse: Project = checkProjectAdmin(response.data.data.updateProjectDescription.project);
    dispatch({
      type: ActionType.GET_CURRENT_PROJECT_SUCCESS,
      payload: projectResponse,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Description updated', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const deleteProject = (projectUuid: string) => async (): Promise<void> => {
  const id = toast.loading('Deleting project');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: deleteProjectMutation(projectUuid) },
    });
    checkErrors(response);
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Project deleted', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const deleteRepository = (repositoryUuid: string, deleteFromVcs: boolean, projectUuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Deleting repository');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: deleteRepositoryMutation(repositoryUuid, deleteFromVcs) },
    });
    checkErrors(response);
    const projectResponse = await _getProject(projectUuid);
    dispatch({
      type: ActionType.GET_CURRENT_PROJECT_SUCCESS,
      payload: projectResponse,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Repository deleted', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const DeleteTemplateRepository = (uuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Deleting template repository');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: deleteTemplateRepositoryMutation(uuid) },
    });
    checkErrors(response);
    const getRequest = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: getTemplateRepositories() },
    });
    checkErrors(getRequest);
    const templateRepositories: TemplateRepository[] = getRequest.data.data?.templateRepositories?.repositories;
    dispatch({
      type: ActionType.GET_TEMPLATE_REPOSITORIES_SUCCESS,
      payload: templateRepositories,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Template repository deleted', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const importTemplateRepository = (url: string, name: string, description: string, username: string, token: string, organisationUuid?: string) => async (): Promise<void> => {
  const id = toast.loading('Importing template repository');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: addTemplateRepositoryMutation(url, name, description, username, token, organisationUuid) },
    });
    checkErrors(response);
    toast.update(id, {
      render: 'Template repository imported!', type: 'success', ...toastUpdateDefaults,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  return Promise.resolve();
};

export const addMemberToProject = (projectUuid: string, memberUuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Adding member to project');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: addProjectMemberMutation(projectUuid, memberUuid) },
    });
    checkErrors(response);
    const project = await _getProject(projectUuid);
    dispatch({
      type: ActionType.GET_CURRENT_PROJECT_SUCCESS,
      payload: project,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Member added to project', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const updateProjectMember = (projectUuid: string, memberUuid: string, role: 'Admin' | 'User') => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Updating project member role');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: updateProjectMemberMutation(projectUuid, memberUuid, role) },
    });
    checkErrors(response);
    const project = await _getProject(projectUuid);
    dispatch({
      type: ActionType.GET_CURRENT_PROJECT_SUCCESS,
      payload: project,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: `Member role changed to ${role}`, type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const deleteProjectMember = (projectUuid: string, memberUuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Removing member from project');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: deleteProjectMemberMutation(projectUuid, memberUuid) },
    });
    checkErrors(response);
    const project = await _getProject(projectUuid);
    dispatch({
      type: ActionType.GET_CURRENT_PROJECT_SUCCESS,
      payload: project,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Member removed from project', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const addTeamToProject = (projectUuid: string, teamUuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Adding team to project');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: addTeamToProjectMutation(projectUuid, teamUuid) },
    });
    checkErrors(response);
    const project = await _getProject(projectUuid);
    dispatch({
      type: ActionType.GET_CURRENT_PROJECT_SUCCESS,
      payload: project,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Team added to project', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const deleteTeamFromProject = (projectUuid: string, teamUuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  const id = toast.loading('Removing team from project');
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: deleteTeamFromProjectMutation(projectUuid, teamUuid) },
    });
    checkErrors(response);
    const project = await _getProject(projectUuid);
    dispatch({
      type: ActionType.GET_CURRENT_PROJECT_SUCCESS,
      payload: project,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  toast.update(id, {
    render: 'Team removed from project', type: 'success', ...toastUpdateDefaults,
  });
  return Promise.resolve();
};

export const executeTemplatesAction = (projectUUID: string, templates: ITemplateRepositorySubmit[], parameters: Parameter[],
  repositoryUuid?: string) => async (): Promise<void> => {
  const id = toast.loading(`Executing script${templates.length > 1 ? 's' : ''}`);
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: executeTemplatesMutation(projectUUID, templates, parameters, repositoryUuid) },
    });
    checkErrors(response);
    toast.update(id, {
      render: `Script${templates.length > 1 ? 's' : ''} executed successfully`, type: 'success', ...toastUpdateDefaults,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.update(id, {
      render: errorString, type: 'error', ...toastUpdateDefaults,
    });
    return Promise.reject();
  }
  return Promise.resolve();
};

export const GetRepositoryByUUID = (repositoryUuid: string) => async (dispatch: Dispatch<Action>): Promise<void> => {
  try {
    const response = await Axios({
      method: 'POST',
      url: `${baseUrl}${path}`,
      headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
      data: { query: getRepositoryByUuidQuery(repositoryUuid) },
    });
    checkErrors(response);
    const repository: Repository = response.data.data?.repository?.repository;
    dispatch({
      type: ActionType.GET_CURRENT_REPOSITORY_SUCCESS,
      payload: repository,
    });
  } catch (error) {
    const errorString = getErrorString(error);
    toast.error(errorString);
    return Promise.reject();
  }
  return Promise.resolve();
};

export const clearCurrentRepository = () => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.CLEAR_CURRENT_REPOSITORY,
  });
};
