import React, { ReactElement } from 'react';
import Spinner from 'stories/atoms/Spinner/Spinner';
import './Loading.css';

export default function Loading():ReactElement {
  return (
    <div className="loading-screen">
      <Spinner></Spinner>
    </div>
  );
}
