import React, { ReactElement, useState } from 'react';
import Icon from 'stories/atoms/Icon/Icon';
import { IconName } from 'stories/atoms/Icon/types';
import Link from 'stories/atoms/Link/Link';
import Modal from 'stories/composite/Modal/Modal';
import { isUrl } from 'utils';
import { IInfo } from '../RepositoryDetailsInfos';

interface RepositoryDetailsInfoProps {
  info: IInfo[];
  title: string;
  icon: IconName
  maxShown?: number
}
export default function RepositoryDetailsInfo({
  info, title, icon, maxShown = 4,
}: RepositoryDetailsInfoProps): ReactElement {
  const [shown, setShown] = useState(false);
  return (
    <>
      <div className="repository-details-info-container">
        <h4 className="repository-details-info-title">
          <span className={`repository-details-info-icon ${icon}`}>
            <Icon size="small" name={icon} color="white" />
          </span>
          {title}
        </h4>
        <div className="repository-details-info-content">
          {info.map((element, index) => (
            index < maxShown ? (
              <div
                className="repository-details-info-row"
                key={element.uuid}
              >
                {element.value && isUrl(element.value) ? (
                  <>
                    <Icon name="linkRow" width={15} height={10} color="white" />
                    <Link href={element.value}>{element.text}</Link>
                  </>
                ) : null}
                {element.value && !isUrl(element.value) ? (
                  <>
                    <Icon name="arrowRight" size="mini" color="white" />
                    {element.text} : {element.value}
                  </>
                ) : null}
                {!element.value ? (
                  <>
                    <Icon name="arrowRight" size="mini" color="white" />
                    {element.text}
                  </>
                ) : null}
              </div>
            ) : null
          ))}
          {info.length > maxShown ? <div onClick={() => setShown(true)}>show more</div> : null}
        </div>
      </div>
      <Modal open={shown} onClose={() => setShown(false)} className="repository-details-info-modal">
        <h4 className="repository-details-info-title">
          <span className={`repository-details-info-icon ${icon}`}>
            <Icon size="small" name={icon} color="white" />
          </span>
          {title}
        </h4>
        <div className="repository-details-info-content">
          {info.map((element) => (
            <div
              className="repository-details-info-row"
              key={element.uuid}
            >
              {element.value && isUrl(element.value) ? (
                <>
                  <Icon name="linkRow" width={15} height={10} color="white" />
                  <Link href={element.value}>{element.text}</Link>
                </>
              ) : null}
              {element.value && !isUrl(element.value) ? (
                <>
                  <Icon name="arrowRight" size="mini" color="white" />
                  {element.text} : {element.value}
                </>
              ) : null}
              {!element.value ? (
                <>
                  <Icon name="arrowRight" size="mini" color="white" />
                  {element.text}
                </>
              ) : null}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}
