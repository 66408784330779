import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import styles from './SearchResults.module.css';
import { SearchResultsProps } from '../types';

function SearchResults({
  results, onResultSelect, noResultsText, disabledKeys,
}: SearchResultsProps): ReactElement {
  return (
    <div className={styles['search-results']}>
      {results && results.length
        ? results.map((result) => {
          const disabled = disabledKeys.includes(result.key);
          return (
            <div
              role="option"
              tabIndex={0}
              aria-selected={false}
              key={result.key}
              id={result.id}
              onClick={() => !disabled && onResultSelect(result)}
              onKeyPress={(event) => {
                if (!disabled && (event.key === 'Enter' || event.key === 'Space')) {
                  onResultSelect(result);
                }
              }}
              className={disabled ? `${styles.content} ${styles.disabled}` : styles.content}
            >
              <div className={styles.text}>{result.text}</div>
            </div>
          );
        })
        : <div id="search-no-results" className={`${styles.content} ${styles['no-results']}`}>{noResultsText}</div>}
    </div>
  );
}

SearchResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      id: PropTypes.string,
    }),
  ),
  onResultSelect: PropTypes.func.isRequired,
  noResultsText: PropTypes.string,
  disabledKeys: PropTypes.arrayOf(PropTypes.string),
};

SearchResults.defaultProps = {
  results: [],
  noResultsText: 'No results found.',
  disabledKeys: [],
};

export default SearchResults;
