import { Organisation, Team } from 'graphql/types';
import React, { ReactElement, useCallback, useState } from 'react';
import { CreateTeam } from 'store/actions/organisationActions';
import Button from 'stories/atoms/Button/Button';
import Checkbox from 'stories/atoms/Checkbox/Checkbox';
import Input from 'stories/atoms/Input/Input';
import Label from 'stories/atoms/Label/Label';
import TextArea from 'stories/atoms/TextArea/TextArea';
import InputList from 'stories/composite/InputList/InputList';
import Modal from 'stories/composite/Modal/Modal';
import { useAppDispatch, useFormFields } from 'utils';
import OrganisationTeam from './OrganisationTeam/OrganisationTeam';

interface OrganisationTeamsProps {
  organisation: Organisation,
  teams: Team[],
}
export default function OrganisationTeams({ organisation, teams }: OrganisationTeamsProps): ReactElement {
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [groupList, setGroupList] = useState<string[]>([]);
  const { formFields, createChangeHandler, setFormFields } = useFormFields({
    name: '',
    description: '',
    isExternal: false,
    filter: '',
  });
  const dispatch = useAppDispatch();

  const closeModal = useCallback(
    () => {
      setTeamModalOpen(false);
      setGroupList([]);
      setFormFields({
        ...formFields,
        description: '',
        name: '',
        isExternal: false,
      });
    },
    [formFields, setFormFields],
  );
  const createTeam = useCallback(
    async () => {
      if (formFields.isExternal) {
        await dispatch(CreateTeam(organisation?.uuid as string, formFields.name, formFields.description, groupList));
      } else {
        await dispatch(CreateTeam(organisation?.uuid as string, formFields.name, formFields.description));
      }

      closeModal();
    },
    [closeModal, dispatch, formFields.description, formFields.isExternal, formFields.name, groupList, organisation?.uuid],
  );

  const createTeamIsDisabled = useCallback(
    () => {
      if (!formFields.name) {
        return true;
      }
      if (formFields.isExternal && !groupList.length) {
        return true;
      }
      return false;
    },
    [formFields.isExternal, formFields.name, groupList.length],
  );

  return (
    <>
      <div key="org-teams-tab">
        <div className="org-entities-filter-bar">
          <Input placeholder="Filter teams" onChange={createChangeHandler('filter')} />
          <Button id="create-team-button" onClick={() => setTeamModalOpen(true)}>Create a team</Button>
        </div>

        <div className="org-entities-list">
          {teams.filter((team) => team.name.includes(formFields.filter)).map((team) => (
            <OrganisationTeam key={team.uuid} team={team} organisation={organisation} />
          ))}
        </div>
      </div>
      <Modal open={teamModalOpen} onClose={() => setTeamModalOpen(false)} id="create-team-modal">
        <div className="org-create-team-modal">
          <h3>Create team</h3>
          <Input required wrapperClassName="org-input" label="Name" onChange={createChangeHandler('name')} />
          <TextArea className="org-input" label="Description" onChange={createChangeHandler('description')} />
          <Checkbox
            label="Externally managed"
            onChange={createChangeHandler('isExternal')}
            info="If checked team members are managed in an external directory. Team cannot have direct members"
          />
          {
            formFields.isExternal
              ? (
                <>
                  <Label text="External group names *" info="A list of external directory group names mapped to this team" />
                  <InputList inputProps={{ fluid: true, placeholder: 'Group name' }} id="group-list-input" onChange={(groups) => setGroupList(groups)} />
                </>
              )
              : null
          }
          <Button disabled={createTeamIsDisabled()} onClick={createTeam}>Create</Button>
        </div>
      </Modal>
    </>

  );
}
